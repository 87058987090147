// ...src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import AccMastSlice from '../../Redux/Slices/Masters/AccMastSlice';
import BGMMastSlice from '../../Redux/Slices/Masters/BGMMastSlice';
import RptFieldMastSlice from '../../Redux/Slices/Masters/RptFieldMastSlice';
import BlackIncMastSlice from '../../Redux/Slices/Masters/BlackIncMastSlice';
import BrownMastSlice from '../../Redux/Slices/Masters/BrownMastSlice';
import BranchMastSlice from '../../Redux/Slices/Masters/BranchMastSlice';
import CertDateMastSlice from '../../Redux/Slices/Masters/CertDateMastSlice';
import CharMastSlice from '../../Redux/Slices/Masters/CharMastSlice';
import ColMastSlice from '../../Redux/Slices/Masters/ColMastSlice';
import CountryMastSlice from '../../Redux/Slices/Masters/CountryMastSlice';
import CuletConMastSilce from '../../Redux/Slices/Masters/CuletConMastSilce';
import CuletSizeMastSlice from '../../Redux/Slices/Masters/CuletSizeMastSlice';
import CutMastSlice from '../../Redux/Slices/Masters/CutMastSlice';
import ClarityMastSlice from '../../Redux/Slices/Masters/ClarityMastSlice';
import DaysRangeMastSlice from '../../Redux/Slices/Masters/DaysRangeMastSlice';
import ExampleSlice from '../../Redux/Slices/Masters/ExampleSlice';
import EyeCleanMastSlice from '../../Redux/Slices/Masters/EyeCleanMastSlice';
import FacetMastSlice from '../../Redux/Slices/Masters/FacetMastSlice';
import FloColMastSlice from '../../Redux/Slices/Masters/FloColMastSlice';
import FlorMastSlice from '../../Redux/Slices/Masters/FlorMastSlice';
import GirdleMastSlice from '../../Redux/Slices/Masters/GirdleMastSlice';
import GreenMastSlice from '../../Redux/Slices/Masters/GreenMastSlice';
import HeartArrowMastSlice from '../../Redux/Slices/Masters/HeartArrowMastSlice';
import LabLocMastSlice from '../../Redux/Slices/Masters/LabLocMastSlice';
import LabMastSlice from '../../Redux/Slices/Masters/LabMastSlice';
import LiveDayMastSlice from '../Slices/Masters/LiveDayMastSlice';
import LocMastSlice from '../../Redux/Slices/Masters/LocMastSlice';
import LotMastSlice from '../../Redux/Slices/Masters/LotMastSlice';
import MilkyMastSlice from '../../Redux/Slices/Masters/MilkyMastSlice';
import NattsMastSlice from '../../Redux/Slices/Masters/NattsMastSlice';
import OpenIncMastSlice from '../../Redux/Slices/Masters/OpenIncMastSlice';
import PartyRateMastSlice from '../Slices/Masters/PartyRateMastSlice';
import SalarMastSlice from '../../Redux/Slices/Masters/SalarMastSlice';
import ShapeMastSlice from '../../Redux/Slices/Masters/ShapMastSlice';
import setLoading from '../../Redux/Slices/Utility/loaderSlice';
import ServiceMastSlice from '../../Redux/Slices/Masters/ServicMastSilce';
import ShdMastSlice from '../../Redux/Slices/Masters/ShadeMastSlice';
// import StkPartySlice from '../../Redux/Slices/Masters/StockPartyMastSlice';
import TermMastSlice from '../../Redux/Slices/Masters/TermMastSlice';
import WhiteIncMastSlice from '../../Redux/Slices/Masters/WhiteIncMastSlice';
import ColumnConfigSlice from '../../Redux/Slices/Utility/ColumnConfigSlice';
import WishListSlice from '../Slices/Masters/WishListSlice';
import StoneSearchSlice from '../Slices/Masters/StoneSearchSlice';
import HoldSlice from '../Slices/Masters/HoldSlice';
import ConfirmSlice from '../Slices/Masters/ConfirmSlice';
import ViewRequestSlice from '../Slices/Masters/ViewRequestSlice';
import loginSlice from '../Slices/Utility/LoginSlice';
import SizeMastSlice from '../Slices/Masters/SizeMastSlice';
import UserMenuPermission from '../Slices/Utility/MenuPermition';
import FileFildValidation from '../Slices/Transaction/FileFildValidation';
import ColumnMastSlice from '../Slices/Masters/ColumnMastSlice';
import MenuMastSlice from '../Slices/Masters/MenuMastSlice';
import RolePermissionSlice from '../Slices/Utility/RolePermissionSlice';
import RoleSlice from '../Slices/Utility/RoleSlice';
import GrdEntSlice from '../Slices/Transaction/GrdEntSlice';
import DynamicSlice from '../Slices/Transaction/DynamicSlice';
import FildColorMastSlice from '../Slices/Masters/FildColorMastSlice';
import SettingSlice from '../Slices/Masters/SettingSlice';
import SPNameMastSlice from '../../Redux/Slices/Masters/SPNameMastSlice';
import SPRolePermissionSlice from '../../Redux/Slices/Masters/SPRolePermissionSlice';
import PacketSearchSlice from '../Slices/Masters/PacketSearchSlice';
import APISettingSlice from '../Slices/Masters/ApiSettingSlice';
import BarSettingSlice from '../Slices/Utility/BarSettingSlice';
import DropDownSlice from '../Slices/Utility/DropDownSlice';
import MixColMastSlice from '../Slices/MixMasters/MixColMastSlice';
import MixQuaMastSlice from '../Slices/MixMasters/MixQuaMastSlice';
import MixCharMastSlice from '../Slices/MixMasters/MixCharMastSlice';
import MixCutMastSlice from '../Slices/MixMasters/MixCutMastSlice'; 
import MixIdentityMastSlice from '../Slices/MixMasters/MixIdentityMastSlice';


export default configureStore({
    reducer: {
        AccMast: AccMastSlice,
        BGMMast: BGMMastSlice,
        RptFieldMast: RptFieldMastSlice,
        BlackIncMast: BlackIncMastSlice,
        BrownMast: BrownMastSlice,
        BranchMast: BranchMastSlice,
        CertDateMast: CertDateMastSlice,
        CharMast: CharMastSlice,
        ColMast: ColMastSlice,
        ColumnMaster: ColumnMastSlice,
        CountryMast: CountryMastSlice,
        CuletConMast: CuletConMastSilce,
        CuletSizeMast: CuletSizeMastSlice,
        CutMast: CutMastSlice,
        ClarityMast: ClarityMastSlice,
        MixQuaMast: MixQuaMastSlice,
        ConfirmList: ConfirmSlice,
        DaysRangeMast: DaysRangeMastSlice,
        Example: ExampleSlice,
        EyeCleanMast: EyeCleanMastSlice,
        FacetMast: FacetMastSlice,
        FloColMast: FloColMastSlice,
        FlorMast: FlorMastSlice,
        GirdleMast: GirdleMastSlice,
        GreenMast: GreenMastSlice,
        HeartArrowMast: HeartArrowMastSlice,
        HoldList: HoldSlice,
        LabLocMast: LabLocMastSlice,
        LabMast: LabMastSlice,
        LiveDayMast: LiveDayMastSlice,
        LocMast: LocMastSlice,
        LotMast: LotMastSlice,
        MenuMaster: MenuMastSlice,
        MilkyMast: MilkyMastSlice,
        NattsMast: NattsMastSlice,
        OpenIncMast: OpenIncMastSlice,
        PartyRateMast: PartyRateMastSlice,
        SalarMast: SalarMastSlice,
        ShpMast: ShapeMastSlice,
        SizeMast: SizeMastSlice,
        ServiceMast: ServiceMastSlice,
        StoneSearch: StoneSearchSlice,
        ShadeMast: ShdMastSlice,
        // StockPartyMast: StkPartySlice,
        TermMast: TermMastSlice,
        ViewRequest: ViewRequestSlice,
        WishList: WishListSlice,
        WhiteIncMast: WhiteIncMastSlice,
        RolePermission: RolePermissionSlice,
        Role: RoleSlice,
        GrdEntry: GrdEntSlice,
        DynamicEnt: DynamicSlice,
        DynamicEntSum: DynamicSlice,
        FildColorMast: FildColorMastSlice,
        SPNameMast: SPNameMastSlice,
        SPRolePermission: SPRolePermissionSlice,

        ColumnConfig: ColumnConfigSlice,
        Loader: setLoading,
        MenuPermition: UserMenuPermission,
        UserLogin: loginSlice,
        FileFildValidation: FileFildValidation,
        Setting: SettingSlice,
        PacketSearch: PacketSearchSlice,
        APISetting: APISettingSlice,
        BarSetting: BarSettingSlice,
        DropDown: DropDownSlice,        

        ///MIX
        MixColMast: MixColMastSlice,
        MixCharMast: MixCharMastSlice,
        MixCutMast: MixCutMastSlice,
        MixIdentityMast: MixIdentityMastSlice,
    },
});