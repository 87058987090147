import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';
import { AlertPopup } from '../../../Asset/common';

export const fetchData = (data) => async (dispatch) => {
  dispatch(startLoader());
  try {
    
    const res = await HttpMethod.post('/SystemConfig/Roles/List', data);
    if (res?.status === 200) {
      // Use the utility function to convert keys to uppercase
      const transformedData = convertKeysToUpperCase(res.data);
      dispatch(getData(transformedData));
      console.log("transform",transformedData);
    }
  } catch (error) {
    console.error("Error fetching data", error);
  } finally {
    dispatch(stopLoader());
  }
};

const convertKeysToUpperCase = (data) => {
  if (Array.isArray(data)) {
    return data.map(item => convertKeysToUpperCase(item));
  } else if (typeof data === 'object' && data !== null) {
    const transformedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        transformedData[key.toUpperCase()] = convertKeysToUpperCase(data[key]);
      }
    }
    return transformedData;
  }
  return data; // return primitive data types (string, number, etc.) unchanged
};

export const addData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/SystemConfig/Roles/Add', data);
  console.log("res",res);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data);
    dispatch(fetchData())  // ----------Bandh kryu che km k prob ave che ---Khushali
  }
};

export const updateData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/SystemConfig/Roles/Update', data);
  dispatch(fetchData());   //farithi Fill karva mate
};

export const deleteData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/SystemConfig/Roles/Delete', data);
  dispatch(fetchData());   //Delete karva mate
}

export const RoleSlice = createSlice({
  name: 'RoleSlice',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };
    }
  },
});

export const { getData } = RoleSlice.actions;
export default RoleSlice.reducer;