import React from 'react';
import Sidebar from '../Sidebar/Sidebar';

const Layout = ({ children, isAuthenticated }) => {
    return (
        <>
            <div className="d-flex">
                {isAuthenticated && <Sidebar />}
                <div className="viewContent">{children}</div>
            </div>
        </>
    );
};

export default Layout;
