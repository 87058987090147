import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';

export const fetchData = () => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.get('/Master/FileFildValidation/GetList');
  if (res?.status === 200) {
    dispatch(getData(await res.data));
  }
  dispatch(stopLoader())
};

export const addData = (data) => async (dispatch) => {
    const res = await HttpMethod.post('/Stock/STKTrnParty/Upload', data);
    // dispatch(fetchData());   //farithi Fill karva mate
};

export const updateData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/Stock/STKTrnParty/Upload', data);
  dispatch(fetchData());   //farithi Fill karva mate
};

export const deleteData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/Stock/STKTrnParty/Delete' , data);
  dispatch(fetchData());   //Delete karva mate
}

export const FileFildValidation = createSlice({
  name: 'FileFildValidation',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };      
    }
  },
});

export const { getData } = FileFildValidation.actions;
export default FileFildValidation.reducer;