import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from './loaderSlice';
import { AlertPopup } from '../../../Asset/common';

const initialState = {
  loading: false,
};

export const registerUser = (userData) => async (dispatch) => {
  dispatch(startLoader());
  try {
    const res = await HttpMethod.Register('/Authorization/Account/Register', userData);
    if (res && res?.status === 200) {
      // Handle success
      if (AlertPopup && AlertPopup.SuccessPopup) {
        AlertPopup.SuccessPopup('Registration successful!');
      }
      dispatch(stopLoader());
    }
  } catch (error) {
    // Handle error
    console.error('Error registering user:', error);
    if (AlertPopup && AlertPopup.ErrorPopup) {
      AlertPopup.ErrorPopup('An error occurred while registering. Please try again later.');
    }
    dispatch(stopLoader());
  }
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    // You can add additional reducers if needed
  },
});

export const { /* Any additional reducers */ } = registrationSlice.actions;

export default registrationSlice.reducer;