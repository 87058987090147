import React, { useEffect, useReducer, useState } from 'react'
import { Collapse, Modal } from 'react-bootstrap'
import { objReducer } from '../../Asset/reducer';
import StoneSearchComponent from '../StoneSearch/StoneSearchComponent';
import '../StoneSearch/StoneSearch.css';
import { BiReset, BiSearchAlt } from 'react-icons/bi';
import { IoAddCircleOutline } from 'react-icons/io5';
import { PiArrowsVerticalFill } from 'react-icons/pi';
import { MdFilterAlt, MdReadMore, MdSaveAs } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData as FetchStoneSearch } from '../../Redux/Slices/Masters/StoneSearchSlice';
import { fetchData as fetchPacketSearch } from '../../Redux/Slices/Masters/PacketSearchSlice';
import { MultiSelect } from 'primereact/multiselect';

function PacketSearchComp(props) {
    const [GetViewPara, setGetViewPara] = useState([])
    let [SearchObj, SetSearchObj] = useState()
    const [showModal, setShowModal] = useState(props.openPacketSearchCom);
    let [obj, dispatch] = useReducer(objReducer, {})
    const [size, setsize] = useState({ fromSize: '', toSize: '' })
    const [transformedObj, settransformedObj] = useState({})
    const [StoneSearchCap, setStoneSearchCap] = useState('STONE SEARCH');
    const [activeKey, setActiveKey] = useState("1");
    const [open, setOpen] = useState(false);
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const columnConfigRecord = useSelector(state => state.ColumnConfig.visible)
    const [selFanCol, setselFanCol] = useState(null);
    const [selFanColOvr, setselFanColOvr] = useState(null);
    const [selFanColInt, setselFanColInt] = useState(null);
    const [AVLStatus, setAVLStatus] = useState([]);
    const [showMoreShapes, setShowMoreShapes] = useState(false);
    const [isOtherShpClicked, setisOtherShpClicked] = useState(false);
    const DropDown = useSelector(state => state.DropDown)
    const settings = useSelector(state => state.Setting.Setting)

    useEffect(() => {
        setShowModal(props.openPacketSearchCom)
    }, [props.openPacketSearchCom])



    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        props.setopenPacketSearchCom(false)
    };

    const getValue = (e) => {
        dispatch({ type: "add", event: e })
    }

    // const getValue = (e) => {
    //     dispatch({ type: "add", event: e }); // Dispatch an action with the event object

    //     const { name, value } = e.target;

    //     // Filter PKTID and MFGID values
    //     let filteredValue = value;
    //     if (name === 'PKTID' || name === 'MfgID') {
    //         filteredValue = value.toUpperCase(); // Example: Convert PKTID and MFGID values to uppercase
    //     }

    //     // Update the state object with the filtered value
    //     dispatch({ type: "update", payload: { [name]: filteredValue } });
    // };

    const AddSize = (e) => {

        let concatenatedValue = `${size.fromSize}-${size.toSize}`;

        obj.SizeName?.push(concatenatedValue)

        dispatch({ type: "add", event: e });
    }
    const getAllData = () => {
        if (props.SearchType == 'PACKETVIEW') {
            let transformedObjTyp = { ...transformedObj, SearchType: 'PACKETVIEW' };
            dispatchMast(fetchPacketSearch(transformedObjTyp, settings.find(x => x.SKEY == 'RecordPerPage')?.SVALUE))
        }
        else if (props.SearchType == "SEARCH") {
            let transformedObjTyp = { ...transformedObj, SearchType: 'SEARCH' };
            dispatchMast(FetchStoneSearch(transformedObjTyp, settings.find(x => x.SKEY == 'RecordPerPage')?.SVALUE))
        }
    }

    const Columnconfig = async () => {
        setGetViewPara(columnConfigRecord?.filter(x => x.MenuCode == 'PACKETSEARCH'));
    }

    useEffect(() => {
        Columnconfig();
    }, [columnConfigRecord])

    const ResetValue = (e) => {
        dispatch({ type: "Reset", event: e })

        setsize({ fromSize: '', toSize: '' });
        setselFanColInt([]);
        setselFanCol([]);
        setselFanColOvr([]);
        setfiltereObj([]);

        SetSearchObj(undefined);
        settransformedObj({});
        // getAllData();
    }

    const handleCollapseButtonClick = () => {
        setOpen(!open);
    };

    const dispatchMast = useDispatch()
    let ColMastObj = useSelector(state => state.ColMast.DynDisp)?.filter(item => item.ISWEB === true && (item.WEB_GROUP === 'WHITE COLOR' || item.COL_GROUP === "WHITE COLOR")) || [];
    let FanColObj = useSelector(state => state.ColMast.DynDisp)?.filter(item => item.ISWEB === true && (item.WEB_GROUP === 'FANCY COLOR' || item.COL_GROUP === "FANCY COLOR")) || [];
    let SizeMast = useSelector(state => state.SizeMast.DynDisp)?.filter(item => item.TYP === 'RAP' && item.ISWEB === true) || [];
    let QuaMast = useSelector(state => state.ClarityMast.DynDisp)?.filter(item => item.ISWEB === true) || [];
    let CutMast = useSelector(state => state.CutMast.DynDisp)?.filter(item => item.ISWEB === true) || [];
    let FloMast = useSelector(state => state.FlorMast.DynDisp)?.filter(item => item.ISWEB === true) || [];
    let LabMast = useSelector(state => state.LabMast.DynDisp)
    let LocMast = useSelector(state => state.LocMast.DynDisp)?.filter(item => item.ISWEB === true) || [];
    let ShapMastIsWeb = useSelector(state => state.ShpMast.DynDisp)?.filter(item => item.ISWEB === true) || [];
    let ShapMastNoWeb = useSelector(state => state.ShpMast.DynDisp)?.filter(item => item.ISWEB === false) || [];
    let BrownMast = useSelector(state => state.BrownMast.DynDisp);
    let GreenMast = useSelector(state => state.GreenMast.DynDisp);
    let MilkyMast = useSelector(state => state.MilkyMast.DynDisp);
    let BGMMast = useSelector(state => state.BGMMast.DynDisp);
    let ShadeMast = useSelector(state => state.ShadeMast.DynDisp);
    let BlackTblMast = useSelector(state => state.BlackIncMast.DynDisp);
    let WhiteTblMast = useSelector(state => state.WhiteIncMast.DynDisp);
    let OpenTblMast = useSelector(state => state.OpenIncMast.DynDisp);

    const [filtereObj, setfiltereObj] = useState({
        BROWN_CODE: null,
        GREEN_CODE: null,
        MILKY_CODE: null,
        BGM_CODE: null,
        SH_CODE: null,
        BTINC_CODE: null,
        BSINC_CODE: null,
        WTINC_CODE: null,
        WSINC_CODE: null,
        OTINC_CODE: null,
        OSINC_CODE: null,
        OPINC_CODE: null
    });

    let objDimTyp = [
        {
            D_NAME: 'NATURAL'
        },
        {
            D_NAME: 'CVD'
        },
        {
            D_NAME: 'HPHT'
        },
        {
            D_NAME: 'LABGROWN'
        }

    ]

    let objCBlk = [
        {
            CTB_NAME: 'NONE',
            CTB_CODE: 1
        },
        {
            CTB_NAME: 'B1',
            CTB_CODE: 2
        },
        {
            CTB_NAME: 'B2',
            CTB_CODE: 3
        },
        {
            CTB_NAME: 'B3',
            CTB_CODE: 4
        }
    ]

    let objCRwBlk = [
        {
            CRB_NAME: 'NONE',
            CRB_CODE: 1
        },
        {
            CRB_NAME: 'B1',
            CRB_CODE: 2
        },
        {
            CRB_NAME: 'B2',
            CRB_CODE: 3
        },
        {
            CRB_NAME: 'B3',
            CRB_CODE: 4
        }
    ]

    useEffect(() => {
        let ObjAVLStatus = {
            // "Master": "VIEW_STNSTATUS"
            "Master": "LOOKUPMAST",
            "Text": "StnStatus"
        }

        const fetchData = async () => {
            let GetAVLDT = DropDown.VIEW_STNSTATUS;
            setAVLStatus(GetAVLDT);
        }

        fetchData();
    }, [])

    useEffect(() => {
        searchStone(undefined, !JSON.parse(localStorage.getItem('isRefresh')));
    }, [props.isRefresh])

    const searchStone = (eventKey, isApiFetch) => {
        debugger
        if (size.fromSize != null && size.toSize != null) {
            const concatenatedValue = `${size.fromSize}-${size.toSize}`;
            if (concatenatedValue === "-") {
                obj.SizeName = "";
            }
            obj.SizeName = Array.isArray(obj.SizeName) ? [...obj.SizeName, concatenatedValue] : [''];
        }
        if (eventKey != undefined) {
            toggleSection(eventKey)
        }

        const keyMappings = {
            'Shape': { array: [...(Array.isArray(ShapMastIsWeb) ? ShapMastIsWeb : []), ...(Array.isArray(ShapMastNoWeb) ? ShapMastNoWeb : [])], valueKey: 'ShpName', codeKey: 'SCode' },
            // 'StnStatus': { array: objStnStatus, valueKey: 'NAME', codeKey: 'NAME' },
            'Color': { array: ColMastObj, valueKey: 'C_NAME', codeKey: 'C_CODE' },
            'Cut': { array: CutMast, valueKey: 'CT_NAME', codeKey: 'CT_CODE' },
            'Fluorescent': { array: FloMast, valueKey: 'FL_NAME', codeKey: 'FL_CODE' },
            'Clarity': { array: QuaMast, valueKey: 'Q_NAME', codeKey: 'Q_CODE' },
            'Lab': { array: LabMast, valueKey: 'LAB_NAME', codeKey: 'LAB_CODE' },
            'CTB_CODE': { array: objCBlk, valueKey: 'CTB_NAME', codeKey: 'CTB_CODE' },
            'CRB_CODE': { array: objCRwBlk, valueKey: 'CRB_NAME', codeKey: 'CRB_CODE' },
            'CT_CODE': { array: CutMast, valueKey: 'CT_NAME', codeKey: 'CT_CODE' },
            'Polish': { array: CutMast, valueKey: 'CT_NAME', codeKey: 'CT_CODE' },
            'Symmetry': { array: CutMast, valueKey: 'CT_NAME', codeKey: 'CT_CODE' },
            'LocationCode': { array: LocMast, valueKey: 'LOC_NAME', codeKey: 'LOC_CODE' },
            'SizeName': { array: SizeMast, valueKey: 'SZ_NAME', codeKey: 'SZ_NAME' },
            'DiamondType': { array: objDimTyp, valueKey: 'D_NAME', codeKey: 'D_NAME' },
            'StnStatus': { array: AVLStatus, valueKey: 'NAME', codeKey: 'CODE' }
        };

        const selectedValues = {};

        for (const [key, { array, valueKey, codeKey }] of Object.entries(keyMappings)) {
            if (obj[key]) {
                const selectedItems = obj[key]?.map(code => {
                    const selectedItem = array.find(item => item[codeKey] == code);
                    return selectedItem && selectedItem[valueKey] !== '' ? selectedItem[valueKey] : null;
                })?.filter(Boolean);
                selectedValues[key] = selectedItems.join(',');
            }
        }

        const multiSelectValues = {
            'Brown': filtereObj.BROWN_CODE?.map(code => BrownMast.find(item => item.BROWN_CODE === code)?.BROWN_NAME).join(','),
            'Green': filtereObj.GREEN_CODE?.map(code => GreenMast.find(item => item.GREEN_CODE === code)?.GREEN_NAME).join(','),
            'Milky': filtereObj.MILKY_CODE?.map(code => MilkyMast.find(item => item.MILKY_CODE === code)?.MILKY_NAME).join(','),
            'BGM': filtereObj.BGM_CODE?.map(code => BGMMast.find(item => item.BGM_CODE === code)?.BGM_NAME).join(','),
            'Shade': filtereObj.SH_CODE?.map(code => ShadeMast.find(item => item.SH_CODE === code)?.SH_NAME).join(','),
            'BlackTblInc:': filtereObj.BTINC_CODE?.map(code => BlackTblMast.find(item => item.BTINC_CODE === code)?.BTINC_CODE).join(','),
            'BlackSideInc:': filtereObj.BTINC_CODE?.map(code => BlackTblMast.find(item => item.BTINC_CODE === code)?.BTINC_CODE).join(','),
            'WhiteTblInc:': filtereObj.BTINC_CODE?.map(code => BlackTblMast.find(item => item.BTINC_CODE === code)?.BTINC_CODE).join(','),
            'WhiteSideInc:': filtereObj.BTINC_CODE?.map(code => BlackTblMast.find(item => item.BTINC_CODE === code)?.BTINC_CODE).join(','),
            'OpenTblInc:': filtereObj.BTINC_CODE?.map(code => BlackTblMast.find(item => item.BTINC_CODE === code)?.BTINC_CODE).join(','),
            'OpenSideInc:': filtereObj.BTINC_CODE?.map(code => BlackTblMast.find(item => item.BTINC_CODE === code)?.BTINC_CODE).join(','),
            'OpenPavInc:': filtereObj.BTINC_CODE?.map(code => BlackTblMast.find(item => item.BTINC_CODE === code)?.BTINC_CODE).join(',')
        };

        Object.assign(selectedValues, multiSelectValues);

        const AttachValue = [
            { From: 'FTablePer', To: 'TTablePer' },
            { From: 'DepthFrom', To: 'DepthTo' },
            { From: 'RatioFrom', To: 'RatioTo' },
            { From: 'LengthFrom', To: 'LengthTo' },
            { From: 'WidthFrom', To: 'WidthTo' },
            { From: 'HeightFrom', To: 'HeightTo' },
            { From: 'CrwAngleFrom', To: 'CrwAngleTo' },
            { From: 'PavAngleFrom', To: 'PavAngleTo' },
            { From: 'GirdlePerFrom', To: 'GirdlePerTo' },
            { From: 'CrwHeightPerFrom', To: 'CrwHeightPerTo' },
            { From: 'PavDepthPerFrom', To: 'PavDepthPerTo' },
            { From: 'StoneDiscFrom', To: 'StoneDiscTo' },
            { From: 'StonePriceFrom', To: 'StonePriceTo' },
            { From: 'StoneValueFrom', To: 'StoneValueTo' },
            { From: 'PKTID', To: 'PKTID' },
            { From: 'MFGID', To: 'MFGID' },
            { From: 'CertificateNo', To: 'CertificateNo' }
        ]

        AttachValue.forEach(({ From, To }) => {
            const fromValue = obj[From];
            const toValue = obj[To];
            let newKey;
            if (fromValue !== undefined && toValue !== undefined) {

                if (fromValue === toValue) {
                    // const value = obj[key].replace(/\s+/g, ',');
                    // transformedObj[key] = value;
                    newKey = `${From}`;
                    selectedValues[newKey] = `${fromValue}`.replace(/\s+/g, ',');

                }
                else {
                    newKey = `${From}_${To}`;
                    selectedValues[newKey] = `${fromValue}-${toValue}`;
                }

            }
        });

        let ObjString_WithArray = "DiamondType,Shape,SizeName,Lab,LocationCode,StnStatus"
        let ObjString_WithOutArray = "CertificateNo,StockNo,MfgID,PktID"
        for (let key in obj) {
            if (Array.isArray(obj[key])) {

                if (ObjString_WithArray.includes(key) === true) {
                    transformedObj[key] = [obj[key].join(',')];
                }
                else {
                    transformedObj[key] = obj[key]?.map(Number);
                }
            }
            else {
                if (ObjString_WithOutArray.includes(key) === false) {
                    transformedObj[key] = Number(obj[key])
                }
                else {
                    if (key == 'PktID') {
                        const value = obj[key].replace(/\s+/g, ',');
                        transformedObj[key] = value;
                    }
                    else if (key == 'MfgID') {
                        const value = obj[key].replace(/\s+/g, ',');
                        transformedObj[key] = value;
                    }
                    else if (key == 'CertificateNo') {
                        const value = obj[key].replace(/\s+/g, ',');
                        transformedObj[key] = value;
                    }
                    else {
                        transformedObj[key] = (obj[key]);
                    }
                }
            }
        }

        if (filtereObj.BROWN_CODE?.length > 0) {
            transformedObj['Brown'] = filtereObj.BROWN_CODE;
        }
        if (filtereObj.GREEN_CODE?.length > 0) {
            transformedObj['Green'] = filtereObj.GREEN_CODE;
        }
        if (filtereObj.MILKY_CODE?.length > 0) {
            transformedObj['Milky'] = filtereObj.MILKY_CODE;
        }
        if (filtereObj.BGM_CODE?.length > 0) {
            transformedObj['BGM'] = filtereObj.BGM_CODE;
        }
        if (filtereObj.SH_CODE?.length > 0) {
            transformedObj['Shade'] = filtereObj.SH_CODE;
        }
        if (filtereObj.BTINC_CODE?.length > 0) {
            transformedObj['BlackTblInc'] = filtereObj.BTINC_CODE;
        }
        if (filtereObj.BSINC_CODE?.length > 0) {
            transformedObj['BlackSideInc'] = filtereObj.BSINC_CODE;
        }
        if (filtereObj.WTINC_CODE?.length > 0) {
            transformedObj['WhiteTblInc'] = filtereObj.WTINC_CODE;
        }
        if (filtereObj.WSINC_CODE?.length > 0) {
            transformedObj['WhiteSideInc'] = filtereObj.WSINC_CODE;
        }
        if (filtereObj.OTINC_CODE?.length > 0) {
            transformedObj['OpenTblInc'] = filtereObj.OTINC_CODE;
        }
        if (filtereObj.OSINC_CODE?.length > 0) {
            transformedObj['WhiteSideInc'] = filtereObj.OSINC_CODE;
        }
        if (filtereObj.OPINC_CODE?.length > 0) {
            transformedObj['WhitePavInc'] = filtereObj.OPINC_CODE;
        }

        if (isApiFetch == true) {
            getAllData();
        }
        SetSearchObj(Object.keys(selectedValues).length === 0 ? undefined : selectedValues);

        closeModal();
    }


    const toggleSection = (eventKey) => {
        setActiveKey(activeKey === eventKey ? null : eventKey);
        const element = document.querySelector('.StickyHeader');
        const elementModify = document.querySelector('.BtnModifySearch');
        if (element) {
            if (eventKey == 1) {
                element.style.display = 'none';
                if (elementModify) {
                    elementModify.style.display = 'block';
                    setStoneSearchCap('');
                }
            }
            else {
                element.style.display = 'block';
                if (elementModify) {
                    elementModify.style.display = 'none';
                    setStoneSearchCap('STONE SEARCH');
                }
            }
        }
    };

    const RemoveSelection = (key, items) => {

        const newSearchObj = { ...SearchObj };


        if (key.includes('_')) {
            const FromKey = key.split('_')[0];
            delete obj['' + FromKey + ''];

            const ToKey = key.split('_')[1];
            delete obj['' + ToKey + ''];
        }

        if (key == "" || key == undefined) {
            delete obj[key];
            delete newSearchObj[key];
        }

        delete obj['' + key + ''];
        delete newSearchObj['' + key + ''];

        SetSearchObj(newSearchObj);

        dispatch({ type: 'RemoveKey', payload: { key } });

        if (Object.keys(newSearchObj).length === 0) {
            SetSearchObj(undefined);
        }

        delete transformedObj[key];
        getAllData();
    }

    const handleOtherShapeClick = () => {
        setisOtherShpClicked(true);
    };

    return (
        // function MyVerticallyCenteredModal(props) {
        //     return (
        <>

            <Modal
                show={showModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ Width: '2000px', margin: 'auto' }}
                dialogClassName="custom-modal modalwidth"
                onHide={() => closeModal()}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    {filterModalOpen && <PacketSearchComp />}

                    <div>

                        {
                            (SearchObj && Object.keys(SearchObj).length > 0) &&
                            Object.keys(SearchObj).map((key, i) => {
                                if (SearchObj[key] !== undefined && SearchObj[key] !== null && SearchObj[key].length !== 0) {
                                    const items = SearchObj[key].split(',');
                                    // return (
                                    //     <div key={i} className="btn-group DataFilterButton me-2 my-0 pb-1" role="group" aria-label="Basic example">
                                    //         <button type=" button" key={key} className="btn btnClose border-0 fw-semibold">
                                    //             {items.map((item, index) => (
                                    //                 <span className='mb-0' key={index}>
                                    //                     {index === items.length - 1 ? item : item + ','}
                                    //                 </span>
                                    //             ))}
                                    //         </button>
                                    //         <button type="button" className="btn btnClose border-0" onClick={() => RemoveSelection(key, items)}><IoClose size={15} className='ms-3' /></button>
                                    //     </div>
                                    // );
                                }
                                return null;
                            })
                        }
                        <div className='py-2 px-2 px-md-3'>
                            <div className='row Bg-White rounded-2'>
                                <div className='col-12 px-1 px-md-3'>
                                    <h5 className=''>BASIC INFO</h5>
                                    <hr />
                                </div>

                                <div className="col-12 col-lg-8">
                                    <div className="row align-items-center">
                                        <div className="col-5 col-md-2">
                                            <div className='px-2'>
                                                <p className='h7'>Diamond Type</p>
                                            </div>
                                        </div>
                                        <div className="col-7 col-md-10">
                                            <div className='d-flex'>
                                                <StoneSearchComponent objMast={objDimTyp} objkey='DiamondType' code='D_NAME' name="D_NAME" obj={obj} getValue={getValue} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center pt-md-2">
                                        <div className="col-md-2">
                                            <div className='px-2'>
                                                <p className='h7'>Inputs</p>
                                            </div>
                                        </div>
                                        <div className="col-md-10 pt-2 pt-md-0">
                                            <div className='Base-Input d-block d-md-flex justify-content-between'>
                                                <input type="text" className='me-2 mb-2' name="PktID" onChange={getValue} value={obj.PktID ?? ''} id="" placeholder='Enter PKTID' />
                                                <input type="text" className='me-2 mb-2' name="MfgID" onChange={getValue} value={obj.MfgID ?? ''} id="" placeholder='Enter MFGID' />
                                                <input type="text" className='me-2 mb-2' name="CertificateNo" onChange={getValue} value={obj.CertificateNo ?? ''} id="" placeholder='Enter CertID' />
                                            </div>
                                        </div>
                                    </div>
                                    {/* 
                                    <div className="row align-items-center pt-2">
                                        <div className="col-5 col-md-2">
                                            <div className='px-2'>
                                                <p className='h7'>Availability</p>
                                            </div>
                                        </div>
                                        <div className="col-7 col-md-10">
                                            <div className='d-flex'>
                                                <StoneSearchComponent objMast={objStnStatus} objkey='StnStatus' code='NAME' name="NAME" obj={obj} getValue={getValue} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row align-items-center pt-3">
                                        <div className="col-md-2">
                                            <div className='px-2 pb-3 pb-md-0'>
                                                <p className='h7'>Shape</p>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className='d-flex flex-wrap gap-2'>
                                                {
                                                    ShapMastIsWeb?.map((x, i) => {
                                                        return <div key={x.S_CODE} className='' >

                                                            <label className={`ShapeDivBtnNew  CursorPoinrer  ${obj.Shape?.includes(x.S_CODE) ? 'selectedItems' : ''}`}>
                                                                <div className='text-center'>
                                                                    <input type="checkbox" className='d-none' name='Shape' value={x.S_CODE} checked={obj.Shape?.includes(x.S_CODE) == true} onChange={getValue} />
                                                                    <div dangerouslySetInnerHTML={{ __html: x.S_SVG }} ></div>
                                                                    <p className='m-0 '>{x.S_NAME}</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    })
                                                }

                                                {/* "Other Shp" button to show non-web shapes */}
                                                <div className=''>
                                                    <label className="ShapeDivBtnNew CursorPoinrer" onClick={() => setShowMoreShapes(!showMoreShapes)}>
                                                        <div className='text-center'>
                                                            <p className='m-0 mt-3'><MdReadMore size={22} /></p>
                                                            <p className='m-0'>Other Shp</p>
                                                        </div>
                                                    </label>
                                                </div>

                                                {showMoreShapes && ShapMastNoWeb?.map((x) => (
                                                    <div key={x.S_CODE} className=''>
                                                        <label className={`ShapeDivBtnNew CursorPoinrer ${obj.Shape?.includes(x.S_CODE) ? 'selectedItems' : ''}`}>
                                                            <div className='text-center'>
                                                                <input
                                                                    type="checkbox"
                                                                    className='d-none'
                                                                    name='Shape'
                                                                    value={x.S_CODE}
                                                                    checked={obj.Shape?.includes(x.S_CODE) === true}
                                                                    onChange={getValue}
                                                                />
                                                                <div dangerouslySetInnerHTML={{ __html: x.S_SVG }}></div>
                                                                <p className='m-0'>{x.S_NAME}</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center pt-4">
                                        <div className="col-md-2">
                                            <div className='px-2 pb-2 pb-md-0'>
                                                <p className='h7'>Carat</p>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className='row'>
                                                <div className="col-12">
                                                    <div className='Carat-Input pb-3'>
                                                        <div className="row Base-Input">
                                                            <div className="col-6 col-md-3">
                                                                <input type="text" className='me-2 w-100' name="" id="" placeholder='From' value={size.fromSize} onChange={(e) => setsize({ ...size, fromSize: e.target.value })} />
                                                            </div>
                                                            <div className="col-6 col-md-3">
                                                                <input type="text" className='me-2 w-100' name="" id="" placeholder='To' value={size.toSize} onChange={(e) => setsize({ ...size, toSize: e.target.value })} />
                                                            </div>
                                                            <div className="col-12 col-md-1 text-center">
                                                                <IoAddCircleOutline size={24} className='me-2 CursorPoinrer' onClick={AddSize} />
                                                            </div>

                                                            <div className="col-12 col-md-5 pt-1 py-md-0">
                                                                <input type="text" name="SizeName" id="" placeholder='From + To' className='w-100' value={obj.SizeName ?? ''} onChange={() => { }} />
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className='d-flex flex-wrap'>
                                                        {
                                                            SizeMast?.map((x, i) => {
                                                                return <div key={i} >
                                                                    <label className={`CaratDivBtn me-3 CursorPoinrer 1 ${obj.SizeName?.includes('' + x.SZ_NAME + '') ? 'selectedItems' : ''}`}>
                                                                        <div className='text-center'>
                                                                            <input type="checkbox" className='d-none' name='SizeName' value={x.SZ_NAME} checked={obj.SizeName?.includes('' + x.SZ_NAME + '') == true} onChange={getValue} />
                                                                            <p name='1' className='m-0'>{(x.FCARAT).toFixed(3)}</p>
                                                                            <PiArrowsVerticalFill size={13} />
                                                                            <p name='T_Size' className='m-0'>{(x.TCARAT).toFixed(3)}</p>
                                                                        </div>

                                                                    </label>
                                                                </div>

                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-center pt-4">
                                            <div className="col-md-2">
                                                <div className='px-2 pb-2 pb-md-0'>
                                                    <p className='h7'>Color</p>
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className='d-flex flex-wrap'>
                                                    <StoneSearchComponent objMast={ColMastObj} code='C_CODE' objkey='Color' name="C_NAME" obj={obj} getValue={getValue} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-center pt-4">
                                            <div className="col-md-2">
                                                <div className='px-2 pb-2 pb-md-0'>
                                                    <p className='h7'>Fancy Color</p>
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className='d-block d-md-flex justify-content-between multiSelect'>
                                                    <MultiSelect value={selFanCol} onChange={(e) => setselFanCol(e.value)} options={FanColObj} optionLabel="C_NAME" filter
                                                        placeholder="Fancy Color" className='me-2 mb-2' />

                                                    <MultiSelect value={selFanColInt} onChange={(e) => setselFanColInt(e.value)} options={FanColObj} optionLabel="C_NAME" filter
                                                        placeholder="Fancy Color Intensity" className='me-2 mb-2' />
                                                    <MultiSelect value={selFanColOvr} onChange={(e) => setselFanColOvr(e.value)} options={FanColObj} optionLabel="C_NAME" filter
                                                        placeholder="Fancy Color Overtone" className='me-2 mb-2' />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row align-items-center pt-4">
                                            <div className="col-md-2">
                                                <div className='px-2 pb-2 pb-md-0'>
                                                    <p className='h7'>Clarity</p>
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className='d-flex flex-wrap'>
                                                    <StoneSearchComponent objMast={QuaMast} code='Q_CODE' objkey='Clarity' name="Q_NAME" obj={obj} getValue={getValue} />
                                                </div>
                                            </div>
                                        </div>



                                        <div className={`row align-items-center pt-3 ${isOtherShpClicked ? 'mb-5' : ''}`}>
                                            <div className="col-md-2">
                                                <div className='px-2 py-2'>
                                                    <p className='h7'>Price</p>
                                                </div>
                                            </div>
                                            <div className="col-md-10 Base-Input">
                                                <div className='row Price-Input py-1 d-flex justify-content-between flex-wrap'>

                                                    <div className="col-md-4 d-flex">
                                                        <div className='me-3'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label ms-2 F12">Disc %</label>
                                                            <input type="text" className="form-control w-100" id="" name="StoneDiscFrom" onChange={getValue} value={obj.StoneDiscFrom ?? ''} placeholder='From'></input>
                                                        </div>
                                                        <div className=''>
                                                            <label htmlFor="exampleInputEmail1" className="form-label ms-2"> &nbsp; </label>
                                                            <input type="text" className="form-control w-100" id="" name="StoneDiscTo" onChange={getValue} value={obj.StoneDiscTo ?? ''} placeholder='To'></input>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 d-flex">
                                                        <div className='me-3'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label ms-2 F12"> $/CT </label>
                                                            <input type="text" className="form-control w-100" id="" name="StonePriceFrom" onChange={getValue} value={obj.StonePriceFrom ?? ''} placeholder='From'></input>
                                                        </div>
                                                        <div className=''>
                                                            <label htmlFor="exampleInputEmail1" className="form-label ms-2"> &nbsp; </label>
                                                            <input type="text" className="form-control w-100" id="" name="StonePriceTo" onChange={getValue} value={obj.StonePriceTo ?? ''} placeholder='To'></input>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 d-flex">
                                                        <div className='me-3'>
                                                            <label htmlFor="exampleInputEmail1" className="form-label ms-2 F12"> Value $ </label>
                                                            <input type="text" className="form-control w-100" d="" name="StoneValueFrom" onChange={getValue} value={obj.StoneValueFrom ?? ''} placeholder='From'></input>
                                                        </div>
                                                        <div className=''>
                                                            <label htmlFor="exampleInputEmail1" className="form-label ms-2"> &nbsp; </label>
                                                            <input type="text" className="form-control w-100" d="" name="StoneValueTo" onChange={getValue} value={obj.StoneValueTo ?? ''} placeholder='To'></input>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>



                                <div className="col-1 d-lg-block d-none">
                                    <div className='d-flex justify-content-center'>
                                        <div className='LineGradingStone'>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12 col-lg-3 d-flex">
                                    <div className='py-2'>

                                        <div className='row text-md-center d-md-block d-flex align-items-center pt-3 pt-md-0'>
                                            <div className='col-12'>
                                                <div className='px-2'>
                                                    <p className='h7 heading'>Cut</p>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='d-flex flex-wrap'>
                                                    <StoneSearchComponent objMast={CutMast} code='CT_CODE' objkey='Cut' name="CT_NAME" obj={obj} getValue={getValue} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <div className='LineGradingBottom d-none d-md-block'>
                                            </div>
                                        </div>

                                        <div className='row text-md-center d-md-block d-flex align-items-center pt-3 pt-md-0'>
                                            <div className='col-12'>
                                                <div className='px-2'>
                                                    <p className='h7 heading'>Polish</p>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='d-flex flex-wrap'>
                                                    <StoneSearchComponent objMast={CutMast} code='CT_CODE' objkey='Polish' name="CT_NAME" obj={obj} getValue={getValue} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <div className='LineGradingBottom d-none d-md-block'>
                                            </div>
                                        </div>

                                        <div className='row text-md-center d-md-block d-flex align-items-center pt-3 pt-md-0'>
                                            <div className='col-12'>
                                                <div className='px-2'>
                                                    <p className='h7 heading'>Symmetry</p>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='d-flex flex-wrap'>
                                                    <StoneSearchComponent objMast={CutMast} code='CT_CODE' objkey='Symmetry' name="CT_NAME" obj={obj} getValue={getValue} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <div className='LineGradingBottom d-none d-md-block'>
                                            </div>
                                        </div>

                                        <div className='row text-md-center d-md-block d-flex align-items-center pt-3 pt-md-0'>
                                            <div className='col-12'>
                                                <div className='px-2'>
                                                    <p className='h7 heading'>Fluorescence</p>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='d-flex flex-wrap'>
                                                    <StoneSearchComponent objMast={FloMast} code='FL_CODE' objkey='Fluorescent' name="FL_NAME" obj={obj} getValue={getValue} />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="d-flex justify-content-center">
                                            <div className='LineGradingBottom d-none d-md-block'>
                                            </div>
                                        </div>

                                        <div className='row text-md-center d-md-block d-flex align-items-center pt-3 pt-md-0'>
                                            <div className='col-12'>
                                                <div className='px-2'>
                                                    <p className='h7 heading'>Lab</p>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='d-flex flex-wrap'>
                                                    <StoneSearchComponent objMast={LabMast} code='LAB_CODE' objkey='Lab' name="LAB_NAME" obj={obj} getValue={getValue} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center ">
                                            <div className='LineGradingBottom d-none d-md-block'>
                                            </div>
                                        </div>

                                        <div className='row text-md-center d-md-block d-flex align-items-center pt-3 pt-md-0'>
                                            <div className='col-12'>
                                                <div className='px-2'>
                                                    <p className='h7 heading'>Location</p>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='d-flex flex-wrap location'>
                                                    <StoneSearchComponent objMast={LocMast} code='LOC_CODE' objkey='LocationCode' name="LOC_NAME" obj={obj} getValue={getValue} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <Collapse in={open}>
                                    <div id='example-collapse-text' className='pt-4'>
                                        <div className='px-2'>
                                            <h5 className=''>MEASUREMENT</h5>
                                            <hr />
                                        </div>
                                        <div className='col-12 pt-md-2'>
                                            <div className="row">
                                                <div className='col-12 px-4 mb-3'>
                                                    <div className='d-block d-md-flex justify-content-between multiSelect'>
                                                        <div className='col-2'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>Brown</label>
                                                            <MultiSelect
                                                                value={filtereObj.BROWN_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, BROWN_CODE: e.target.value })}
                                                                options={BrownMast?.map((option) => ({ label: option.BROWN_NAME, value: option.BROWN_CODE }))}
                                                                placeholder="Select Brown"
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-2'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>Green</label>
                                                            <MultiSelect
                                                                value={filtereObj.GREEN_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, GREEN_CODE: e.target.value })}
                                                                options={GreenMast?.map((option) => ({ label: option.GREEN_NAME, value: option.GREEN_CODE }))}
                                                                placeholder="Select Green"
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-2'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>Milky</label>
                                                            <MultiSelect
                                                                value={filtereObj.MILKY_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, MILKY_CODE: e.target.value })}
                                                                options={MilkyMast?.map((option) => ({ label: option.MILKY_NAME, value: option.MILKY_CODE }))}
                                                                placeholder="Select Milky"
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-2'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>BGM</label>
                                                            <MultiSelect
                                                                value={filtereObj.BGM_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, BGM_CODE: e.target.value })}
                                                                options={BGMMast?.map((option) => ({ label: option.BGM_NAME, value: option.BGM_CODE }))}
                                                                placeholder="Select BGM"
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-2'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>Shade</label>
                                                            <MultiSelect
                                                                value={filtereObj.SH_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, SH_CODE: e.target.value })}
                                                                options={ShadeMast?.map((option) => ({ label: option.SH_NAME, value: option.SH_CODE }))}
                                                                placeholder="Select Shade"
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-12 px-4 mb-3'>
                                                    <div className='d-block d-md-flex justify-content-between multiSelect'>
                                                        <div className='col-1'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>Black Table Inc.</label>
                                                            <MultiSelect
                                                                value={filtereObj.BTINC_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, BTINC_CODE: e.target.value })}
                                                                options={BlackTblMast?.map((option) => ({ label: option.IN_NAME, value: option.IN_CODE }))}
                                                                placeholder="Select Black Table Inc."
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-1'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>Black Side Inc.</label>
                                                            <MultiSelect
                                                                value={filtereObj.BSINC_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, BSINC_CODE: e.target.value })}
                                                                options={BlackTblMast?.map((option) => ({ label: option.IN_NAME, value: option.IN_CODE }))}
                                                                placeholder="Select Black Side Inc."
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-1'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>White Table Inc.</label>
                                                            <MultiSelect
                                                                value={filtereObj.WTINC_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, WTINC_CODE: e.target.value })}
                                                                options={WhiteTblMast?.map((option) => ({ label: option.IN_NAME, value: option.IN_CODE }))}
                                                                placeholder="Select White Table Inc."
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-1'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>White Side Inc.</label>
                                                            <MultiSelect
                                                                value={filtereObj.WSINC_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, WSINC_CODE: e.target.value })}
                                                                options={WhiteTblMast?.map((option) => ({ label: option.IN_NAME, value: option.IN_CODE }))}
                                                                placeholder="Select White Side Inc."
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-1'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>Open Table Inc.</label>
                                                            <MultiSelect
                                                                value={filtereObj.OTINC_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, OTINC_CODE: e.target.value })}
                                                                options={OpenTblMast?.map((option) => ({ label: option.IN_NAME, value: option.IN_CODE }))}
                                                                placeholder="Select Open Table Inc."
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-1'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>Open Side Inc.</label>
                                                            <MultiSelect
                                                                value={filtereObj.OSINC_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, OSINC_CODE: e.target.value })}
                                                                options={OpenTblMast?.map((option) => ({ label: option.IN_NAME, value: option.IN_CODE }))}
                                                                placeholder="Select Open Table Inc."
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>

                                                        <div className='col-1'>
                                                            <label htmlFor="exampleInputEmail1" className='h7'>Open Pav Inc.</label>
                                                            <MultiSelect
                                                                value={filtereObj.OPINC_CODE}
                                                                onChange={(e) => setfiltereObj({ ...filtereObj, OPINC_CODE: e.target.value })}
                                                                options={OpenTblMast?.map((option) => ({ label: option.IN_NAME, value: option.IN_CODE }))}
                                                                placeholder="Select Open Pav Inc."
                                                                maxSelectedLabels={10}
                                                                className='mt-1'
                                                                filter
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 mt-3">
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Table % </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="FTablePer" id="" placeholder="From" onChange={getValue} value={obj.FTablePer ?? ''} />
                                                            <input type="text" className='me-2' name="TTablePer" id="" placeholder="To" onChange={getValue} value={obj.TTablePer ?? ''} />
                                                        </div>
                                                    </div>
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Length </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="LengthFrom" id="" placeholder="From" onChange={getValue} value={obj.LengthFrom ?? ''} />
                                                            <input type="text" className='me-2' name="LengthTo" id="" placeholder="To" onChange={getValue} value={obj.LengthTo ?? ''} />
                                                        </div>
                                                    </div>
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Cr.Angle </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="CrwAngleFrom" id="" placeholder="From" onChange={getValue} value={obj.CrwAngleFrom ?? ''} />
                                                            <input type="text" className='me-2' name="CrwAngleTo" id="" placeholder="To" onChange={getValue} value={obj.CrwAngleTo ?? ''} />
                                                        </div>
                                                    </div>
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Cr.Height </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="CrwHeightPerFrom" id="" placeholder="From" onChange={getValue} value={obj.CrwHeightPerFrom ?? ''} />
                                                            <input type="text" className='me-2' name="CrwHeightPerTo" id="" placeholder="To" onChange={getValue} value={obj.CrwHeightPerTo ?? ''} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 mt-3">
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Depth % </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="DepthFrom" id="" placeholder="From" onChange={getValue} value={obj.DepthFrom ?? ''} />
                                                            <input type="text" className='me-2' name="DepthTo" id="" placeholder="To" onChange={getValue} value={obj.DepthTo ?? ''} />
                                                        </div>
                                                    </div>
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> WIdth </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="WidthFrom" id="" placeholder="From" onChange={getValue} value={obj.WidthFrom ?? ''} />
                                                            <input type="text" className='me-2' name="WidthTo" id="" placeholder="To" onChange={getValue} value={obj.WidthTo ?? ''} />
                                                        </div>
                                                    </div>
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Pav.Angle </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="PavAngleFrom" id="" placeholder="From" onChange={getValue} value={obj.PavAngleFrom ?? ''} />
                                                            <input type="text" className='me-2' name="PavAngleTo" id="" placeholder="To" onChange={getValue} value={obj.PavAngleTo ?? ''} />
                                                        </div>
                                                    </div>
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Pav.Height </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="PavDepthPerFrom" id="" placeholder="From" onChange={getValue} value={obj.PavDepthPerFrom ?? ''} />
                                                            <input type="text" className='me-2' name="PavDepthPerTo" id="" placeholder="To" onChange={getValue} value={obj.PavDepthPerTo ?? ''} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 mt-3">
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Ratio % </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="RatioFrom" id="" placeholder="From" onChange={getValue} value={obj.RatioFrom ?? ''} />
                                                            <input type="text" className='me-2' name="RatioTo" id="" placeholder="To" onChange={getValue} value={obj.RatioTo ?? ''} />
                                                        </div>
                                                    </div>
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Height </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="HeightFrom" id="" placeholder="From" onChange={getValue} value={obj.HeightFrom ?? ''} />
                                                            <input type="text" className='me-2' name="HeightTo" id="" placeholder="To" onChange={getValue} value={obj.HeightTo ?? ''} />
                                                        </div>
                                                    </div>
                                                    <div className='Measure-Input  d-flex justify-content-between px-md-3 py-2 flex-wrap'>
                                                        <label htmlFor="exampleInputEmail1" className="h7"> Girdle % </label>
                                                        <div>
                                                            <input type="text" className='me-2' name="GirdlePerFrom" id="" placeholder="From" onChange={getValue} value={obj.GirdlePerFrom ?? ''} />
                                                            <input type="text" className='me-2' name="GirdlePerTo" id="" placeholder="To" onChange={getValue} value={obj.GirdlePerTo ?? ''} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </Collapse>

                            </div>

                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className='dynamic-width'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex me-2'>
                                <button type="button" data-toggle="collapse" className="btn btn-sm btn-outline-primary F12 ml-md-2 me-2" aria-expanded="true" onClick={() => searchStone("1", true)}>
                                    <BiSearchAlt size={17} /> Search
                                </button>
                                <button type="button" data-toggle="collapse" className="btn btn-sm btn-outline-primary F12 ml-md-2" onClick={ResetValue} aria-expanded="true">
                                    <BiReset size={17} /> Reset
                                </button>
                            </div>
                            <div className='d-flex'>
                                <button type="button" data-toggle="collapse" className="btn btn-sm btn-outline-primary F12 ml-md-2 me-2" aria-expanded="true">
                                    <MdSaveAs size={17} /> Save Filter
                                </button>
                                <button type="button" className="btn btn-sm btn-outline-primary ml-md-2 F12" onClick={() => handleCollapseButtonClick()} aria-controls="example-collapse-text"
                                    aria-expanded={open}
                                >
                                    <MdFilterAlt size={17} /> Advanced Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
    // }
    // )
}

export default PacketSearchComp