import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod, sendError_notify } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';
import { AlertPopup } from "../../../Asset/common";

let PacketSearchData = {
  "Shape": [
    "R"
  ],
  "Lab": [
    ""
  ],
  "Color": [
    1, 2, 3, 4, 5, 6, 7, 8, 9
  ],
  "Clarity": [
    1, 2, 3, 4, 5, 6, 7, 8, 9
  ],
  "Cut": [
    0
  ],
  "Polish": [
    0
  ],
  "Symmetry": [
    0
  ],
  "Fluorescent": [
    0
  ],
  "Shade": [
    0
  ],
  "Brown": [
    0
  ],
  "Culet": [
    ""
  ],
  "HeartArrow": [
    0
  ],
  "BlackInc": [
    0
  ],
  "WhiteInc": [
    0
  ],
  "OpenInc": [
    0
  ],
  "Girdle": [
    0
  ],
  "CaratFrom": 0.01,
  "CaratTo": 99.0,
  "StonePrice": "",
  "StonePriceFrom": 0,
  "StonePriceTo": 0,
  "StockNo": "",
  "CertificateNo": "",
  "LocationCode": "",
  "TableDepthFrom": 0,
  "TableDepthTo": 0,
  "DepthFrom": 0,
  "DepthTo": 0,
  "LengthFrom": 0,
  "LengthTo": 0,
  "WidthFrom": 0,
  "WidthTo": 0,
  "RatioFrom": 0,
  "RatioTo": 0,
  "SearchType": ""
}

export const fetchData = (data, RecordPerPage) => async (dispatch) => {
  if (!data) return; 
  
  data.PageNo = [1];
  dispatch(startLoader());

  try {
    const res = await HttpMethod.post('/Stock/StockSearch/StockList', data);

    if (res?.status === 200) {
      dispatch(resetCombinedData());
      dispatch(setFirstData(res?.data));
 
      const stockList = res?.data?.StockPacketList1 || [];
      const pcs = stockList[0]?.I_PCS;

      if (pcs) {
        const pages = Math.ceil(pcs / RecordPerPage);
        Array.from({ length: pages - 1 }, (_, index) => {
          dispatch(fetchNextData({ ...data, PageNo: [index + 2] }));
        });
      }
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    if (data?.PageNo[0] === 1) {
      dispatch(stopLoader());
    }
  }
};

export const fetchWebStockPartyData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const ObjJson = {
    jsonObj1: [data],
    SPName: "API_PartyStockExcelDisp"
  };
  let res = await HttpMethod.post(`/SystemConfig/InsUpdDel/DynSpExDisp`, ObjJson)
  dispatch(stopLoader())
  return res?.data?.DynDisp
};

export const fetchNextData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/Stock/StockSearch/StockList', data);
  if (res?.status === 200) {
      dispatch(appendApiData(await res.data));
  }
};

export const setEmpty = () => async (dispatch) => {
  dispatch(resetCombinedData({}));
}

export const PacketSearchSlice = createSlice({
  name: 'PacketSearch',
  initialState: { StockPacketList: [], StockPacketList1: []},
  reducers: {
    setFirstData: (state, action) => {
      state.StockPacketList = action.payload.StockPacketList; 
      state.StockPacketList1 = action.payload.StockPacketList1;
      return state;
    },
    appendApiData: (state, action) => {
      state.StockPacketList = [...state.StockPacketList, ...action.payload.StockPacketList];
      state.StockPacketList1 = action.payload.StockPacketList1;
      return state;
    },
    resetCombinedData: (state) => {
      state.StockPacketList = []; 
      return state;
    },
  },
});

export const { setFirstData, appendApiData, resetCombinedData } = PacketSearchSlice.actions;

export default PacketSearchSlice.reducer;