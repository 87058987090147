import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';
import { AlertPopup } from '../../../Asset/common';
import { fetchData as fetchPacketSearch } from './PacketSearchSlice';

export const TableName = "SETTING"

export const fetchData = () => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.get('/Master/Setting/GetList');
  if (res?.status === 200) {
    let isRefreshObj = res.data.Setting?.find(element => element.SKEY == 'IsRefresh')
    let RecordPerPageObj = res.data.Setting?.find(element => element.SKEY == 'RecordPerPage')
    if(isRefreshObj){
        localStorage.setItem('isRefresh', JSON.parse(isRefreshObj.SVALUE))
        if(JSON.parse(isRefreshObj.SVALUE)){
          dispatch(fetchPacketSearch({SearchType: 'PACKETVIEW'}, RecordPerPageObj.SVALUE))
        }
    }
    dispatch(getData(await res.data));

  }
  dispatch(stopLoader())
};

const constructDataJson = (data) => {


  let newData = {};
  for (let key in data) {
    newData[key.toLowerCase()] = data[key];
  }

  let dataJson = {};
  dataJson["jsonObj"] = [];
  dataJson["TableName"] = TableName;
  dataJson["jsonObj"].push(newData);
  return dataJson;
};

export const addData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Add', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
};


export const updateData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Update', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
};

export const deleteData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Delete', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
}

export const SettingSlice = createSlice({
  name: 'SETTING',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };
    }
  },
});
export const { getData } = SettingSlice.actions;
export default SettingSlice.reducer;