import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';
import { AlertPopup,errorPopup } from '../../../Asset/common';


export const TableName = "APISETTING"

export const fetchData = (isMessage) => async (dispatch) => {
  dispatch(startLoader())
  let ObjJsonDet = {
    jsonObj1: [{}],
    SPName: "APISettingDisp"
  }; 
  let res = await HttpMethod.post(`/SystemConfig/InsUpdDel/DynSpExDisp`, ObjJsonDet)
  if (res?.status === 200) {
    if(!(res.data.DynDisp) && isMessage){
      errorPopup(res.data)
      dispatch(getData({ DynDisp : []}));
    }
    else{
      dispatch(getData(await res.data.DynDisp));
    }

  }
  dispatch(stopLoader())
};


export const addData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/DynSpEx', data);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
};


export const deleteData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Delete', data);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
}

export const APISettingSlice = createSlice({
  name: 'AspNetUserRoles',
  initialState: [],
  loading: false,
  reducers: {
    getData: (state, action) => {
      return action.payload;
    }
  },
});
export const { getData } = APISettingSlice.actions;
export default APISettingSlice.reducer;