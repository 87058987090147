import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import GlidGrid from './Grid/GlidGrid.jsx';
import { GetColumnconfig } from '../Asset/httpMethod.js';

function PktErrorComp({ show, onHide, dataSource, onExcludePackets }) {

    const [GetViewPara, setGetViewPara] = useState([])

    useEffect(() => {
        Columnconfig()
    }, [dataSource])

    const Columnconfig = async () => {
        let ColumnConfig = await GetColumnconfig("PktError")
        setGetViewPara(ColumnConfig)
    }

    return (
        <>
            <Modal show={show} onHide={onHide} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Packet Validation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h6 className='text-danger'>{dataSource?.length} Packet{dataSource?.length !== 1 ? 's' : ''} Found With Error</h6>
                        <button className="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 mb-2" onClick={onExcludePackets}>Click here to Exclude These Packets</button>
                        <div className="glidgrid-container">
                            {
                                dataSource?.length > 0 && GetViewPara?.length > 0 &&
                                <GlidGrid
                                    data={dataSource}
                                    columns={GetViewPara}
                                />
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 mb-2" onClick={onHide}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PktErrorComp