import React, { Fragment, useState } from 'react';
import './Sidebar.css';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse, Dropdown, Offcanvas } from 'react-bootstrap';
// import Logo from "../Asset/Image/Logo/Logo.png";
import { useEffect } from 'react';
import IconComponent from '../Components/IconComponent';
import { HiBars3 } from 'react-icons/hi2';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoCloseOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { FormateRouting } from '../Redux/Slices/Utility/MenuPermition';

if (localStorage?.getItem('isOpen') != null) {
    debugger
    if (localStorage?.getItem('isOpen') == 'false') {
        document.documentElement.style.setProperty('--widthSidebar', '50px');
    } else {
        document.documentElement.style.setProperty('--widthSidebar', '230px');
    }
}
else {
    document.documentElement.style.setProperty('--widthSidebar', '230px');
}

function Sidebar() {
    const [isOpen, setIsOpen] = useState((localStorage?.getItem('isOpen') != null) && (localStorage?.getItem('isOpen') == 'false') ? false : true);
    let [openItem, setOpenItem] = useState({ openLevel1: null, openLevel2: null });
    const [menuPopupName, setMenuPopupName] = useState();
    const [subMenuPopupName, setSubMenuPopupName] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [IsSideBarShow, setIsSideBarShow] = useState(true);
    const isMobileScreen = window.innerWidth <= 767;
    let routesConfig1 = useSelector(state => state.MenuPermition)
    let routesConfig = routesConfig1 && routesConfig1.length > 0 && routesConfig1?.filter(item => item.IsShow === true);
    let [renderMenuConfig, setrenderMenuConfig] = useState([])

    let Setting = useSelector(state => state.Setting.Setting)
    let Logo = Setting?.find(Setting => Setting.SKEY == 'COMP_CODE' && Setting.KEYDESC)?.KEYDESC
    const [show, setShow] = useState(false);
    const [logoSize, setlogoSize] = useState({ height: "", width: "50px" })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSidebarOpen = () => {
        setIsSidebarOpen(true);
    };
    const handleSidebarClose = () => {
        setIsSidebarOpen(false);
    };

    useEffect(() => {
        if (Setting) {
            let [logoHeight, logoWidth] = Setting?.find(Setting => Setting.SKEY == 'LogoHeightWidth' && Setting.SVALUE)?.SVALUE?.split(',');
            setlogoSize({ logoHeight: logoHeight, logoWidth: logoWidth })
        }
    }, [Setting])


    const toggleSidebar = () => {
        const newIsOpen = !isOpen;
        if (newIsOpen == false) {
            setOpenItem({ openLevel1: null, openLevel2: null });
            document.documentElement.style.setProperty('--animation-name', 'sidebarClose');
            document.documentElement.style.setProperty('--widthSidebar', '50px');
            // document.querySelector('.sidebar').style.width = "50px";
        } else {
            document.documentElement.style.setProperty('--animation-name', 'sidebarOpen')
            document.documentElement.style.setProperty('--widthSidebar', '230px');
            // document.querySelector('.sidebar').style.width = "230px";
        }
        setIsOpen(newIsOpen);

        //LocalStorage Ma Add Krava Mate
        localStorage.setItem('isOpen', JSON.stringify(newIsOpen));
    };

    useEffect(() => {
        if (document.querySelector('.active')?.parentElement?.parentElement?.previousElementSibling) {
            document.querySelector('.active').parentElement.parentElement.previousElementSibling.classList.add('active')
        }

        if (window.innerWidth <= 768) {
            setIsSideBarShow(false);
        } else {
            setIsSideBarShow(true);
        }

    }, [window.innerWidth])

    useEffect(() => {
        document.documentElement.style.setProperty('--animation-name', '')

        //LocalStorage Ma Add Krava Mate
        const isOpenStored = localStorage?.getItem('isOpen');
        setIsOpen(isOpenStored ? JSON.parse(isOpenStored) : false);

    }, [])

    useEffect(() => {
        const Header = document.querySelector('.BgHeader');
        const StickyHeader = document.querySelector('.StickyHeader');
        if (Header != null) {
            if (isOpen == true && IsSideBarShow == true) {
                Header.style.width = `calc(100% - 230px)`;
                if (StickyHeader) {
                    StickyHeader.style.width = `calc(100% - 230px)`;
                }
            }
            else if (isOpen == false && IsSideBarShow == true) {
                Header.style.width = `calc(100% - 50px)`;
                if (StickyHeader) {
                    StickyHeader.style.width = `calc(100% - 50px)`;
                }
            }
            else if (IsSideBarShow == false) {
                Header.style.width = `calc(100%)`;
                if (StickyHeader) {
                    StickyHeader.style.width = `calc(100%)`;
                }
            }
        }

    }, [isOpen, IsSideBarShow])


    const handleItemClick = (e, item, level) => {
        e.stopPropagation();
        if (isOpen == true || isMobileScreen) {
            if (level == 1) {
                setOpenItem({ openLevel1: openItem.openLevel1 === item ? null : item });
            }
            if (level == 2) {
                setOpenItem({ ...openItem, openLevel2: openItem.openLevel2 === item ? null : item });
            }
        }
    };
    const hoverMenu = (e, name, route) => {
        if (!isOpen) {
            var rect = e.target.getBoundingClientRect();
            document.documentElement.style.setProperty('--menuPopup', (rect.top - 10) + 'px');
            if (route) {
                let submenu = route.children;
                let SidebarSubMenu = <>
                    <h6 className='text-center'>{name}</h6>
                    <hr />
                    {
                        submenu?.map((x) => {
                            return <NavLink to={'/' + x.RoutePath} className={'ps-2 p-0'} onMouseEnter={(e) => {
                                if (x.children && x.children.length > 0) {
                                    subHoverMenu(e, x.MenuName, x);
                                    hideMenuPopup('subMenuPopup', true)
                                }
                            }
                            }
                            > {x.MenuName} </NavLink>
                        })
                    }
                </>
                setMenuPopupName(SidebarSubMenu);
            } else {
                setMenuPopupName(name);
            }
        }
    }
    const subHoverMenu = (e, name, route) => {
        if (!isOpen) {
            var rect = e.target.getBoundingClientRect();
            document.documentElement.style.setProperty('--subMenuPopup', (rect.top - 10) + 'px');
            if (route) {
                let menuPopupContent = document.querySelector('.menuPopup');
                if (menuPopupContent) {
                    document.querySelector('.subMenuPopup').style.left = menuPopupContent.clientWidth + 'px';
                    console.log(document.querySelector('.subMenuPopup').left)
                }
                let submenu = route.children;
                let SidebarSubMenu = <>
                    <h6 className='text-center'>{name}</h6>
                    <hr />
                    {
                        submenu?.map((x) => {
                            return <NavLink to={'/' + x.RoutePath} className={'ps-2 p-0'}> {x.MenuName} </NavLink>
                        })
                    }
                </>
                setSubMenuPopupName(SidebarSubMenu);
            } else {
                setSubMenuPopupName(name);
            }
        }
    }

    const hideMenuPopup = (name, isEnter) => {
        const menuPopup = document.querySelector(`.${name}`);
        if (isEnter && !isOpen) {
            menuPopup.style.display = "block";
        } else {
            menuPopup.style.display = "none";
        }
    }
    useEffect(() => {
        if (routesConfig && renderMenuConfig.length == 0) {
            setrenderMenuConfig([...JSON.parse(JSON.stringify(FormateRouting(routesConfig)))])
        }
    }, [routesConfig])


    // let menuContent = <>
    //     <div className="Menu" style={{ marginTop: !isOpen ? '20px' :''}} onMouseLeave={() => hideMenuPopup(false)} onMouseEnter={() => hideMenuPopup(true)} >
    //         {routesConfig?.map((route, index) => {
    //             const { RoutePath, MenuCode, MenuName, MenuLevel, IsHaveSubMenu, MenuIconPath, MenuIcon } = route; 
    //             if (MenuLevel === 1) {
    //                 return (
    //                     <Fragment key={index}>
    //                         {
    //                             ((MenuLevel == 1) && (IsHaveSubMenu == false)) && <NavLink to={'/' + RoutePath}
    //                                 onMouseLeave={handleSidebarOpen} onMouseEnter={(e) => hoverMenu(e, MenuName)} >
    //                                 <div className='d-flex align-items-center' key={index}>
    //                                     <IconComponent MenuIcon={MenuIcon} MenuIconPath={MenuIconPath} color='var(---IconColor)' className='ms-3 fw-bold' size={24} />
    //                                     <div className='ps-2 d-md-block d-none'>
    //                                         {isOpen && MenuName}
    //                                     </div>
    //                                     <div className='ps-2 d-md-none d-block'>
    //                                         {MenuName}
    //                                     </div>
    //                                 </div>
    //                             </NavLink>
    //                         }
    //                         {
    //                             IsHaveSubMenu == true && <Dropdown onClick={() => handleItemClick(MenuName)} onMouseLeave={handleSidebarOpen} onMouseEnter={(e) => hoverMenu(e, MenuName, route)}
    //                                 aria-controls={MenuName} aria-expanded={openItem === MenuName} >
    //                                 <div className='Dropdown'>
    //                                     <div className='d-flex justify-content-between'>
    //                                         <div>
    //                                             <IconComponent MenuIcon={MenuIcon} MenuIconPath={MenuIconPath} color='var(---IconColor)' className='ms-3 fw-bold' size={22} />
    //                                             <span className='ps-2 d-md-inline d-none'>{isOpen && MenuName}</span>
    //                                             <span className='ps-2 d-md-none d-inline'>{MenuName}</span>
    //                                         </div>
    //                                         <div className='float-end me-2'>
    //                                             {
    //                                                 isMobileScreen ? 
    //                                                 <>
    //                                                     {openItem === MenuName ? 
    //                                                         <IoIosArrowUp className='logo' width={150} /> : 
    //                                                         <IoIosArrowDown className='logo' width={150} />
    //                                                     }
    //                                                 </>
    //                                                 :
    //                                                 <>
    //                                                     {openItem === MenuName ? 
    //                                                         <IoIosArrowUp className={`logo ${!isOpen ? 'logo-small' : ''}`} width={150} /> : 
    //                                                         <IoIosArrowDown className={`logo ${!isOpen ? 'logo-small' : ''}`} width={150} />
    //                                                     }
    //                                                 </>
    //                                             }
    //                                         </div>
    //                                     </div>
    //                                 </div>

    //                                 <Collapse in={(openItem === MenuName && (isOpen || isMobileScreen))} className='DropDownListDisp'>
    //                                     <div id={MenuName}> 
    //                                         {routesConfig?.map((Submenu, i) => {
    //                                             if (Submenu.ParentMenuCode.toLowerCase() === MenuCode.toLowerCase()) {
    //                                                 return (
    //                                                     <Fragment key={i}>
    //                                                         <div className="ms-3">
    //                                                             {
    //                                                                  <NavLink to={'/' + Submenu.RoutePath} className={'ps-2'}> {(isOpen || isMobileScreen) && Submenu.MenuName} </NavLink>
    //                                                             }
    //                                                         </div>
    //                                                     </Fragment>
    //                                                 )
    //                                             }
    //                                         })
    //                                         }
    //                                     </div>
    //                                 </Collapse>
    //                             </Dropdown>
    //                         }
    //                     </Fragment>
    //                 );
    //             }
    //         })
    //         }
    //         <div className='bg-white p-3 shadow menuPopup'>
    //             {<span>{menuPopupName}</span>}
    //         </div>
    //     </div >
    // </>

    const optimizerMenu = (menu, index) => {
        const { RoutePath, MenuCode, MenuName, MenuLevel, IsHaveSubMenu, MenuIconPath, MenuIcon, children } = menu;
        return <Fragment key={index}>
            <Dropdown onClick={(e) => handleItemClick(e, MenuName, MenuLevel)} onMouseLeave={handleSidebarOpen} onMouseEnter={(e) => hoverMenu(e, MenuName, menu)}
                aria-controls={MenuName} aria-expanded={MenuLevel == 1 ? openItem.openLevel1 === MenuName : openItem.openLevel2 === MenuName} >
                <div className='Dropdown'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <IconComponent MenuIcon={MenuIcon} MenuIconPath={MenuIconPath} color='var(---IconColor)' className='ms-3 fw-bold' size={22} />
                            <span className='ps-2 d-md-inline d-none'>{isOpen && MenuName}</span>
                            <span className='ps-2 d-md-none d-inline'>{MenuName}</span>
                        </div>
                        <div className='float-end me-2'>
                            {
                                isMobileScreen ?
                                    <>
                                        {(MenuLevel == 1 ? openItem.openLevel1 === MenuName : openItem.openLevel2 === MenuName) ?
                                            <IoIosArrowUp className='logo' width={150} /> :
                                            <IoIosArrowDown className='logo' width={150} />
                                        }
                                    </>
                                    :
                                    <>
                                        {(MenuLevel == 1 ? openItem.openLevel1 === MenuName : openItem.openLevel2 === MenuName) ?
                                            <IoIosArrowUp className={`logo ${!isOpen ? 'logo-small' : ''}`} width={150} /> :
                                            <IoIosArrowDown className={`logo ${!isOpen ? 'logo-small' : ''}`} width={150} />
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>

                <Collapse in={((MenuLevel == 1 ? openItem.openLevel1 === MenuName : openItem.openLevel2 === MenuName) && (isOpen || isMobileScreen))} className='DropDownListDisp'>
                    <div id={MenuName}>
                        {menu.children?.map((Submenu, i) => {
                            if (Submenu.children && Submenu.children.length > 0) {
                                return optimizerMenu(Submenu, i)
                            } else {
                                if (Submenu.ParentMenuCode.toLowerCase() === MenuCode.toLowerCase()) {
                                    return (
                                        <Fragment key={i}>
                                            <div className="ms-3">
                                                {
                                                    <NavLink to={'/' + Submenu.RoutePath} className={'ps-2'}> {(isOpen || isMobileScreen) && Submenu.MenuName} </NavLink>
                                                }
                                            </div>
                                        </Fragment>
                                    )
                                }
                            }
                        })
                        }
                    </div>
                </Collapse>
            </Dropdown>
        </Fragment>
    }

    let menuContent = <>
        <div className="Menu" style={{ marginTop: !isOpen ? '20px' : '' }} onMouseLeave={() => hideMenuPopup('menuPopup', false)} onMouseEnter={() => hideMenuPopup('menuPopup', true)} >
            {
                renderMenuConfig?.map((menu, index) => {
                    const { RoutePath, MenuCode, MenuName, MenuLevel, IsHaveSubMenu, MenuIconPath, MenuIcon, children } = menu;
                    return <Fragment key={index}>
                        {
                            children?.length == 0 ?
                                <>
                                    <NavLink to={'/' + RoutePath}
                                        onMouseLeave={handleSidebarOpen} onMouseEnter={(e) => hoverMenu(e, MenuName)} >
                                        <div className='d-flex align-items-center' key={index}>
                                            <IconComponent MenuIcon={MenuIcon} MenuIconPath={MenuIconPath} color='var(---IconColor)' className='ms-3 fw-bold' size={24} />
                                            <div className='ps-2 d-md-block d-none'>
                                                {isOpen && MenuName}
                                            </div>
                                            <div className='ps-2 d-md-none d-block'>
                                                {MenuName}
                                            </div>
                                        </div>
                                    </NavLink>
                                </> :
                                <>
                                    {
                                        optimizerMenu(menu, index)
                                    }
                                </>
                        }
                    </Fragment>
                })
            }
            <div className='bg-white p-3 shadow menuPopup'>
                {<span>{menuPopupName}</span>}
            </div>
            <div className='bg-white p-3 shadow subMenuPopup' onMouseLeave={() => hideMenuPopup('subMenuPopup', false)}>
                {<span>{subMenuPopupName}</span>}
            </div>
        </div >
    </>

    return (
        <>
            <div className={`sidebar position-sticky top-0 d-none d-md-block`} onMouseEnter={handleSidebarOpen} onMouseLeave={handleSidebarClose} >
                <div className='SidebarPosition'>
                    <div className='cursor-pointer'>
                        <button className='toggle-btn' onClick={toggleSidebar}>
                            <HiBars3 size={22} />
                        </button>
                    </div>
                    <div className='text-center p-2'>
                        <img src={Logo} alt="" className={`logo ${!isOpen ? 'logo-small' : ''}`} width={isOpen ? logoSize.logoWidth : 0} height={isOpen ? logoSize.logoHeight : 0} />
                    </div>
                    {menuContent}
                </div>
            </div>

            <div className='headerMenuIcon' onClick={handleShow}>
                <HiBars3 size={25} />
            </div>

            <Offcanvas show={show} className='d-block d-md-none' onHide={handleClose} style={{ width: '250px' }}>
                <div style={{ height: '100vh', overflowX: 'scroll' }}>
                    <div className='d-flex justify-content-end pe-2 pt-2'>
                        <div className='text-center p-3'>
                            <img src={Logo} alt="" className={`logo`} width={150} />
                        </div>
                        <IoCloseOutline size={25} onClick={handleClose} />
                    </div>
                    <div>
                        {menuContent}
                    </div>
                </div>
            </Offcanvas>
        </>
    );
}

export default Sidebar;
