import { createSlice } from '@reduxjs/toolkit';

export const exampleSlice = createSlice({
  name: 'todos',
  initialState: [],
  reducers: {
    addTodo: (state, action) => {
      const todo = {
        text: 'dfgfgdfg',
      };

      state.push(todo);
    }
    }
});

// this is for dispatch
export const { addTodo } = exampleSlice.actions;

// this is for configureStore
export default exampleSlice.reducer;