import { useState } from "react";
import { useSelector } from "react-redux";
import { fetchDataNew as DynamicFetchNew, addData } from '../Redux/Slices/Transaction/DynamicSlice.js';

import Swal from "sweetalert2";
const getList = (id) => {

    let list = document?.getElementById('mytable')
    let card = document?.getElementById('myCard')
    list.style.display = 'none';
    card.style.display = 'none';

    if (id == 'list') {
        list.style.display = 'block';
    }
    else {
        card.style.display = 'block';
    }
}

export const AlertPopup = {
    errorPopup: (message) => {
        Swal.fire({
            position: "top-end",
            icon: "error",
            title: message,
            showConfirmButton: false,
            timer: 1500,
            toast: true
        });
    },
    warningPopup: (message) => {
        Swal.fire({
            position: "center",
            icon: "warning",
            text: message,
            showConfirmButton: true,
            // timer: 1500,
            // toast: true
        });
    },
    errorPopupBig: (title, message) => {
        Swal.fire({
            position: "center",
            icon: "error",
            title: title,
            text: message,
            showConfirmButton: true,
            // timer: 1500,
            // toast: true
        });
    },
    SuccessPopup: (message) => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: message,
            showConfirmButton: false,
            timer: 1500,
            toast: true
        });
    }
}


export const ConfirmPopupBig = (title, message) => {
    return Swal.fire({
        title: title,
        text: message,
        icon: "warning",
        // showCloseButton: true,
        // showCancelButton: true,
        focusConfirm: false,
        // showCancelButton: true,
        // confirmButtonColor: "#274c77",
        // cancelButtonColor: "#d33",
        // confirmButtonText: "Yes, delete it!"
    }).then((result) => {
        return result.isConfirmed
    });
}
export const errorPopup = (message) => {
    Swal.fire({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500,
        toast: true
    });
}
export const getCodeFromMaster = (name, master, fieldName, fieldCode) => {
    const foundItem = master?.find(item => {
        const otnameArray = item.OTNAME.split(',');
        return otnameArray.some(otname => otname.trim().toUpperCase() === name.toUpperCase());
    });
    return foundItem ? foundItem[fieldCode] : null;
};
export const getEXTNAMEfromColumnMaster = (fieldName, Master, ExtName) => {
    let foundItem = Master?.filter(x => x.CNAME === fieldName).map(i => i.EXTNAME).join(',');
    console.log('ColumnMaster', foundItem);
    return foundItem;
}
export const confirmPopup = (testingTrue, testingFalse) => {
    return Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#274c77",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
    }).then((result) => {
        return result.isConfirmed
    });
}
export const confirmPopupwithfunction = (testingTrue, testingFalse) => {
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
    }).then((result) => {
        if (result.isConfirmed) {
            if (testingTrue) {
                testingTrue()
            }
        } else {
            if (testingFalse) {
                testingFalse()
            }
        }
    });
}


export const GridActionButton = {
    AddButton: {
        kind: "button-cell",
        backgroundColor: 'transparent',
        color: "accentColor",
        // borderColor: "#6572ffa0",
        borderRadius: 9,
        title: "Add New",
    },
    EditButton: {
        kind: "button-cell",
        backgroundColor: 'transparent',
        color: "accentColor",
        // borderColor: "#6572ffa0",
        borderRadius: 9,
        title: "Edit",
    },
    DeleteButton: {
        kind: "button-cell",
        backgroundColor: 'transparent',
        color: "accentColor",
        // borderColor: "#6572ffa0",
        borderRadius: 9,
        title: "Delete",
    },
    LinkButton: {
        kind: "button-cell",
        backgroundColor: "transparent",  //2nd is for hover Event
        color: "accentColor",
        // borderColor: "#6572ffa0",
        borderRadius: 0,
        title: "Link",
    },
    DemoButton: {
        kind: "button-cell",
        backgroundColor: ["transparent", "#6572ffee"],  //2nd is for hover Event
        color: ["accentColor", "accentFg"],
        borderColor: "#6572ffa0",
        borderRadius: 9,
        contentAlign: "left",
        title: "View Details",

    },
    GetDataButton: {
        kind: "button-cell",
        backgroundColor: ["transparent", "green"],
        color: ["green", "accentFg"],
        borderColor: "green",
        borderRadius: 7,
        contentAlign: "Center",
        title: "GetData",

    }
}

// export const SearchText = (obj, SearchColName, value, RetColumnName) => {
//     if (obj && Array.isArray(obj) && obj.length > 0) {
//         const searchValues = value.toString().split(',').map(item => item.trim().toUpperCase());
//         const matchingIds = [];
//         for (let i = 0; i < obj.length; i++) {
//             if (obj[i][SearchColName]) { 
//                 const columnValues = obj[i][SearchColName].trim().toUpperCase().split(',');
//                 for (const columnValue of columnValues) {
//                     if (searchValues.includes(columnValue.trim())) { 
//                         if (obj[i][RetColumnName]) {
//                             matchingIds.push(obj[i][RetColumnName]);
//                         } else {
//                             return null;
//                         }
//                     }
//                 }
//             }
//         }
//         return matchingIds
//     }
//     return null;
// };

export const SearchText = (obj, SearchColName, value, RetColumnName) => {
    if (obj && Array.isArray(obj) && obj.length > 0) {

        const searchValues = value.toString().split(',')?.map(item => item.trim().toUpperCase());
        const matchingIds = [];
        const matchedKeys = new Set(); // To keep track of matched keys
        for (let i = 0; i < obj.length; i++) {
            if (obj[i][SearchColName]) {
                const columnValues = obj[i][SearchColName].trim().toUpperCase().split(',');
                for (const columnValue of columnValues) {
                    if (searchValues.includes(columnValue.trim())) {
                        const key = columnValue.trim();
                        const valueToAdd = obj[i][RetColumnName] ? obj[i][RetColumnName] : null;
                        matchedKeys.add(key); // Mark the key as matched
                        const existingItemIndex = matchingIds.findIndex(item => item.key === key);
                        if (existingItemIndex !== -1) {
                            matchingIds[existingItemIndex].value = valueToAdd;
                        } else {
                            matchingIds.push({ key, value: valueToAdd });
                        }
                    }
                }
            }
        }
        // Add unmatched keys with null value
        for (const key of searchValues) {
            if (!matchedKeys.has(key)) {
                matchingIds.push({ key, value: null });
            }
        }
        return matchingIds;
    }
    return null;
};

export function FileDownloader(file, filename) {
    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = file;
    anchor.download = filename;

    // Programmatically click the anchor element to trigger the download
    anchor.click();
}

// export function convertKeysToUpperCase(arr) { 
//     if (!Array.isArray(arr)) {
//         throw new Error("Input must be an array of objects");
//     }

//     const convertKeysToUpperCase = (obj) => {
//         if (typeof obj !== 'object' || obj === null) {
//             throw new Error("Input must be a non-null object");
//         }

//         return Object.keys(obj).reduce((acc, key) => {
//             const upperKey = key.toUpperCase();
//             const value = obj[key];
//             acc[upperKey] = (typeof value === 'object' && value !== null && !Array.isArray(value))
//                 ? convertKeysToUpperCase(value) // Recursively convert keys for nested objects
//                 : value;
//             return acc;
//         }, {});
//     };

//     return arr.map(item => convertKeysToUpperCase(item));


// }

export function convertKeysToUpperCase(arr) {

    if (!Array.isArray(arr)) {
        throw new Error("Input must be an array of objects");
    }

    const convertKeys = obj => Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key.toUpperCase()] = (typeof value === 'object' && value !== null && !Array.isArray(value))
            ? convertKeys(value)
            : value;
        return acc;
    }, {});

    return arr?.map(convertKeys);
}

export function ColumnAggregator({ data, columnName, operation }) {
    if (!data || data.length === 0 || !columnName) {
        return <div>0</div>;
    }

    const computeValue = () => {
        if (operation === 'SUM') {
            return data.reduce((acc, item) => acc + (item[columnName] || 0), 0);
        } else if (operation === 'COUNT') {
            return data.length;
        }
        return 0;
    };
    const result = computeValue();
    const formattedResult = result.toFixed(2);

    return (
        <div>
            {formattedResult}
        </div>
    );
}


export const ValidateDuplicateGrid = (OldRecord, newRecord) => {
    // Validate OldRecord and newRecord
    if (!Array.isArray(OldRecord)) {
        console.error("OldRecord is not an array or undefined:", OldRecord);
        OldRecord = []; // Default to an empty array
    }

    if (!Array.isArray(newRecord)) {
        console.error("newRecord is not an array or undefined:", newRecord);
        alert("Invalid data: newRecord is not defined or is not an array.");
        return OldRecord; // Return the original data if newRecord is invalid
    }

    let gridData = JSON.parse(JSON.stringify(OldRecord));
    let alreadyExistPkt = [];
    newRecord.forEach((x) => {
        if (!gridData.find(a => a.PKTID == x.PKTID)) {
            gridData.push(x)
        } else {
            alreadyExistPkt.push(x.PKTID)
        }
    })


    if (alreadyExistPkt.length > 0) {
        alert(`Already exist ${alreadyExistPkt.join(', ')}`)
    }

    return gridData
}

export const fetchAndSetORate = async (row, ColMast, QuaMast, ShpMast) => {
    try {


        const ObjJson = {
            jsonObj1: [{
                S_CODE: ShpMast?.filter(Shp => Shp.S_NAME == row.S_NAME)[0]?.S_CODE,
                C_CODE: ColMast?.filter(col => col.C_NAME.includes(row.C_NAME))[0]?.C_CODE,
                Q_CODE: QuaMast?.filter(qua => qua.Q_NAME == row.Q_NAME)[0]?.Q_CODE,
                CARAT: row.I_CARAT
            }],
            SPName: "API_RapOrateFind"
        };

        const response = await DynamicFetchNew(ObjJson);
        if (response) {
            return response[0].ORate;
        }
    } catch (error) {
        console.error("Error fetching ORate:", error);
    }
};

export const fetchAndSetDisc = async (row, ColMast, QuaMast, CutMast, FLorMast, ShpMast) => {
    try {

        const ObjJson = {
            jsonObj1: [{
                CARAT: row.I_CARAT,
                S_CODE: ShpMast?.filter(Shp => Shp.S_NAME == row.S_NAME)[0]?.S_CODE,
                C_CODE: ColMast?.filter(col => col.C_NAME.includes(row.C_NAME))[0]?.C_CODE || 0,
                Q_CODE: QuaMast?.filter(qua => qua.Q_NAME == row.Q_NAME)[0]?.Q_CODE || 0,
                CT_CODE: CutMast?.filter(qua => qua.CT_NAME == row.CT_NAME)[0]?.CT_CODE || 0,
                FL_CODE: FLorMast?.filter(qua => qua.FL_NAME == row.FL_NAME)[0]?.FL_CODE || 0
            }],
            SPName: "API_RapDiscFind"
        };


        const response = await DynamicFetchNew(ObjJson);
        if (response) {
            return response[0].Disc;
        }
    } catch (error) {
        console.error("Error fetching ORate:", error);
    }
};

export const getChangedKeysFromObjs = (selectedRow, oldData) => {

    try {
        const oldRow = oldData.find(row => row.PKTID === selectedRow[0].PKTID);
        const changesObject = {
            PKTID: selectedRow[0].PKTID
        };

        if (oldRow) {
            Object.keys(selectedRow[0]).forEach(key => {
                if (selectedRow[0][key] !== oldRow[key]) {
                    changesObject[`OLD_${key}`] = oldRow[key];
                    changesObject[key] = selectedRow[0][key];
                }
            });

        }
        return Object.keys(changesObject).length ? changesObject : [];
    } catch (error) {
        console.error("Error fetching Keys:", error);
    }

};



export default getList