import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../../Slices/Utility/loaderSlice';

export const fetchData = () => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.get('/Master/CertDate/GetList');
  if (res?.status === 200) {
    dispatch(getData(await res.data));
  }
  dispatch(stopLoader())
};

export const addData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/Master/CertDate/Add', data);
  dispatch(fetchData());   //farithi Fill karva mate
};

export const updateData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/Master/CertDate/Update', data);
  dispatch(fetchData());   //farithi Fill karva mate
};

export const deleteData = (data) => async (dispatch) => {
//   const res = await HttpMethod.post('/Master/CertDate/Delete?BGM_CODE=' + data);
//   dispatch(fetchData());   //Delete karva mate
}

export const CertDateMastSlice = createSlice({
  name: 'CertDateMast',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };      
    }
  },
});
export const { getData } = CertDateMastSlice.actions;
export default CertDateMastSlice.reducer;