import ApexCharts from 'apexcharts'
import moment from 'moment';
import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = ({ chartData, symbol  }) => {    
    const fieldLabels = chartData.map((item) => item.FIELDLABEL);    
    const fieldData = chartData.map((item) => parseFloat(item.FIELDDATA));    
    const options = {
      chart: {
        width: 380,
        type: 'pie',
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex]
          return [name, val.toFixed(1) + '%']
        },
      },
      labels: fieldLabels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    return (
        <div>
            <div className="shadow p-2 mb-2 bg-body rounded">
            <div id="chart">
                <ReactApexChart
                    options={options}
                    series={fieldData}
                    type="pie"
                    height={300}
                />                 
            </div>
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default PieChart;
