import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';

export const fetchData = (ObjPara) => async (dispatch) => {
  dispatch(startLoader()) 
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/DynSpExDisp', ObjPara); 
  if (res?.status === 200) {
    dispatch(getData(await res.data));
  }
  dispatch(stopLoader())
};

export const addData = (data) => async (dispatch) => {  
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/DynSpEx', data);
  dispatch(fetchData());  
};

export const updateData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/Stock/STKTrnParty/Upload', data);
  dispatch(fetchData());  
};

export const deleteData = (data) => async (dispatch) => {
  const res = await HttpMethod.post('/Stock/STKTrnParty/Delete', data);
  dispatch(fetchData()); 
};

export const GrdEntrySlice = createSlice({
  name: 'PKTMAST',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };
    }
  },
});

export const { getData } = GrdEntrySlice.actions;
export default GrdEntrySlice.reducer;