import React, { useEffect } from 'react'
import './Header.css'
import { FaRegUserCircle } from "react-icons/fa";
import { Link, NavLink } from 'react-router-dom';
import { OverlayTrigger, Popover, Tooltip, Card, Offcanvas } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";
import { TbLogout } from 'react-icons/tb';
import { IoKeyOutline } from "react-icons/io5";
import { useState } from 'react';
import { IoMdCheckboxOutline, IoMdNotifications } from 'react-icons/io';
import { LuTags } from 'react-icons/lu';
import { PiUserBold } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { ViewRequestSlicefetchData } from '../Redux/Slices/Masters/ViewRequestSlice';
import { WishListfetchData } from '../Redux/Slices/Masters/WishListSlice';
import { ConfirmfetchData } from '../Redux/Slices/Masters/ConfirmSlice';
import { HoldfetchData } from '../Redux/Slices/Masters/HoldSlice';
import { logoutUser } from '../Redux/Slices/Utility/LoginSlice';
import { fetchDataNew as DynamicFetchNew, addData as DynamicAdd } from '../Redux/Slices/Transaction/DynamicSlice.js';
import { AlertPopup } from '../Asset/common.js';
import { HttpMethod } from '../Asset/httpMethod.js';
import { BiSolidBadgeDollar } from "react-icons/bi";

const NotificationItem = ({ props, onRemove }) => {
  const [isClicked, setIsClicked] = useState(false);
  const handleRemoveClick = (e) => {
    e.stopPropagation();
    onRemove(props.TRNID);
  };
  return (
    <div className={`notification-popover ${isClicked ? 'hover' : ''}`} onClick={() => setIsClicked(!isClicked)}>
      <Card className={`mx-4 mb-2 ${isClicked ? 'clicked' : ''}bordered-card`} style={{ background: 'rgba(255, 255, 255, 0.13)' }}>
        <Card.Body className='p-0 cardin-body'>
          <div className="position-absolute BgIconDel top-0 end-0">
            <IoMdClose className='IconDel' size={20} onClick={handleRemoveClick} style={{ cursor: 'pointer', color: 'Black' }} />
          </div>
          <div className="row m-1 d-flex justify-content-center align-item-center">
            <div className="col-12 ps-2 p-2 align-items-center">
              <Card.Title className="mb-0 notificait-cardTital" style={{ fontSize: '0.9rem' }}>                
              <div className="row">
                        <div className="col-1 notification-Icon">
                          {props.Nameicon}
                        </div>
                        <div className="col align-content-center mx-2">{props.TRN_REMARK}</div>
                    </div>
                
                </Card.Title>
              <Card.Text className="mb-0" style={{ fontSize: '0.8rem' }}>
                <div className="d-flex justify-content-between mt-1">
                  <div className='ms-1'> 
                    
                    <p className='notification-desc'>{props.MsgDesc}</p>
                    <p className='notificaitonDate'>{props.MsgDateTime}</p>
                  </div>
                  
                  {/* <div className='me-5'>Carat : {props.TCRT} </div> */}
                </div>
              </Card.Text>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

function Header({ MainPageName }) {
  const dispatch = useDispatch()
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  let Confirm = useSelector(state => state.ConfirmList)
  let HoldList = useSelector(state => state.HoldList)

  let Setting = useSelector(state => state.Setting.Setting);
  let logoutUrl = Setting?.find(Setting => Setting.SKEY == 'COMP_NAME' && Setting.KEYDESC)?.KEYDESC;
  let LiveRate = Setting?.find(Setting => Setting.SKEY == 'Live_ConvRate')?.SVALUE || 0;
  let userLoginDetail = JSON.parse(localStorage.getItem('loginUserDetail'))
  let UserName = userLoginDetail.UserName;

  const logout = () => {
    if (logoutUrl) {
      dispatch(logoutUser(logoutUrl))
    } else {
      dispatch(logoutUser())
    }
  }

  const [PageName, setPageName] = useState('')
  const [showCanvas, setShowCanvas] = useState(false);


  const handleRemove = (newObj) => {
    const ObjJson = {
      jsonObj1: [{ MSGTYPE: "TRNLOGDATA", ID: newObj.TRNID, USERID: newObj.USERID }],
      SPName: "API_PopUpMSGSave"
    };

    dispatch(DynamicAdd(ObjJson));
    AlertPopup.SuccessPopup("Remove SucessFully");
    getAllData();
  };

  const toggleCanvas = () => setShowCanvas(!showCanvas);

  const popover = (
    <Popover id="popover-contained" className='popover header-popover' >
      <Popover.Body>
        <NavLink to='/EmpProfile' onClick={''}><FaRegUserCircle size={20} />&nbsp;&nbsp; My Account</NavLink>
        <Link onClick={logout}><TbLogout size={20} />&nbsp;&nbsp; Logout</Link>
        <NavLink to='/ChangePassword' onClick={''}><IoKeyOutline size={20} />&nbsp;&nbsp; Change Password</NavLink>
      </Popover.Body>
    </Popover>
  );


  const SetName = (name) => {
    setPageName(name);
  }
  const [Detail, setDetail] = useState([])
  const getAllData = async () => {
    const jsonObj = {
      jsonObj1: [{ USERID: 1 }],
      SPName: 'API_PopUpMSGDisp',
    };
    let DetDT = await DynamicFetchNew(jsonObj)
    setDetail(DetDT)

    // dispatch(ViewRequestSlicefetchData())
    // dispatch(WishListfetchData())
    // dispatch(ConfirmfetchData())
    // dispatch(HoldfetchData())
  }

  useEffect(() => {
    getAllData();
    const intervalId = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [])

  function getCurrentDateTime() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();

    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? String(hours).padStart(2, '0') : '12';

    return `${day}-${month}-${year} ( ${hours}:${minutes} ${ampm} )`;
  }
  const HeaderTimeMsg = () => {
    var myDate = new Date();
    var hrs = myDate.getHours();
    var greet;
    if (hrs < 12)
      greet = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
      greet = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
      greet = 'Good Evening';
    return greet
  };

  
  const [formattedRate, setFormattedRate] = useState("0");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFormattedRate(Number(LiveRate).toFixed(4))
  }, [LiveRate])


  const saveRateInSetting = async () => {
    const response = await fetch("https://latest.currency-api.pages.dev/v1/currencies/usd.json");
    const data = await response.json();
    const rate = data.usd?.inr;
    setFormattedRate(rate.toFixed(4));

    let ObjJSON = {
      jsonObj1: [{ SKEY: "Live_ConvRate", SVALUE: rate.toFixed(4) }],
      SPName: "API_SettingUpdate"
    }
    dispatch(DynamicAdd(ObjJSON))
  }

  return (
    <>
      <div className='BgHeader row d-flex justify-content-between align-items-center m-0'>
        <div className='col-3 d-flex justify-content-between align-items-center'>
          <h5 className='text-black fw-semibold MainPage m-0 ps-4 ms-2 px-md-2'>{MainPageName} </h5>
        </div>
        <div className='col-9 d-flex justify-content-end align-items-center'>
          <div className='text-black fw-semibold px-2 d-xxl-flex d-none w-100'>
            <marquee style={{ width: '100%', fontWeight: 400, fontSize: '15px', overflow: 'hidden' }}>
              <span>&nbsp;{HeaderTimeMsg()} &nbsp;| &nbsp;{currentDateTime} {UserName} </span>
            </marquee>
          </div>
          <div className='md-pe-2 ps-2 d-flex align-items-center'>
              {
                <button type='button' className='btnLiveRate text-nowrap mx-2' onClick={saveRateInSetting} disabled={isLoading}>
                  <BiSolidBadgeDollar size={23} className='' />
                  <span className='ms-1'>{formattedRate}</span>
                </button>
              }
              <button
                type="button"
                className="border-0 me-1 position-relative btnHeaderIcon"
                onClick={() => SetName('Confirm')}
              >
                <NavLink to='/confirm' className='BadgeLink'> <IoMdCheckboxOutline size={18} color='var(---IconColor)' /> </NavLink>
                <span className="mybadge">
                  {Confirm.StockConfirmList1 && (Confirm.StockConfirmList1[0]?.I_PCS || 0)}
                </span>
              </button>
              <button
                type="button"
                className="border-0 me-1 position-relative btnHeaderIcon"
                onClick={() => SetName('Hold')}
              >
                <NavLink to='/hold' className='BadgeLink'> <LuTags size={18} color='var(---IconColor)' /> </NavLink>
                <span className="mybadge">
                  {HoldList.StockHoldList1 && (HoldList.StockHoldList1[0]?.I_PCS || 0)}
                </span>
              </button>

              <button type="button" className="border-0 me-1 position-relative btnHeaderIcon" onClick={toggleCanvas}>
                <NavLink className='BadgeLink'> <IoMdNotifications size={18} color='var(---IconColor)' /> </NavLink>
                <span className="mybadge">4</span>
              </button>
          </div>
          <div className='border-Left ps-3'>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
              <button variant="" className='btnHeaderIcon me-2 border-0' >
                <div className='py-2 MainAdmin'>
                  {/* <span className='small me-lg-2 me-md-2 me-0'>Admin</span> */}
                  <PiUserBold size={30} color='var(---IconColor)' className='Admin-icon me-2' />
                </div>
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <Offcanvas show={showCanvas} onHide={toggleCanvas} placement='end' className="noti-offcanvas">
        <Offcanvas.Header closeButton>Notification</Offcanvas.Header>
        <Offcanvas.Body className="popover-bodyNoti my-4" style={{ overflowY: 'auto' }}>
          <div className='row'>
            {Detail?.map((item, index) =>
              <NotificationItem key={index} props={item} onRemove={() => handleRemove(item)} />              
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default Header