import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../../Slices/Utility/loaderSlice';
import { fetchData as DropDownSlice } from '../Utility/DropDownSlice';
import { AlertPopup, convertKeysToUpperCase, errorPopup } from '../../../Asset/common';

export const TableName = "AccMast"

// const constructDataJson = (data) => {
//   let newData = {};
//   for (let key in data) {
//     newData[key.toLowerCase()] = data[key];
//   }
  
//   let dataJson = {};
//   dataJson["jsonObj"] = [];
//   dataJson["TableName"] = TableName;
//   dataJson["jsonObj"].push(newData);
//   return dataJson;
// };

const constructDataJson = (data) => {
  let dataJson = {};
  dataJson["jsonObj"] = [];
  dataJson["TableName"] = TableName;
  if(Array.isArray(data)){
    for(let x of data){
      let newData = {};
      for (let key in x) {
        newData[key.toLowerCase()] = x[key];
      }
      dataJson["jsonObj"].push(newData);
    }
  }else{
    let newData = {};
    for (let key in data) {
      newData[key.toLowerCase()] = data[key];
    }
    dataJson["jsonObj"].push(newData);
  }
  return dataJson;
};

export const fetchData = (isMessage) => async (dispatch) => {
  dispatch(startLoader())
  let ObjJsonDet = {
    jsonObj1: [{}],
    SPName: "API_AccMastGet"
  }; 
  let res = await HttpMethod.post(`/SystemConfig/InsUpdDel/DynSpExDisp`, ObjJsonDet)
  if (res?.status === 200) {
    if(!(res.data.DynDisp) && isMessage){
      errorPopup(res.data)
      dispatch(getData({ DynDisp : []}));
    }
    else{
      dispatch(getData(await res.data));
    }
  }
  dispatch(stopLoader())
};

export const addData = (data) => async (dispatch) => {
  const upperCaseObj = convertKeysToUpperCase(data);
  const ObjJson = {
      jsonObj1: upperCaseObj,
      SPName: "API_AccMastImportSave"
  };
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/DynSpEx', ObjJson);
  if (res?.status === 200) {
    dispatch(fetchData())
    dispatch(DropDownSlice())
    AlertPopup.SuccessPopup(res.data);
  };
  dispatch(stopLoader());
};

// export const addData = (data) => async (dispatch) => { 
//   dispatch(startLoader())
//   const dataJson = constructDataJson(data);
//   const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Add', dataJson);
//   if (res?.status === 200) {
//     dispatch(fetchData())
//     dispatch(DropDownSlice())
//     AlertPopup.SuccessPopup(res.data);
//   };
//   dispatch(stopLoader());
// };

// export const updateData = (data) => async (dispatch) => {
//   dispatch(startLoader())
//   const dataJson = constructDataJson(data);
//   const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Update', dataJson);
//   if (res?.status === 200) {
//     dispatch(fetchData())
//     dispatch(DropDownSlice())
//     AlertPopup.SuccessPopup(res.data);
//   }
//   dispatch(stopLoader())
// };

export const deleteData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Delete', dataJson);
  if (res?.status === 200) {
    dispatch(fetchData())
    dispatch(DropDownSlice())
    AlertPopup.SuccessPopup(res.data);
  }
  dispatch(stopLoader())
}


export const AccountMastSlice = createSlice({
  name: 'AccountMast',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };      
    }
  },
});
export const { getData } = AccountMastSlice.actions;
export default AccountMastSlice.reducer;