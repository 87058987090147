import React, { useEffect } from 'react'
import { fetchData as AccMast } from '../../Redux/Slices/Masters/AccMastSlice';
import { fetchData as ShapMast } from '../../Redux/Slices/Masters/ShapMastSlice';
import { fetchData as ShadeMast } from '../../Redux/Slices/Masters/ShadeMastSlice';
import { fetchData as BGMMast } from '../../Redux/Slices/Masters/BGMMastSlice';
import { fetchData as CutMast } from '../../Redux/Slices/Masters/CutMastSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData as BrownMast } from '../../Redux/Slices/Masters/BrownMastSlice';
import { fetchData as ClarityMast } from '../../Redux/Slices/Masters/ClarityMastSlice';
import { fetchData as ServiceMast } from '../../Redux/Slices/Masters/ServicMastSilce';
import { fetchData as FloColMast } from '../../Redux/Slices/Masters/FloColMastSlice';
import { fetchData as LabMast } from '../../Redux/Slices/Masters/LabMastSlice';
import { selectLogin } from '../../Redux/Slices/Utility/LoginSlice';
import { fetchData as SizeMast } from '../../Redux/Slices/Masters/SizeMastSlice';
import { fetchData as LocationMast } from '../../Redux/Slices/Masters/LocMastSlice';
import { fetchData as FlorMast } from '../../Redux/Slices/Masters/FlorMastSlice';
import { fetchData as ColorMast } from '../../Redux/Slices/Masters/ColMastSlice';
import { HoldfetchData } from '../../Redux/Slices/Masters/HoldSlice';
import { ConfirmfetchData } from '../../Redux/Slices/Masters/ConfirmSlice';
import { WishListfetchData } from '../../Redux/Slices/Masters/WishListSlice';
import { ViewRequestSlicefetchData } from '../../Redux/Slices/Masters/ViewRequestSlice';
import { fetchMenuPermition } from '../../Redux/Slices/Utility/MenuPermition';
import { fetchData as FileFildValidation } from '../../Redux/Slices/Transaction/FileFildValidation';
import { fetchData as ColumnMastSlice } from '../../Redux/Slices/Masters/ColumnMastSlice';
import { fetchData as MenuMast } from '../../Redux/Slices/Masters/MenuMastSlice';
import { fetchData as ColumnConfigSlice } from '../../Redux/Slices/Utility/ColumnConfigSlice'
import { fetchData as RolePermissionSlice } from '../../Redux/Slices/Utility/RolePermissionSlice'
import { fetchData as GirdleMastSlice } from '../../Redux/Slices/Masters/GirdleMastSlice';
import { fetchData as MilkyMastSlice } from '../../Redux/Slices/Masters/MilkyMastSlice';
import { fetchData as NattsMastSlice } from '../../Redux/Slices/Masters/NattsMastSlice';
import { fetchData as CuletConMastSilce } from '../../Redux/Slices/Masters/CuletConMastSilce';
import { fetchData as CuletSizeMastSlice } from '../../Redux/Slices/Masters/CuletSizeMastSlice';
import { fetchData as TermMastSlice } from '../../Redux/Slices/Masters/TermMastSlice';
import { fetchData as CountryMastSlice } from '../../Redux/Slices/Masters/CountryMastSlice';
import { fetchData as LocMastSlice } from '../../Redux/Slices/Masters/LocMastSlice';
import { fetchData as GreenMastSlice } from '../../Redux/Slices/Masters/GreenMastSlice';
import { fetchData as BrownMastSlice } from '../../Redux/Slices/Masters/BrownMastSlice';
import { fetchData as EyeCleanMastSlice } from '../../Redux/Slices/Masters/EyeCleanMastSlice';
import { fetchData as HeartArrowMastSlice } from '../../Redux/Slices/Masters/HeartArrowMastSlice';
import { fetchData as SettingSlice } from '../../Redux/Slices/Masters/SettingSlice';
import { fetchData as BarSettingSlice } from '../../Redux/Slices/Utility/BarSettingSlice';
import { fetchData as DropDownSlice } from '../../Redux/Slices/Utility/DropDownSlice';
import { fetchData as MixColMastSlice } from '../../Redux/Slices/MixMasters/MixColMastSlice';
import { fetchData as MixQuaMastSlice } from '../../Redux/Slices/MixMasters/MixQuaMastSlice';
import { fetchData as MixCutMastSlice } from '../../Redux/Slices/MixMasters/MixCutMastSlice';
import { fetchData as MixIdentityMastSlice } from '../../Redux/Slices/MixMasters/MixIdentityMastSlice';
import { fetchData as MixCharniMastSlice } from '../../Redux/Slices/MixMasters/MixCharMastSlice';
import { fetchData as BlackIncMastSlice } from '../../Redux/Slices/Masters/BlackIncMastSlice';
import { fetchData as WhiteIncMastSlice } from '../../Redux/Slices/Masters/WhiteIncMastSlice';
import { fetchData as OpenIncMastSlice } from '../../Redux/Slices/Masters/OpenIncMastSlice';
import { fetchData as ApiSettingSlice } from '../../Redux/Slices/Masters/ApiSettingSlice';

function LoadAllMast() {

  // let ShpMast = useSelector(state => state.ShpMast)    
  const login = useSelector(selectLogin)
  const dispatch = useDispatch()
  useEffect(() => {
    if (login) {
      dispatch(fetchMenuPermition())
      dispatch(AccMast())
      dispatch(ShapMast())
      dispatch(ShadeMast())
      dispatch(BGMMast())
      dispatch(MenuMast())
      dispatch(BrownMast())
      dispatch(CutMast())
      dispatch(ColorMast())
      dispatch(ClarityMast())
      dispatch(ServiceMast())
      dispatch(FloColMast())
      dispatch(FlorMast())
      dispatch(SizeMast())
      dispatch(LabMast())
      dispatch(LocationMast())
      // dispatch(HoldfetchData())
      // dispatch(ConfirmfetchData())
      // dispatch(WishListfetchData())
      // dispatch(ViewRequestSlicefetchData())
      dispatch(FileFildValidation())
      dispatch(ColumnMastSlice())
      dispatch(ColumnConfigSlice())
      dispatch(RolePermissionSlice())
      dispatch(GirdleMastSlice())
      dispatch(MilkyMastSlice())
      dispatch(NattsMastSlice())
      dispatch(CuletConMastSilce())
      dispatch(CuletSizeMastSlice())
      dispatch(TermMastSlice())
      dispatch(CountryMastSlice())
      dispatch(LocMastSlice())
      dispatch(GreenMastSlice())
      dispatch(BlackIncMastSlice())
      dispatch(WhiteIncMastSlice())
      dispatch(OpenIncMastSlice())
      dispatch(BrownMastSlice())
      dispatch(EyeCleanMastSlice())
      dispatch(HeartArrowMastSlice())
      dispatch(SettingSlice())
      dispatch(BarSettingSlice())
      dispatch(DropDownSlice())
      dispatch(MixColMastSlice())
      dispatch(MixCutMastSlice())
      dispatch(MixQuaMastSlice())
      dispatch(MixIdentityMastSlice())
      dispatch(MixCharniMastSlice())
      dispatch(ApiSettingSlice(true))

    }

  }, [login])

  return <></>
}

export default LoadAllMast