import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod, sendError_notify } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';
import { AlertPopup } from "../../../Asset/common";

export const HoldfetchData = () => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.get('/Stock/StockSearch/HoldList');
  if (res?.status === 200) {
    dispatch(getData(await res.data));
  }
  dispatch(stopLoader())
};

export const HoldaddData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.post('/Stock/StockSearch/AddToHold', data);
  if (res?.status === 200) {
    dispatch(HoldfetchData())
    AlertPopup.SuccessPopup(res.data);
  };
  dispatch(stopLoader())

}


export const HoldDelData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.post('/Stock/StockSearch/RemoveHold', data);
  if (res?.status === 200) {
    dispatch(HoldfetchData())
    AlertPopup.SuccessPopup(res.data);
  }
  dispatch(stopLoader())
};

export const HoldSlice = createSlice({
  name: 'HoldList',
  initialState: {
    "StockHoldList": [],
    "StockHoldList1": []
  },
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };
    }
  },
});
export const { getData } = HoldSlice.actions;
export default HoldSlice.reducer;