import ApexCharts from 'apexcharts'
import moment from 'moment';
import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const VerticalChart = ({ chartData, symbol  }) => {
    let allItems = chartData.map(x => x?.FIELDTYP?.split(',') || []);
    let chartItems = [...new Set(allItems.flatMap(subArray => subArray))]; 
    const series = chartItems?.map((item,i) => {
        return {
            name: item,
            data: chartData?.map(x => x?.FIELDDATA?.split(',')[i] || 0) || []
          }
    }) || [];

    
    const options = {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: 'end', // 'around', 'end'
            borderRadiusWhenStacked: 'last', // 'all', 'last'
            dataLabels: {
              total: {
                enabled: true,
                formatter: function (val) {
                  return val + symbol; 
                },
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
          categories: chartData.map(x => x.FIELDLABEL),
        },
        yaxis: {
          labels: {
            formatter: function (value) {              
              return value + symbol;
            },
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          offsetX: 0,
          offsetY: 0
        },
        fill: {
          opacity: 1
        },
        colors: ['#00E396', '#008FFB', '#FF9F00']
      };

    return (
      
       <div className="shadow p-2 mb-1 bg-body rounded">
            <div id="chart">
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={300}
                />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default VerticalChart;
