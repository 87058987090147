import { createSlice } from '@reduxjs/toolkit';
import { GetDropDownList, HttpMethod } from '../../../Asset/httpMethod';
import { setLoading } from '../../Slices/Utility/loaderSlice';

export const fetchMenuPermition = () => async (dispatch) => {
 // Dispatch startLoader() if needed
  // dispatch(startLoader())
  
  
  let userLoginDetail = JSON.parse(localStorage.getItem('loginUserDetail'))
  const res = await HttpMethod.get('/SystemConfig/Menu/GetPermittedMenu?UserName='+userLoginDetail.UserName);
  if (res?.status === 200) {
    dispatch(getData(await res.data));
  }
  // Simulate a delay of 5 seconds using setTimeout
  
    // // Call your asynchronous function after the delay
    // const res = await GetDropDownList(ObjParam);  
    
    // // Dispatch the action with the data received
    // dispatch(getData(res));    
    
    // Dispatch stopLoader() if needed
    // dispatch(stopLoader())
  


};

export const FormateRouting = (data) => {
    const updatedMenuConfig = JSON.parse(JSON.stringify(data.filter(x => x.IsShow == true)));
    let renderMenuConfig = [];
        if(updatedMenuConfig){
            renderMenuConfig = updatedMenuConfig.filter(x => x.MenuLevel == 1)
            renderMenuConfig.forEach(element => {
                element.children = updatedMenuConfig.filter(b => b.ParentMenuCode == element.MenuCode)
                if(element.children && element.children.length > 0){
                    element.children.forEach((subElement) => {
                        subElement.children = updatedMenuConfig.filter(c => c.ParentMenuCode == subElement.MenuCode)
                    })
                }              
            });
            
        }

    return renderMenuConfig;
}
export const UserMenuPermission = createSlice({
  name: 'MenuPermition',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return action.payload ;
    }
  },
});
export const { getData } = UserMenuPermission.actions;
export default UserMenuPermission.reducer;