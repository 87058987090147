import React, { memo, useEffect, useMemo, useState } from 'react'
import VerticalChart from '../../Components/Chart/VerticalChart';
import { Dialog } from 'primereact/dialog';
import PieChart from '../../Components/Chart/PieChart';

function ChartPopup(props) {
    let { visible, setVisible, objDs } = props;
    const [cardViewHeight, setcardViewHeight] = useState(0);
    const [chartFor, setchartFor] = useState('I_CARAT')
    const [charttyp, setchartTyp] = useState('bar')


    useEffect(() => {
        window.addEventListener('resize', (e) => {
            changeContentHeight()
        })
        changeContentHeight()
    }, []);

    const changeContentHeight = () => {
        let StoneStatusHeight = document.querySelector('.StoneStatus')?.clientHeight;
        let totalDivHeight = document.querySelector('.totalDiv')?.clientHeight;
        let btndivHeight = document.querySelector('.btndiv')?.clientHeight;
        setcardViewHeight(window.innerHeight - 40 - (StoneStatusHeight + totalDivHeight + btndivHeight + 55))
    };

    const getChartData = (fieldName, ordBy) => {
        let allData = Object.values(
            objDs?.reduce((acc, item) => {
                const fieldLabel = item[fieldName];
                if (!acc[fieldLabel]) {
                    acc[fieldLabel] = {
                        FIELDLABEL: fieldLabel || "",
                        FIELDDATA: 0,
                        FIELDTYP: "number",
                        TYP: chartFor,
                        ORD: item[ordBy]
                    };
                }
                acc[fieldLabel].FIELDDATA += Number(item[chartFor]);
                return acc;
            }, {})
        ).sort((a, b) => a.ORD - b.ORD);
        let sortedData = allData;
        if (chartFor == 'I_CARAT') {
            const total = allData.reduce((sum, x) => sum + x.FIELDDATA, 0);
            sortedData = allData.map(x => ({
                ...x,
                FIELDDATA: total > 0 ? ((x.FIELDDATA / total) * 100).toFixed(2) : 0
            }))
        } else {
            sortedData = allData.map(x => ({
                ...x,
                FIELDDATA: String(x.FIELDDATA)
            }))
        }
        return sortedData;
        
    }

    const chartContent = useMemo(() => {
        
        const changeChartForType = (type) => {
            setchartFor()
            setTimeout(() => {
                setchartFor(type)
            }, 0);
        }


        const changeChartType = (type) => {                                 
            setchartTyp()
            setTimeout(() => {
                setchartTyp(type)
            }, 0);
        }
        return <>
            <div className="row shadow p-2 mb-2 bg-body rounded">
                <div className="col-lg-6">
                    <button type="button" class="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 mb-2" onClick={() => changeChartForType('I_CARAT')}>Carat</button>
                    <button type="button" class="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 mb-2" onClick={() => changeChartForType('I_PCS')}>Pcs</button>    
                </div>
                <div className='col-lg-6'>
                    <button type="button" class="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 mb-2" onClick={() => changeChartType('bar')}>Bar Chart</button>
                    <button type="button" class="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 mb-2" onClick={() => changeChartType('pie')}>Pie Chart</button>
                </div>
            </div>
            
            <div className="row px-2">
                <div className="col-12 col-lg-4">
                    
                    
                    {
                        
                        chartFor && (
                            charttyp === 'bar' ? (
                                <VerticalChart chartData={getChartData('Q_NAME', 'Q_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            ) : (
                                <PieChart chartData={getChartData('Q_NAME', 'Q_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            )
                        )
                    }
                </div>
                <div className="col-12 col-lg-4">
                    {
                    chartFor && (
                        charttyp === 'bar' ? (
                            <VerticalChart chartData={getChartData('CT_NAME', 'CT_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                        ) : (
                            <PieChart chartData={getChartData('CT_NAME', 'CT_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                        )
                    )
                    }
                </div>
                <div className="col-12 col-lg-4">
                    {
                    chartFor && (
                        charttyp === 'bar' ? (
                            <VerticalChart chartData={getChartData('PO_NAME', 'PO_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                        ) : (
                            <PieChart chartData={getChartData('PO_NAME', 'PO_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                        )
                    )
                    }
                </div>
                <div className="col-12 col-lg-4">
                    {
                        chartFor && (
                            charttyp === 'bar' ? (
                                <VerticalChart chartData={getChartData('S_NAME', 'ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            ) : (
                                <PieChart chartData={getChartData('S_NAME', 'ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            )
                        )    
                    }
                </div>
                <div className="col-12 col-lg-4">
                    {
                        chartFor && (
                            charttyp === 'bar' ? (
                                <VerticalChart chartData={getChartData('SY_NAME', 'SY_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            ) : (
                                <PieChart chartData={getChartData('SY_NAME', 'SY_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            )
                        )    
                    }
                </div>
                <div className="col-12 col-lg-4">
                    {
                    chartFor && (
                        charttyp === 'bar' ? (
                            <VerticalChart chartData={getChartData('C_NAME', 'C_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                        ) : (
                            <PieChart chartData={getChartData('C_NAME', 'C_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                        )
                    )                         
                    }

                </div>
            </div>
        </>
    }, [objDs, chartFor,charttyp])

    return (
        <>

            <Dialog visible={visible} header={'Chart View'} modal={true} style={{ width: '85vw', height: "100vh", backgroundColor: "red" }} onHide={() => setVisible(false)} position=''>
                <div className='p-2'>
                    <div style={{ height: cardViewHeight }}> {chartContent} </div>
                </div>
            </Dialog>
        </>
    )
}

export default memo(ChartPopup)