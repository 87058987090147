import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../../Slices/Utility/loaderSlice';
import { AlertPopup, errorPopup } from '../../../Asset/common';

export const TableName = "MixCharMast"

export const fetchData = (isMessage) => async (dispatch) => {
  dispatch(startLoader())
  let ObjJsonDet = {
    jsonObj1: [{}],
    SPName: "API_MixCharMastGet"
  }; 
  let res = await HttpMethod.post(`/SystemConfig/InsUpdDel/DynSpExDisp`, ObjJsonDet)
  if (res?.status === 200) {
    if(!(res.data.DynDisp) && isMessage){
      errorPopup(res.data)
      dispatch(getData({ DynDisp : []}));
    }
    else{
      dispatch(getData(await res.data));
    }

  }
  dispatch(stopLoader())
};

const constructDataJson = (data) => {


  let newData = {};
  for (let key in data) {
    newData[key.toLowerCase()] = data[key];
  }

  let dataJson = {};
  dataJson["jsonObj"] = [];
  dataJson["TableName"] = TableName;
  dataJson["jsonObj"].push(newData);
  return dataJson;
};

export const addData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Add', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
};


export const updateData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Update', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
};

export const deleteData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Delete', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
}

export const MixCharMastSlice = createSlice({
  name: 'MixCharMast',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return action.payload ;
    }
  },
});
export const { getData } = MixCharMastSlice.actions;
export default MixCharMastSlice.reducer;