import React, { useEffect, useState } from 'react';
import * as fa6 from 'react-icons/fa6';
import * as io5 from 'react-icons/io5';
import * as md from 'react-icons/md';
import * as fa from 'react-icons/fa'; 
import * as hi2 from 'react-icons/hi2';
import * as ri from 'react-icons/ri';
import * as im from 'react-icons/im';


function IconComponent(props) {
  const { MenuIconPath, MenuIcon, size ,color,className } = props;
  const [iconComponent, setIconComponent] = useState(null);
  
  useEffect(() => {
    const iconSets = {
      fa6: fa6,
      io5: io5,
      md: md,
      fa: fa,
      hi2:hi2,
      ri:ri
      // Add more icon sets as needed
    };

    const loadIcon = async () => {
      try {
        
        if (iconSets[MenuIconPath]) {
          const selectedIconSet = iconSets[MenuIconPath];
          // Check if MenuIcon exists in the selected icon set
          if (selectedIconSet[MenuIcon]) {
            const Icon = selectedIconSet[MenuIcon];
            setIconComponent(<Icon size={size} color={color} className={className}/>); // Pass the size prop to the icon component
          } else {
            // console.error('Invalid MenuIcon:', MenuIcon);
          }
        } else {
          // console.error('Invalid MenuIconPath:', MenuIconPath);
        }
      } catch (error) {
        console.error('Error loading icon component:', error);
      }
    };

    loadIcon();
  }, [MenuIcon, MenuIconPath, size,color,className]); // Include 'size' in the dependency array

  return iconComponent ? iconComponent : null;
}

export default IconComponent;
