// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';   
import '../src/Asset/common.css';
import RoutePathDynamic from './Components/RoutesDynamic';
import LoadAllMast from './Pages/Utility/LoadAllMast';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, selectLogin } from './Redux/Slices/Utility/LoginSlice';


function App() {  
  const login = useSelector(selectLogin)
  const dispatch = useDispatch()

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      dispatch(logoutUser())
      return ''
    } else {
      // return <span>{hours}:{minutes}:{seconds}</span>;
      return <></>;
    }
  };


  return (
    <>    
      {
        login && <Countdown
          date={new Date(login?.Expires)}
          renderer={renderer}
        />
      }
      <LoadAllMast />
      <RoutePathDynamic/>
      <div id="portal" style={{ position: "fixed", left: "0", top: "0", zIndex: "9999" }} />
    </>
  );
}

export default App;