import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from './loaderSlice';
import { AlertPopup } from '../../../Asset/common';

export const TableName = "RoleGroupPermission"

export const fetchData = () => async (dispatch) => {  
  dispatch(startLoader())  
    const res = await HttpMethod.post('/SystemConfig/RolePermission/GetList');  
    if (res?.status === 200) {
      dispatch(getData(await res.data));      
    }
    dispatch(stopLoader())  
};

const constructDataJson = (data) => {
  let newData = {};
  for (let key in data) {
      newData[key.toLowerCase()] = data[key];
  }  
    let dataJson = {};
    dataJson["jsonObj"] = [];
    dataJson["TableName"] = TableName;
    dataJson["jsonObj"].push(newData);
    return dataJson;
};

export const addData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Add', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
};


export const updateData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data); 
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Update', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
};

export const deleteData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data); 
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Delete', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
}


export const RolePermissionSlice = createSlice({
  name: 'RolePermission',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };
      // state.initialState=action.payload;
      // state.loading=false;
    }
  },
});
export const { getData } = RolePermissionSlice.actions;
export default RolePermissionSlice.reducer;