import { useState, useEffect } from 'react';

const useAutoAdjustHeight = (props1, props2) => {
    const [newGridHeight, setNewGridHeight] = useState(0);

    useEffect(() => {
        function updateDataDivHeight() {

            const windowHeight = window.innerHeight;
            const header = document.querySelector('.BgHeader');
            const gridTopContent = document.querySelector('.gridTopHeight');
            const gridTopContent1 = document.querySelector('.gridTopHeight1');
            const gridTopContent2 = document.querySelector('.gridTopHeight2');
            const gridBottomtHeight = document.querySelector('.gridBottomtHeight');
            if (header) {
                const gridTopContentHeight = gridTopContent ? gridTopContent.offsetHeight : 0;
                const gridTopContentHeight1 = gridTopContent1 ? gridTopContent1.offsetHeight : 0;
                const gridTopContentHeight2 = gridTopContent2 ? gridTopContent2.offsetHeight : 0;
                const BottomtHeight = gridBottomtHeight ? gridBottomtHeight.offsetHeight : 0;
                const headerHeight = header ? header.offsetHeight : 0;
                const newHeight = windowHeight - headerHeight - gridTopContentHeight - gridTopContentHeight1 - gridTopContentHeight2 - BottomtHeight - 35 ;
                setNewGridHeight(newHeight);
            }
        }

        updateDataDivHeight();
        window.addEventListener('resize', updateDataDivHeight);

        return () => window.removeEventListener('resize', updateDataDivHeight);

    }, [props1, props2]);
    return newGridHeight;
};

export default useAutoAdjustHeight;
