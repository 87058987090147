 
import { GetColumnconfig } from '../../Asset/httpMethod';
import generateExcel from '../../Components/Grid/ExcelGenerator';
import { AlertPopup } from '../../Asset/common';

export const ExcelGenerate = async (MenuCode, data) => {
  try {
    let ColumnConfig = await GetColumnconfig(MenuCode);
    if (ColumnConfig && ColumnConfig.length > 0) {
      const filteredColumns = ColumnConfig
      ?.filter(column => !column.IsExcelHide)
      ?.sort((a, b) => a.ExcelOrd - b.ExcelOrd);

      await generateExcel(filteredColumns, data, 'example.xlsx');
    } else {
      AlertPopup.errorPopup('No column configuration available.');
    }
  } catch (err) {
    AlertPopup.errorPopup(err.message || 'An error occurred while generating the Excel file.');
  }
}