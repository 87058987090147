import ErrorBoundary from './Components/ErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
// import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.css'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './Redux/Stores/Store';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

const root = ReactDOM.createRoot(document?.getElementById('root'));
root.render(

  // Hello
  // <React.StrictMode>
        <ErrorBoundary>
      <Provider store={store}>
      <App />
      </Provider>
     </ErrorBoundary>     
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
