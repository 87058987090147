import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod, sendError_notify } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';
import { AlertPopup } from "../../../Asset/common";

export const ViewRequestSlicefetchData = () => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.get('/Stock/StockSearch/ConfirmList');
  if (res?.status === 200) {
    dispatch(getData(await res.data));
  }
  dispatch(stopLoader())
};

export const ViewRequestaddData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.post('/Stock/StockSearch/AddToConfirm', data);
  if (res?.status === 200) {
    dispatch(ViewRequestSlicefetchData())
    AlertPopup.SuccessPopup(res.data);
  };
  dispatch(stopLoader())
}

export const ViewRequestDelData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.post('/Stock/StockSearch/RemoveConfirm', data);
  if (res?.status === 200) {
    dispatch(ViewRequestSlicefetchData())
    AlertPopup.SuccessPopup(res.data);
  }
  dispatch(stopLoader())
};

export const ViewRequestSlice = createSlice({
  name: 'ViewRequestSlice',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };
    }
  },
});
export const { getData } = ViewRequestSlice.actions;
export default ViewRequestSlice.reducer;