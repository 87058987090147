import React from 'react';
import './ErrorBoundary.css'
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state to trigger fallback UI
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error to an external service or console
        console.error('Error caught in ErrorBoundary:', error, errorInfo);
    }

    handleReset = () => {
        // Reset the state to allow re-rendering of the component
        this.setState({ hasError: false, error: null });
    };

    handleGoHome = () => {                
        this.setState({ hasError: false }); // Reset the error state if needed
      };
    render() {
        if (this.state.hasError) {
            return (
                <>
                    <div class="ErrorMainbody">
                        <div class="ErrorPageMain">                            
                                <h1 className='ErrorH1'> Oops! Something went wrong. </h1>
                                <h2 className='ErrorDesc'> Error: {this.state.error?.message || 'Unknown error'} </h2>
                                <button className='ErrorRefreshbutton' onClick={this.handleReset}> Refresh </button>
                                {/* <button className='ErrorRefreshbutton mx-3' onClick={this.handleGoHome}> Goto Home </button>                                 */}
                        </div>
                    </div>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
