import React, { useState, useEffect } from 'react';
import './ColumnConfig.css'
import GlidGrid from '../../Components/Grid/GlidGrid';
import { GetColumnconfig } from '../../Asset/httpMethod';
import { useDispatch, useSelector } from 'react-redux';
import { addData, deleteData, fetchData, updateData } from '../../Redux/Slices/Utility/ColumnConfigSlice';
import { confirmPopup, GridActionButton } from '../../Asset/common';
import Loader from './Loader';
import { Accordion } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa6'; 
import useAutoAdjustHeight from '../../Components/useAutoAdjustHeight/useAutoAdjustHeight.js';

function ColumnConfigComp(props) {

  let { MenuCode } = props;
  const [GetViewPara, setGetViewPara] = useState([])
  const [ColumnConfigData, setColumnConfigData] = useState([])
  const dispatch = useDispatch()
  let ColumnConfigData_Alldata = useSelector(state => state.ColumnConfig.all);
  let [AllData, setAllData] = useState(ColumnConfigData_Alldata)
  const getAllData = () => {
    dispatch(fetchData())
    Columnconfig()
  }  
  useEffect(() => {
    getAllData()
  }, []);
  
  const gridHeight = useAutoAdjustHeight();

  useEffect(() => {
    AllData = ColumnConfigData_Alldata;
    if (MenuCode === undefined) {
      setAllData(ColumnConfigData_Alldata)
    }
    else {
      setAllData(AllData?.filter(x => x.MenuCode == MenuCode))
    }
  }, [ColumnConfigData_Alldata])


  const Columnconfig = async () => {
    let ColumnConfig = await ColumnConfigData_Alldata?.filter(x => x.MenuCode == "COLUMNCONFIG")

    if (MenuCode === undefined) {
      setColumnConfigData(ColumnConfigData_Alldata)
    }
    else {
      let ColumnConfigData = await GetColumnconfig(MenuCode)
      setAllData(AllData?.filter(x => x.MenuCode == MenuCode))
      setColumnConfigData(ColumnConfigData)
    }

    setGetViewPara(ColumnConfig)
  }

  const editdata = (type, dataRow) => {
    if (type === "edit") {
      dispatch(updateData(dataRow))
      dispatch(fetchData())
    }
    else {
      dispatch(addData(dataRow))
      dispatch(fetchData())
    }
  }

  const Delete = (dataRow) => {
    let res = confirmPopup()
    if (res === true) {
      dispatch(deleteData(dataRow))
      dispatch(fetchData())
    }
  }

  const getActionButton = (dataRow, columnKey) => {
    if (columnKey.toLowerCase() === "edit") {
      return {
        ...GridActionButton.EditButton,
        onClick: () => editdata(columnKey.toLowerCase(), dataRow)
      };
    }

    else if (columnKey.toLowerCase() === "delete") {
      return {
        ...GridActionButton.DeleteButton,
        onClick: () => Delete(dataRow)
      };
    }
    else if (columnKey.toLowerCase() === "addnew") {
      return {
        ...GridActionButton.AddButton,
        onClick: () => editdata(columnKey.toLowerCase(), dataRow)
      };
    }
    else {
      return undefined;
    }
  };

  const loadData = () => {
    getAllData()
  }

  return (
    <>
      <Loader />
      <div className='gridTopHeight'>
      <div className='accoridonContent'>
        <Accordion style={{ width: '100%' }}>
          <Accordion.Item className='p-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='col-12 text-end'>
                <button className='btnbluedark' onClick={() => loadData()}>
                  <FaCheck size={17} /> Show
                </button>
              </div>
            </div>
          </Accordion.Item>
        </Accordion>
      </div>
      </div>

      <div className='col-12 col-md-12 p-1 border border-1 rounded'>
        {
          AllData?.length > 0 && GetViewPara.length > 0 &&
          <GlidGrid
            data={AllData}
            columns={GetViewPara}
            // height={"650px"}
            height={`${gridHeight}px`}
            ActionButton={getActionButton}
            AddNewRecord={true}
            ViewparaName={"COLUMNCONFIG"}
          />
        }
      </div>
    </>
  );
}
export default ColumnConfigComp;
