import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../../Slices/Utility/loaderSlice';
import { AlertPopup } from '../../../Asset/common';

export const TableName = "LotMast"

export const fetchData = () => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.get('/Master/Lot/GetList');
  if (res?.status === 200) {
    dispatch(getData(await res.data));
  }
  dispatch(stopLoader())
};

const constructDataJson = (data) => {
  let newData = {};
  for (let key in data) {
    newData[key.toLowerCase()] = data[key];
  }

  let dataJson = {};
  dataJson["jsonObj"] = [];
  dataJson["TableName"] = TableName;
  dataJson["jsonObj"].push(newData);
  return dataJson;
};

export const addData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Add', dataJson);
  if (res?.status === 200) {
    dispatch(fetchData())
    AlertPopup.SuccessPopup(res.data);
  };
  dispatch(stopLoader());
};

export const updateData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Update', dataJson);
  if (res?.status === 200) {
    dispatch(fetchData())
    AlertPopup.SuccessPopup(res.data);
  }
  dispatch(stopLoader())
};

export const deleteData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Delete', dataJson);
  if (res?.status === 200) {
    dispatch(fetchData())
    AlertPopup.SuccessPopup(res.data);
  }
  dispatch(stopLoader())
}

export const LotMastSlice = createSlice({
  name: 'LotMast',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };      
    }
  },
});
export const { getData } = LotMastSlice.actions;
export default LotMastSlice.reducer;