import React from 'react'

function StoneSearchComponent(props) {
    let { objMast, code, name, obj, getValue, objkey } = props;
    return (
        <>
            {
                objMast?.map((x, i) => {
                    return <div key={i} className="m-1">
                        <label className={`ColorDivBtn me-2 me-md-2 me-sm-1 m-0 me-lg-2 CursorPoinrer 1  ${obj[objkey]?.includes('' + x[code] + '') ? 'selectedItems' : ''}`}>
                            <div className='text-center'>
                                <input type="checkbox" className='d-none' name={objkey} value={x[code]} checked={obj[objkey]?.includes('' + x[code] + '') == true} onChange={getValue} />
                                <p className='m-0'>{x[name]}</p>
                            </div>
                        </label>
                    </div>
                })

            }
        </>
    )
}

export default StoneSearchComponent