import React, { memo, useCallback, useMemo, useState } from 'react'
import './DataListComp.css'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WishListDelData, WishListaddData } from '../../Redux/Slices/Masters/WishListSlice';
import { HoldDelData, HoldaddData } from '../../Redux/Slices/Masters/HoldSlice';
import { ConfirmListDelData, ConfirmListaddData } from '../../Redux/Slices/Masters/ConfirmSlice';
import { ViewRequestDelData, ViewRequestaddData } from '../../Redux/Slices/Masters/ViewRequestSlice';
import { Dropdown } from 'primereact/dropdown';
import GlidGrid from '../../Components/Grid/GlidGrid';
import { AlertPopup, GridActionButton } from '../../Asset/common';
import Form from 'react-bootstrap/Form'
import generateExcel from '../../Components/Grid/ExcelGenerator';
import { fetchDataNew as DynamicFetchNew, addData } from '../../Redux/Slices/Transaction/DynamicSlice';
import PacketSearchComp from '../Transaction/PacketSearchComp'
import { useNavigate } from 'react-router-dom';
import PktErrorComp from '../../Components/PktErrorComp';
import { IoGrid } from 'react-icons/io5';
import { FaLink, FaRectangleList } from 'react-icons/fa6';
import { FlagIcon } from 'react-flag-kit';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RiFilterFill, RiWhatsappFill } from 'react-icons/ri';
import { IoMdDownload, IoMdRefresh, IoMdRefreshCircle } from 'react-icons/io';
import { Modal } from 'react-bootstrap';
import useAutoAdjustHeight from '../../Components/useAutoAdjustHeight/useAutoAdjustHeight';
import { fetchWebStockPartyData } from '../../Redux/Slices/Masters/PacketSearchSlice';
import { HiChartSquareBar } from "react-icons/hi";
import VerticalChart from '../../Components/Chart/VerticalChart';

function DataListComp(props) {

    let Setting = useSelector(state => state.Setting.Setting);
    let APISetting = useSelector(state => state.APISetting);
    let MemberName = Setting?.find(Setting => Setting.SKEY == 'WebWhatsApp1' && Setting.KEYDESC)?.KEYDESC;
    let MobileNo = Setting?.find(Setting => Setting.SKEY == 'WebWhatsApp1' && Setting.KEYDESC)?.SVALUE;
    const splitValue = MemberName?.split(',');
    const splitNumberValue = MobileNo?.split(',');
    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalVideoLink, setisModalVideoLink] = useState(false);
    const [VideoLinkRow, setVideoLinkRow] = useState([]);

    const { btn } = props;
    const btnValues = btn.split(',');
    const [objTotal, setobjTotal] = useState(props.totalobj[0])
    //let objDs = props.DataSrc;
    const PageName = props.PageName;
    const objViewPara = props.ViewPara;
    // const IsShowTotal = props.ShowTotal;
    const StnStatus = props.StnStatus;

    let [objDs, setobjDs] = useState(JSON.parse(JSON.stringify(props.DataSrc) || '[]'));

    const dataDivRef = useRef(null);

    const dispatch = useDispatch();

    const [ObjSelTotal, setObjSelTotal] = useState({})

    let [Selectedrow, setSelectedrow] = useState([]);
    const [Party, setParty] = useState(null);
    const [MfgParty, setMfgParty] = useState(null);
    const [PagingRange, setPagingRange] = useState(null);
    const [showTotal, setShowTotal] = useState(true);
    const [openPacketSearchCom, setopenPacketSearchCom] = useState(false)

    const [GetViewPara, setGetViewPara] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [PktErrData, setPktErrData] = useState([]);
    const [pagebtnNaviName, setpagebtnNaviName] = useState('');
    let [ViewType, setViewType] = useState('GridView');

    const columnConfigRecord = useSelector(state => state.ColumnConfig.visible);
    const DropDown = useSelector(state => state.DropDown);
    const pageName = PageName === 'STONESEARCH' ? 'STONESEARCH' : 'PACKETSEARCH';

    let CompCode = Setting?.find(Setting => Setting.SKEY === 'COMP_CODE')?.SVALUE;
    let BarSetting = useSelector(state => state.BarSetting.DynDisp)?.filter(item => item.COMP_CODE == CompCode && item.TYP == 'PKTSEARCH');
    let CertBarSetting = useSelector(state => state.BarSetting.DynDisp)?.filter(item => item.COMP_CODE == CompCode && item.TYP == 'UPCOMING');

    let navigate = useNavigate();
    const videoRef = useRef(null);

    let [visibleItems, setVisibleItems] = useState([]);
    const [itemsPerPage] = useState(12);
    const [errorStatus, setErrorStatus] = useState({});
    const [cardViewHeight, setcardViewHeight] = useState(0);

    const handleMouseOver = (e) => {
        if (e.target.readyState >= 3) {
            e.target.play().catch(error => {
                console.log('Playback failed:', error);
            });
        }
    };
    const handleMouseOut = (e) => {
        e.target.pause();
    };

    const [PageRange, setPageRange] = useState([]);
    useEffect(() => {
        const fetchPageRangeData = () => {
            let GetPageRangeDropDown = DropDown.LOOKUPMAST?.filter(x => x.TYP == 'SearchPageRecerd');
            setPageRange(GetPageRangeDropDown);
        }

        fetchPageRangeData();
    }, [DropDown])

    useEffect(() => {
        if (PageRange?.length > 0) {
            setPagingRange(PageRange[0]); // Set the first item as the default
        }
    }, [PageRange]);

    useEffect(() => {
        let filterStnStatus = props.DataSrc;
        if (StnStatus && StnStatus.length > 0) {
            filterStnStatus = props.DataSrc?.filter(item => StnStatus.includes(item.STNSTATUS));
        } else {
            filterStnStatus = props.DataSrc;
        }
        setobjDs(JSON.parse(JSON.stringify(filterStnStatus) || '[]'));
    }, [StnStatus, props.DataSrc]);

    useEffect(() => {
        let TtlDS = props.DataSrc;

        let objTotal = {
            I_PCS: 0,
            I_CARAT: 0.00,
            ORATE: 0,
            DISC: 0.00,
            RATE: 0,
            AMT: 0,
            OAMT: 0
        }

        TtlDS?.forEach(row => {
            objTotal['I_PCS'] += row['I_PCS'];
            objTotal['I_CARAT'] += row['I_CARAT'];
            objTotal['AMT'] += row['RATE'] * row['I_CARAT'];
            objTotal['OAMT'] += row['ORATE'] * row['I_CARAT'];
        });

        objTotal['ORATE'] = objTotal['OAMT'] / objTotal['I_CARAT'];
        objTotal['RATE'] = objTotal['AMT'] / objTotal['I_CARAT'];
        objTotal['DISC'] = 100 - ((objTotal['RATE'] / objTotal['ORATE']) * 100);

        setobjTotal(objTotal);
    }, [])

    useEffect(() => {
        setobjTotal(props.totalobj[0])
    }, [props.totalobj])

    useEffect(() => {
        getAllData();
    }, [props.DataSrc]);

    const GetselectedRow = (selectedRows, lastEditedRow) => {



        // if (lastEditedRow) {
        //     if (lastEditedRow && lastEditedRow.SELECTED) {
        //         Selectedrow[lastEditedRow.index] = lastEditedRow;
        //     } else {
        //         Selectedrow = Selectedrow?.filter(row => row.PKTID != lastEditedRow.PKTID)
        //     }
        // } else {
        //     Selectedrow = selectedRows;
        // }

        setSelectedrow(selectedRows);
        let selectedPktIds = selectedRows.map(x => x.PKTID)

        objDs.forEach((x, i) => {
            if (selectedPktIds.includes(x.PKTID)) {
                objDs[i].SELECTED = true;
            } else {
                objDs[i].SELECTED = false;
            }
        })

        setobjDs(objDs)
        const ObjSelTotal = {
            'I_PCS': 0,
            'I_CARAT': 0,
            'AMT': 0,
            'OAMT': 0,
            'ORATE': 0,
            'RATE': 0,
            'DISC': 0,
        };
        selectedRows?.forEach(row => {
            if (row.SELECTED == true) {
                ObjSelTotal['I_PCS'] += row['I_PCS'];
                ObjSelTotal['I_CARAT'] += row['I_CARAT'];
                ObjSelTotal['AMT'] += row['RATE'] * row['I_CARAT'];
                ObjSelTotal['OAMT'] += row['ORATE'] * row['I_CARAT'];
            }
        });

        ObjSelTotal['ORATE'] = ObjSelTotal['OAMT'] / ObjSelTotal['I_CARAT'];
        ObjSelTotal['RATE'] = ObjSelTotal['AMT'] / ObjSelTotal['I_CARAT'];
        ObjSelTotal['DISC'] = 100 - ((ObjSelTotal['RATE'] / ObjSelTotal['ORATE']) * 100);

        setObjSelTotal(ObjSelTotal);
    }

    useEffect(() => {
        Columnconfig();
    }, [columnConfigRecord])

    const Columnconfig = async () => {
        setGetViewPara(columnConfigRecord?.filter(x => x.MenuCode == 'STONESEARCH'));
    }

    const ExcelGen = async (selectedRows, ConfigName, fileName) => {
        // Filter columns based on IsExcelHide
        const filteredColumns = columnConfigRecord?.filter(column => column.MenuCode == ConfigName && column.IsExcelHide === false);
        // Sort columns based on ExcelOrd
        filteredColumns.sort((a, b) => a.ExcelOrd - b.ExcelOrd);
        // Generate Excel file with sorted columns and selected rows
        // selectedRows.push()
        generateExcel(filteredColumns, selectedRows, fileName)
            .catch(err => AlertPopup.errorPopup(err));
    }

    const handleSave = async (btnName, showModal) => {

        setpagebtnNaviName(btnName)

        // let pktidArray = Array.isArray(Selectedrow) && Selectedrow?.map(obj => obj.SELECTED === true && obj.PKTID);
        let pktidArray = Array.isArray(Selectedrow) && Selectedrow?.filter(obj => obj.SELECTED === true && obj.PKTID)?.map(obj => obj.PKTID);

        const isSelected = Array.isArray(Selectedrow) && Selectedrow?.some(item => item.Selected);
 
        if ((Selectedrow.length == undefined || Selectedrow.length == 0 || pktidArray.length == 0 || pktidArray == '' || pktidArray == undefined) && (btnName != 'Select All' && btnName != 'Filter' && btnName != 'Refresh' && btnName != 'WhatsApp')) {
            AlertPopup.warningPopup("Please Select atleast one Record !!");
            return
        }
        if ((btnName == 'Excel' && (Party?.Code == null || Party?.Code == undefined))) {
            AlertPopup.warningPopup("Please Select Party !!");
            return
        }
        if (userRole.toLowerCase() != 'web user' && (Party == undefined || Party == null)) {
            if (btnName == 'Wishlist' || btnName == 'Hold' || btnName == 'Confirm' || btnName == 'View Request') {
                AlertPopup.warningPopup("Please Select Party !!");
                return
            }
        }

        const holdOrConfirmExists = Array.isArray(Selectedrow) && Selectedrow?.some(x => x.IsHold || x.IsConfirm);

        if (holdOrConfirmExists && btnName.includes('Remove') == false && btnName != 'Excel' && btnName != 'Selected') {
            AlertPopup.warningPopup("This Packet Is Already Hold or Confirm For Other Party !!");
            return;
        }

        let newObj = { 'PKTID': pktidArray, 'AC_Code': Party == null ? 0 : Party?.Code };

        if (btnName == 'Refresh') {
            localStorage.removeItem('PktId');
            JSON.parse(localStorage.getItem('isRefresh'))
            if (props.refreshData) {
                props.refreshData()
            }
        }
        if (btnName == 'Wishlist') {
            dispatch(WishListaddData(newObj))
        }
        if (btnName == 'Remove Wishlist') {
            dispatch(WishListDelData(newObj))
        }
        if (btnName == 'Hold') {
            dispatch(HoldaddData(newObj))
            if (props.refreshData) {
                props.refreshData()
            }
        }
        if (btnName == 'Remove Hold') {
            dispatch(HoldDelData(newObj))
        }
        if (btnName == 'Confirm') {
            dispatch(ConfirmListaddData(newObj))
            if (props.refreshData) {
                props.refreshData()
            }
        }
        if (btnName == 'Remove Confirm') {
            dispatch(ConfirmListDelData(newObj))
        }
        if (btnName == 'View Request') {
            dispatch(ViewRequestaddData(newObj))
        }
        if (btnName == 'Remove View Request') {
            dispatch(ViewRequestDelData(newObj))
        }
        if (btnName == 'Excel') {
            if (btnName == 'Excel' && Party?.Code) {
                let selectedRowPktIds = Selectedrow.map(x => x.PKTID)
                let payLoad = { AC_CODE: Party?.Code }
                if (selectedRowPktIds.length > 0) {
                    // payLoad.PKTID = selectedRowPktIds
                }
                let response = await dispatch(fetchWebStockPartyData(payLoad))
                let columnConfigName = APISetting?.find(x => x.AC_CODE == Party?.Code)?.COLUMNCONFIG;
                ExcelGen(response, columnConfigName, Party.Name);
            } else {
                ExcelGen(Selectedrow, 'PACKETSEARCH', 'Stock');
            }
        }
        if (btnName == 'Show All') {
            objDs = props.DataSrc;
            setobjDs(props.DataSrc);
            const updatedObjDs = objDs?.map((item) => {
                const isSelected = Selectedrow?.some((row) => row.PKTID == item.PKTID && row.SELECTED);
                return {
                    ...item,
                    SELECTED: isSelected,
                };
            });

            setobjDs(updatedObjDs);
            props.setButtonName(PageName === 'STONESEARCH' ? 'Select All,Show All,Selected,Excel,Confirm,Hold'
                : 'Filter,Select All,Selected,Lab Issue,Jangad Issue,Estimate Issue,MixOut Entry,NonCert Issue,Sale Issue,Price/Disc Upd,Data Upd Entry,Excel,Barcode,IsActive,DeleteAll');
        }
        if (btnName == 'Selected') {
            
            localStorage.removeItem('pktId');
            props.setButtonName(PageName === 'STONESEARCH' ? 'Select All,Show All,Selected,Excel,Confirm,Hold'
                : 'Filter,Refresh,Select All,Selected,Lab Issue,Jangad Issue,Estimate Issue,MixOut Entry,NonCert Issue,Sale Issue,Price/Disc Upd,Data Upd Entry,Excel,Barcode,IsActive,DeleteAll');
            setobjDs([...Selectedrow])
        }

        if (btnName == 'Select All') {

            // const updatedData = objDs?.map(item => ({
            //     ...item,
            //     SELECTED: !item.SELECTED,
            // }));

            const allSelected = objDs.every(item => item.SELECTED); // Check if all are selected
            const updatedData = objDs.map(item => ({
                ...item,
                SELECTED: !allSelected, // Toggle all based on current state
            }));



            setobjDs(updatedData);
            setSelectedrow(updatedData);

            const ObjSelTotal = {
                'I_PCS': 0,
                'I_CARAT': 0,
                'AMT': 0,
                'OAMT': 0,
                'ORATE': 0,
                'RATE': 0,
                'DISC': 0,
            };
            updatedData?.forEach(row => {

                if (row.SELECTED == true) {
                    ObjSelTotal['I_PCS'] += row['I_PCS'];
                    ObjSelTotal['I_CARAT'] += row['I_CARAT'];
                    ObjSelTotal['AMT'] += row['RATE'] * row['I_CARAT'];
                    ObjSelTotal['OAMT'] += row['ORATE'] * row['I_CARAT'];
                }
            });

            ObjSelTotal['ORATE'] = ObjSelTotal['OAMT'] / ObjSelTotal['I_CARAT'];
            ObjSelTotal['RATE'] = ObjSelTotal['AMT'] / ObjSelTotal['I_CARAT'];
            ObjSelTotal['DISC'] = 100 - ((ObjSelTotal['RATE'] / ObjSelTotal['ORATE']) * 100);

            setObjSelTotal(ObjSelTotal);

        }
        if (btnName == 'Lab Issue') {
            localStorage.removeItem('pktId');
            const updatedData = pktidArray?.map(item => ({
                PKTID: item,
                VALIDATIONTYPE: 'VALIDATION_LAB'
            }));

            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_PacketValidation"
            };

            let DetStock = await DynamicFetchNew(ObjJsonDet);
            setPktErrData(DetStock);

            if (DetStock?.length > 0) {
                setShowModal(true);
            }
            else {
                const NewPktId = pktidArray.join(',');
                localStorage.setItem('pktId', NewPktId);
                navigate('/LabIssue');
            }
        }
        if (btnName == 'Jangad Issue') {
            localStorage.removeItem('pktId');
            const updatedData = pktidArray?.map(item => ({
                PKTID: item,
                VALIDATIONTYPE: 'VALIDATION_JANGAD'
            }));

            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_PacketValidation"
            }

            let DetStock = await DynamicFetchNew(ObjJsonDet);
            setPktErrData(DetStock);

            if (DetStock?.length > 0) {
                setShowModal(true);
            }
            else {
                const NewPktId = pktidArray.join(',');
                localStorage.setItem('pktId', NewPktId);
                navigate('/JangadIssue');
            }
        }
        if (btnName == 'Estimate Issue') {
            localStorage.removeItem('pktId');
            const updatedData = pktidArray?.map(item => ({
                PKTID: item,
                VALIDATIONTYPE: 'Validation_ESTIMATE'
            }));

            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_PacketValidation"
            }

            let DetStock = await DynamicFetchNew(ObjJsonDet);
            setPktErrData(DetStock);

            if (DetStock?.length > 0) {
                setShowModal(true);
            }
            else {
                const NewPktId = pktidArray.join(',');
                localStorage.setItem('pktId', NewPktId);
                navigate('/EstimateIss');
            }
        }
        if (btnName == 'Sale Issue') {
            localStorage.removeItem('pktId');
            const updatedData = pktidArray?.map(item => ({
                PKTID: item,
                VALIDATIONTYPE: 'VALIDATION_SAL'
            }));

            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_PacketValidation"
            }

            let DetStock = await DynamicFetchNew(ObjJsonDet);
            setPktErrData(DetStock);

            if (DetStock?.length > 0) {
                setShowModal(true);
            }
            else {
                const NewPktId = pktidArray.join(',');
                localStorage.setItem('pktId', NewPktId);
                navigate('/SaleEntry');
            }
        }
        if (btnName === 'Price/Disc Upd') {
            localStorage.removeItem('pktId');
            const updData = pktidArray?.map(item => ({
                PKTID: item,
                VALIDATIONTYPE: 'VALIDATION _PRICE'
            }));

            let ObjJsonDet = {
                jsonObj1: updData,
                SPName: "API_PacketValidation"
            }

            let DetStock = await DynamicFetchNew(ObjJsonDet);
            setPktErrData(DetStock);

            if (DetStock?.length > 0) {
                setShowModal(true);
            }
            else {
                const NewPktID = pktidArray.join(',');
                localStorage.setItem('pktId', NewPktID);
                navigate('/PriceDiscUpd');
            }
        }
        if (btnName === 'Data Upd Entry') {
            localStorage.removeItem('pktId');
            const updData = pktidArray?.map(item => ({
                PKTID: item,
                VALIDATIONTYPE: 'VALIDATION _DATAUPD'
            }));

            let ObjJsonDet = {
                jsonObj1: updData,
                SPName: "API_PacketValidation"
            }

            let DetStock = await DynamicFetchNew(ObjJsonDet);
            setPktErrData(DetStock);

            if (DetStock?.length > 0) {
                setShowModal(true);
            }
            else {
                const NewPktID = pktidArray.join(',');
                localStorage.setItem('pktId', NewPktID);
                navigate('/DataUpdEntry');
            }
        }
        if (btnName === 'Filter') {
            setopenPacketSearchCom(true)
        }
        if (btnName == 'Barcode') {
            const updatedData = pktidArray?.map(item => ({
                PKTID: item
            }));
            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_Barcode"
            }
            let DetStock = await DynamicFetchNew(ObjJsonDet);
            if (BarSetting === undefined) {
                AlertPopup.warningPopup("Barsetting Data Not Found");
            }
            else {
                let barCodeStr = '';
                DetStock.forEach(x => {
                    let BarCode = BarSetting[0]?.CODE;
                    const regex = /#(.*?)#/g;
                    BarCode?.match(regex)?.forEach(code => {
                        code = code.replace(/#/g, '')
                        BarCode = BarCode.replace(`#${code}#`, `${x[code]}`);
                    })
                    barCodeStr += BarCode + '\n';
                });
                const blob = new Blob([barCodeStr], { type: 'text/plain' });
                const filename = 'barcodes.txt';

                const downloadUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = filename;

                link.click();

                URL.revokeObjectURL(downloadUrl);
            }
        }
        if (btnName == 'CertBarCode') {
            const updatedData = pktidArray?.map(item => ({
                PKTID: item
            }));
            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_Barcode"
            }
            let DetStock = await DynamicFetchNew(ObjJsonDet);
            if (CertBarSetting === undefined) {
                AlertPopup.warningPopup("Certi Barsetting Data Not Found");
            }
            else {
                let barCodeStr = '';
                DetStock.forEach(x => {
                    let BarCode = CertBarSetting[0]?.CODE;
                    const regex = /#(.*?)#/g;
                    BarCode?.match(regex)?.forEach(code => {
                        code = code.replace(/#/g, '')
                        BarCode = BarCode.replace(`#${code}#`, `${x[code]}`);
                    })
                    barCodeStr += BarCode + '\n';
                });
                const blob = new Blob([barCodeStr], { type: 'text/plain' });
                const filename = 'Certibarcode.txt';

                const downloadUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = filename;

                link.click();

                URL.revokeObjectURL(downloadUrl);
            }
        }
        if (btnName == 'DeleteAll') {
            const updatedData = pktidArray?.map(item => ({
                PKTID: item
            }));
            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_PktIdAllDetailDelete"
            }

            // dispatch(addData(ObjJsonDet));

            dispatch(addData(ObjJsonDet)).then(() => {
                if (props.refreshData) {
                    props.refreshData();
                } else {
                    window.location.reload();
                }
            });
        }
        if (btnName == 'IsActive') {
            const updatedData = pktidArray?.map(item => ({
                PKTID: item
            }));
            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_IsActivePktMastUpd"
            }
            dispatch(addData(ObjJsonDet, ''));
        }
        if (btnName == 'NonCert Issue') {
            localStorage.removeItem('pktId');
            const updatedData = pktidArray?.map(item => ({
                PKTID: item,
                VALIDATIONTYPE: 'Validation_NONCERT'
            }));

            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_PacketValidation"
            };

            let DetStock = await DynamicFetchNew(ObjJsonDet);
            setPktErrData(DetStock);

            if (DetStock?.length > 0) {
                setShowModal(true);
            }
            else {
                const NewPktId = pktidArray.join(',');
                localStorage.setItem('pktId', NewPktId);
                navigate('/NonCertIssue');
            }
        }
        if (btnName == 'MixOut Entry') {
            localStorage.removeItem('pktId');
            const updatedData = pktidArray?.map(item => ({
                PKTID: item,
                VALIDATIONTYPE: 'Validation_MIXOUT'
            }));

            let ObjJsonDet = {
                jsonObj1: updatedData,
                SPName: "API_PacketValidation"
            }

            let DetStock = await DynamicFetchNew(ObjJsonDet);
            setPktErrData(DetStock);

            if (DetStock?.length > 0) {
                setShowModal(true);
            }
            else {
                const NewPktId = pktidArray.join(',');
                localStorage.setItem('pktId', NewPktId);
                navigate('/MixOutEntry');
            }
        }
    }

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCloseModalVideoLink = () => {
        setisModalVideoLink(false);
    };

    const handleExcludePackets = () => {
        let pktidArray = Array.isArray(Selectedrow) && Selectedrow?.map(obj => obj.SELECTED === true && obj.PKTID);
        const errorPktIds = PktErrData?.map(row => row.PKTID);
        const remainingPktIds = pktidArray?.filter(pktId => !errorPktIds.includes(pktId)).join(',');

        localStorage.setItem('pktId', remainingPktIds);

        if (pagebtnNaviName == "Lab Issue")
            navigate('/LabIssue');
        else if (pagebtnNaviName == "Jangad Issue")
            navigate('/JangadIssue');
        else if (pagebtnNaviName == "Sale Issue")
            navigate('/SaleEntry');
        else if (pagebtnNaviName == "Price/Disc Upd")
            navigate('/PriceDiscUpd');
        else if (pagebtnNaviName == "Data Upd Entry")
            navigate('/DataUpdEntry');
    };

    const getAllData = () => {
        if (props.DataSrc) {
            setobjDs(JSON.parse(JSON.stringify(props.DataSrc || '[]')));
        }
    }

    const [options, setOptions] = useState([]);
    const [MfgParoptions, setMfgParOptions] = useState([]);
    const [userRole, setuserRole] = useState('')

    useEffect(() => {

        const fetchData = async () => {
            let GetDropDown = DropDown.SPARMAST?.filter(x => x.Ac_Type == 'SPAR');
            setOptions(GetDropDown);

            let GetDropDownMfgPar = DropDown.SPARMAST?.filter(x => x.Ac_Type == 'STKPARTY');
            setMfgParOptions(GetDropDownMfgPar);

            if (DropDown.USERS) {
                let UserRole = DropDown.USERS.find(x => x.UserName == JSON.parse(localStorage.getItem('loginUserDetail'))?.UserName);
                if (UserRole) {
                    setuserRole(DropDown.ROLES.find(x => x.ValueKey == UserRole.RoleId)?.DisplayName)
                    setParty({ Code: UserRole.AC_CODE })
                }
            }
        };
        if (DropDown) {
            fetchData();
        }

    }, [DropDown]);

    ///For Cell Color 
    function getCellTheme(dataRow, columnKey) {
        let colorProperty = Object.keys(dataRow)?.filter(x => x?.endsWith('_COLOR'))
        for (let x of colorProperty) {
            if (x?.split('_COLOR')?.length > 0) {
                if (dataRow[x] && columnKey == x?.split('_COLOR')[0]) {
                    return {
                        bgCell: dataRow[x].toLowerCase()
                    }
                }
            }
        }
    }

    const handleSwitchChange = () => {
        setShowTotal(!showTotal);
    };

    const getActionButton = (dataRow, columnKey) => {
        if (columnKey.toLowerCase() === "certno") {
            return {
                ...GridActionButton.LinkButton,
                title: dataRow.REPORTLINK,
                onClick: () => {
                    window.open(dataRow.REPORTLINK, "_blank");
                },
            };
        }
        else {
            return undefined;
        }
    };

    const selectedOptionTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option?.Name}</div>
                </div>
            );
        }

        return <span>{props?.placeholder}</span>;
    };

    const OptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option?.Name}</div>
            </div>
        );
    };

    const displayType = (vType) => {
        setViewType(vType)
        changeContentHeight()
    }

    function PreloadImages(imageUrls) {
        const promises = imageUrls.map((url, index) =>
            new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = () => {
                    resolve();
                };
                img.onerror = reject;
            })
        );
        Promise.all(promises).catch(() => {
        });
    }

    useEffect(() => {
        if (objDs && ViewType == 'CardView') {
            PreloadImages(objDs.slice(1, 100).map(x => x.PHOTOROTED))
        }
    }, [objDs, ViewType])

    useEffect(() => {
        if (objDs) {
            const newItems = objDs.slice(0, 20);
            visibleItems = [...newItems];
            setVisibleItems([...visibleItems]);
        }
    }, [ViewType, objDs])

    const handleVideoError = (index) => {
        setErrorStatus((prevStatus) => ({ ...prevStatus, [index]: true }));
    };

    const fetchMoreData = () => {
        const newIndex = visibleItems.length + itemsPerPage;
        const newItems = objDs.slice(visibleItems.length, newIndex);
        visibleItems = [...visibleItems, ...newItems];
        setVisibleItems([...visibleItems]);
    };

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            changeContentHeight()
        })
        changeContentHeight()
    }, []);

    const changeContentHeight = () => {
        let StoneStatusHeight = document.querySelector('.StoneStatus')?.clientHeight;
        let totalDivHeight = document.querySelector('.totalDiv')?.clientHeight;
        let btndivHeight = document.querySelector('.btndiv')?.clientHeight;
        setcardViewHeight(window.innerHeight - 40 - (StoneStatusHeight + totalDivHeight + btndivHeight + 55))
    };

    const selectCard = (row, checked) => {
        GetselectedRow(Selectedrow, { ...row, SELECTED: checked })
    };

    const handleOpenWhatsApp = (phoneNumber) => {
        if (!phoneNumber) {
            console.error("Invalid phone number");
            return;
        }

        let PktIDList = Array.isArray(Selectedrow) && Selectedrow?.filter(obj => obj.SELECTED === true && obj.PKTID)?.map(obj => obj.PKTID);
        // const message = PktIDList?.length > 1 ? PktIDList.join(',') : PktIDList; /////Coma Saperated Male
        const message = PktIDList?.length > 1 ? PktIDList.join('\n') : PktIDList; /////blow PKTID Male
        let whatsappURL = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(message)}&app_absent=0`;
        // let whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURI(message)}&app_absent=0`;

        window.open(whatsappURL);
    }

    const videoContent = useMemo(() => {
        return <>
            {
                ViewType == 'CardView' &&
                <>
                    {
                        visibleItems?.map((x, i) => {
                            return <div className='col-6 col-lg-3 col-xxl-2' key={i}>
                                <div className="card border-0">
                                    <div className='d-flex justify-content-end me-2 mt-1'>
                                        <input className="form-check-input textBoxCss me-1" type="checkbox" value="" id="flexCheckChecked" checked={Selectedrow.map(a => a.PKTID).includes(x.PKTID)} onChange={(e) => selectCard(x, e.target.checked)} /> {x.PKTID}
                                    </div>
                                    <div className="card-body p-1">
                                        <div className="video-container" style={{ height: '260px', backgroundColor: '#C2C1C8' }}>
                                            <div className='d-flex BackClr'>
                                                <div className="DiamondTyp">{x.DIAMONDTYPE}</div>
                                                <div className="Lab">{x.LAB}</div>
                                            </div>
                                            {!errorStatus[i] ? (
                                                <>
                                                    <video ref={videoRef} poster={x.PHOTOROTED} style={{ minHeight: '100%' }} width="100%" height="100%" preload="metadata" muted onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} onError={() => handleVideoError(i)}>
                                                        <source src={x.VIDEOROTED} type="video/mp4" onError={() => handleVideoError(i)} />
                                                    </video>
                                                </>
                                            ) : (
                                                <div className="d-flex justify-content-center align-items-center" style={{ height: '260px' }}>
                                                    <p>Video Not Found</p>
                                                </div>
                                            )}
                                        </div>
                                        <p className="card-Heading m-0 text-cente pt-1">{x.STONEDETAIL}</p>
                                        {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-${x.COU_CODE}`}>{x.COU_NAME}</Tooltip>} > */}
                                        <div className='d-flex justify-content-center align-items-center my-1'>
                                            <p className="card-text m-0"> <FlagIcon code={x.COU_CODE} size={13} width={20} /> </p>
                                            <p className='card-text ms-2'>{x.LOC_NAME}</p>
                                        </div>
                                        {/* </OverlayTrigger>  */}
                                        <div className='d-flex justify-content-between my-1'>
                                            <p className="card-text m-0"><span style={{ color: 'red' }}>T:</span> {x.TABLE_PER}</p>
                                            <p className="card-text m-0"><span style={{ color: 'red' }}>D: </span>{x.TOTDEPTH_PER}</p>
                                            <p className="card-text m-0"><span style={{ color: 'red' }}>R:</span> {x.RATIO}</p>
                                            <p className="card-text m-0"><span style={{ color: 'red' }}>M:</span> {x.MEASUREMENTS}</p>
                                        </div>

                                        <div className='d-flex justify-content-between'>
                                            <p className="card-text m-0">${(x.RATE).toFixed(2)}/ct</p>
                                            <p className="m-0 Disc-text" style={{ color: '#1a7431', fontSize: '12px', fontWeight: 800 }}>-{(x.DISC).toFixed(2)}%</p>
                                            <p className="m-0 fs-6 fw-bolder" style={{ color: 'maroon', fontSize: '12px', fontWeight: 600 }}>${(x.AMT).toFixed(2)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </>
            }
        </>
    }, [ViewType, visibleItems, errorStatus, videoRef, handleMouseOver, handleMouseOut, Selectedrow])

    const [chartFor, setchartFor] = useState('I_CARAT')

    const getChartData = (fieldName, ordBy) => {
        let allData = Object.values(
            objDs?.reduce((acc, item) => {
                const fieldLabel = item[fieldName];
                if (!acc[fieldLabel]) {
                    acc[fieldLabel] = {
                        FIELDLABEL: fieldLabel,
                        FIELDDATA: 0,
                        FIELDTYP: "number",
                        TYP: chartFor,
                        ORD: item[ordBy]
                    };
                }
                acc[fieldLabel].FIELDDATA += item[chartFor];
                return acc;
            }, {})
        ).sort((a, b) => a.ORD - b.ORD);
        let sortedData = allData;
        if (chartFor == 'I_CARAT') {
            const total = allData.reduce((sum, x) => sum + x.FIELDDATA, 0);

            sortedData = allData.map(x => ({
                ...x,
                FIELDDATA: total > 0 ? ((x.FIELDDATA / total) * 100).toFixed(2) : 0
            }))
        } else {
            sortedData = allData.map(x => ({
                ...x,
                FIELDDATA: String(x.FIELDDATA)
            }))
        }
        return sortedData;
    }
    const chartContent = useMemo(() => {


        const changeChartForType = (type) => {
            setchartFor()
            setTimeout(() => {
                setchartFor(type)
            }, 0);
        }
        return <>
            {
                ViewType == 'ChartView' &&
                <>
                    <div>
                        <button type="button" class="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 mb-2" onClick={() => changeChartForType('I_CARAT')}>Carat</button>
                        <button type="button" class="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 mb-2" onClick={() => changeChartForType('I_PCS')}>Pcs</button>
                    </div>
                    <div className="row px-2">
                        <div className="col-6">
                            {
                                chartFor && <VerticalChart chartData={getChartData('Q_NAME', 'Q_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            }
                        </div>
                        <div className="col-6">
                            {
                                chartFor && <VerticalChart chartData={getChartData('CT_NAME', 'CT_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            }
                        </div>
                        <div className="col-6">
                            {
                                chartFor && <VerticalChart chartData={getChartData('PO_NAME', 'PO_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            }
                        </div>
                        <div className="col-6">
                            {
                                chartFor && <VerticalChart chartData={getChartData('SY_NAME', 'SY_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            }
                        </div>
                        <div className="col-12">
                            {
                                chartFor && <VerticalChart chartData={getChartData('C_NAME', 'C_ORD') || []} symbol={chartFor == 'I_PCS' ? "" : "%"} />
                            }

                        </div>
                    </div>
                </>
            }
        </>
    }, [ViewType, objDs, chartFor])

    const handlePKTIDClick = (rowData, column) => {
        // Ensure the clicked column is "PKTID"
        if (column === 'STNSTATUS' && rowData?.PKTID) {
            setisModalVideoLink(true);
            setVideoLinkRow([rowData]);
        }
    };

    const gridHeight = useAutoAdjustHeight(showTotal, objTotal);
    const gridContent = useMemo(() => {
        return <>
            {
                ViewType == 'GridView' &&
                // objDs && objDs?.length > 0 && objViewPara && objViewPara?.length > 0 &&
                objViewPara && objViewPara?.length > 0 &&
                <GlidGrid
                    data={objDs}
                    columns={objViewPara}
                    height={`${gridHeight}px`}
                    getCellTheme={getCellTheme}
                    GetselectedRow={GetselectedRow}
                    ActionButton={getActionButton}
                    getaction
                    headerHeight={30}
                    ExcelGet={pageName}
                    ViewparaName={pageName}
                    onCellDoubleClicked={(rowData, column) => handlePKTIDClick(rowData, column)}
                />
            }
        </>
    }, [ViewType, objDs, objViewPara, gridHeight])

    const SelectMfgParty = () => {

    }

    return (
        <>
            <div className='bg-white gridTopHeight2' style={{ zIndex: 100, top: PageName === 'STONESEARCH' ? 85 : 35 }}>
                {
                    (objTotal && ObjSelTotal && showTotal) &&
                    <div className='row totalDiv position-sticky-top-0 CardComp'>
                        <div className='col-lg-2 col-md-4 col-6'>
                            <div className="card border-0 me-2">
                                <div className="card-body d-flex align-items-center justify-content-between totaldivCol">
                                    <div>
                                        <p className='f-title'>Pcs</p>
                                    </div>
                                    <div className='text-end RedColor' style={{ color: "#d90429" }}>
                                        <p className='m-0 '>{(objTotal?.I_PCS || 0)}</p>
                                        <p className='m-0 '>{(ObjSelTotal?.I_PCS || 0)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-6'>
                            <div className="card border-0 me-2">
                                <div className="card-body d-flex align-items-center justify-content-between totaldivCol">
                                    <div>
                                        <p className='f-title'>Carat</p>
                                    </div>
                                    <div className='text-end RedColor' style={{ color: "#3c096c" }}>
                                        <p className='m-0 '>{(objTotal?.I_CARAT || 0.00).toFixed(2)}</p>
                                        <p className='m-0 '>{(ObjSelTotal?.I_CARAT || 0.00).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-6'>
                            <div className="card border-0 me-2">
                                <div className="card-body d-flex align-items-center justify-content-between totaldivCol">
                                    <div>
                                        <p className='f-title'>Avg Rap</p>
                                    </div>
                                    <div className='text-end RedColor' style={{ color: "#a53860" }}>
                                        <p className='m-0  '>$ {(objTotal?.ORATE || 0).toFixed(0)}</p>
                                        <p className='m-0  '>$ {(ObjSelTotal?.ORATE || 0).toFixed(0)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-6'>
                            <div className="card border-0 me-2">
                                <div className="card-body d-flex align-items-center justify-content-between totaldivCol">
                                    <div>
                                        <p className='f-title'>Disc</p>
                                    </div>
                                    <div className='text-end RedColor' style={{ color: "#036666" }}>
                                        <p className='m-0 '>{(objTotal?.DISC || 0.00).toFixed(2)}</p>
                                        <p className='m-0 '>{(ObjSelTotal?.DISC || 0.00).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-6'>
                            <div className="card border-0 me-2">
                                <div className="card-body d-flex align-items-center justify-content-between totaldivCol">
                                    <div>
                                        <p className='f-title'>Avg Price</p>
                                    </div>
                                    <div className='text-end RedColor' style={{ color: "#7d4f50" }}>
                                        <p className='m-0 '>$ {(objTotal?.RATE || 0.00).toFixed(2)}</p>
                                        <p className='m-0 '>$ {(ObjSelTotal?.RATE || 0.00).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-4 col-6'>
                            <div className="card border-0 me-2">
                                <div className="card-body d-flex align-items-center justify-content-between totaldivCol">
                                    <div>
                                        <p className='f-title'>Value</p>
                                    </div>
                                    <div className='text-end RedColor' style={{ color: "#0466c8" }}>
                                        <p className='m-0 '>$ {(objTotal?.AMT || 0.00).toFixed(2)}</p>
                                        <p className='m-0 '>$ {(ObjSelTotal?.AMT || 0.00).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className='btndiv py-lg-2 py-md-2 py-sm-0 py-0 px-lg-3 px-md-3 ps-2 d-flex justify-content-between align-items-center LabIssDropDown'>
                    <div className='col-12 '>
                        <div className='row align-items-center p-1'>
                            <div className={`${PageName === 'STONESEARCH' ? 'col-7' : 'col-8'}`}>
                                {/* <div className='col-7 pt-2'> */}
                                {btnValues?.map((value, index) => (
                                    value.includes("Remove") ?
                                        <button type="button" key={index} data-toggle="collapse" className="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 BorderRed mb-2" aria-expanded="true" onClick={() => handleSave(value.trim())} >
                                            {value.trim()}
                                        </button>
                                        :
                                        value.includes("Filter") ?
                                            <button type="button" key={index} data-toggle="collapse" className="btn btn-sm ml-md-2 me-2 mb-2 btnFilter" aria-expanded="true" onClick={() => handleSave(value.trim())} >
                                                <RiFilterFill className='me-1' size={20} />
                                                {value.trim()}
                                            </button>
                                            :
                                            value.includes("Refresh") ?
                                                <button type="button" key={index} data-toggle="collapse" className="btn btn-sm ml-md-2 me-2 mb-2 btnRefresh" aria-expanded="true" onClick={() => handleSave(value.trim())} >
                                                    <IoMdRefresh className='me-1' size={20} />
                                                    {value.trim()}
                                                </button>
                                                :
                                                value.includes("WhatsApp") ?
                                                    <button type="button" key={index} data-toggle="collapse" className="btn btn-sm ml-md-2 me-2 mb-2 btnWhatsApp" aria-expanded="true" onClick={handleOpenModal} >
                                                        <RiWhatsappFill className='me-1' size={20} />
                                                        {value.trim()}
                                                    </button>
                                                    :
                                                    <button type="button" key={index} data-toggle="collapse" className="btn btn-sm btn-outline-primary F12 ml-md-2 me-2 mb-2" aria-expanded="true" onClick={() => handleSave(value.trim())}>
                                                        {value.trim()}
                                                    </button>
                                ))}
                            </div>
                            {/* <div className={`${PageName === 'STONESEARCH' ? 'col-1' : 'col-1'}`}> */}
                            <div className='col-1 p-disabled'>
                                <div className={`${PageName == 'PACKETSEARCH' ? 'd-none' : 'd-block'}`}>
                                    <Dropdown value={PagingRange} onChange={(e) => setPagingRange(e.target.value)}
                                        options={PageRange ?? []}
                                        optionLabel="NAME"
                                        placeholder="Select Range"
                                        showClear
                                        className={`w-100 p-2`}
                                    />
                                </div>
                            </div>
                            <div className={`${PageName === 'STONESEARCH' ? 'col-4' : 'col-3'}`}>
                                {/* <div className='col-4'> */}
                                <div className='row align-items-center justify-content-end pe-2'>
                                    {/* <div className={`${PageName === 'STONESEARCH' ? 'col-5' : 'col-1'} ${userRole?.toLowerCase() == 'web user' ? 'd-none' : ''} `}> */}
                                    <div className={`col-xl-6 col-4 col-md-12 ${userRole?.toLowerCase() == 'web user' ? 'd-none' : ''}`}>
                                        {/* <div className={`${PageName === 'STONESEARCH' ? 'd-block' : 'd-none'}`}> */}
                                        {
                                            CompCode == "VL" ? <Dropdown value={MfgParty} onChange={(e) => setMfgParty(e.target.value)}
                                                options={MfgParoptions ?? []}
                                                optionLabel="Name"
                                                placeholder="Select a Mfg Party"
                                                showClear
                                                filter valueTemplate={selectedOptionTemplate} itemTemplate={OptionTemplate}
                                                className={`w-100 p-2`}
                                            />
                                                : <Dropdown value={Party} onChange={(e) => setParty(e.target.value)}
                                                    options={options ?? []}
                                                    optionLabel="Name"
                                                    placeholder="Select a Party"
                                                    showClear
                                                    filter valueTemplate={selectedOptionTemplate} itemTemplate={OptionTemplate}
                                                    className={`w-100 p-2`}
                                                />
                                        }

                                        {/* </div> */}
                                    </div>
                                    <div className='col-xl-3 col-5 col-md-6'>
                                        {/* { JSON.parse(localStorage.getItem('isRefresh')) && <button onClick={() => props.refreshData ? props.refreshData() : ""} className='btn btn-sm btn-outline-primary'>Refresh</button> } */}
                                        <div className='w-100 d-flex justify-content-center align-items-center '>
                                            <FaRectangleList size={30} color={ViewType == 'GridView' ? 'var(---IconColor)' : 'Gray'} onClick={() => displayType('GridView')} />
                                            <IoGrid size={28} className='ms-1' color={ViewType == 'CardView' ? 'var(---IconColor)' : 'Gray'} onClick={() => displayType('CardView')} />
                                            <HiChartSquareBar size={33} className='' color={ViewType == 'ChartView' ? 'var(---IconColor)' : 'Gray'} onClick={() => displayType('ChartView')} />
                                        </div>
                                    </div>
                                    <div className='col-xl-3 col-4 col-md-6'>
                                        {/* <Form className={`small ${PageName == 'stonesearch' ? '' : 'float-end'}`} > */}
                                        <Form className='' >
                                            <span className='d-block d-lg-none'>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    name="SummarySwitch"
                                                    label=""
                                                    checked={showTotal}
                                                    onChange={handleSwitchChange}
                                                />
                                            </span>
                                            <span className='d-none d-lg-block'>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    name="SummarySwitch"
                                                    label="Summary"
                                                    checked={showTotal}
                                                    onChange={handleSwitchChange}
                                                />
                                            </span>
                                        </Form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
            </div >

            <div className='Datacontainer'>
                <div ref={dataDivRef} className='dataDiv px-2' style={{ overflowY: 'auto' }}>

                    {gridContent}

                    {
                        ViewType == 'CardView' && <InfiniteScroll
                            dataLength={visibleItems.length}
                            next={fetchMoreData}
                            hasMore={true}
                            height={cardViewHeight}
                            loader={<h4></h4>}
                        >
                            <div className="row m-0">
                                {videoContent}
                            </div>
                        </InfiniteScroll>
                    }

                    {
                        ViewType == "ChartView" && <div style={{ height: cardViewHeight }}> {chartContent} </div>
                    }
                </div>
            </div >

            <PacketSearchComp openPacketSearchCom={openPacketSearchCom} SearchType={props.SearchType} setopenPacketSearchCom={setopenPacketSearchCom} />

            <PktErrorComp
                show={showModal}
                onHide={() => setShowModal(false)}
                dataSource={PktErrData}
                onExcludePackets={handleExcludePackets}
            />

            {
                splitValue?.length > 1 &&
                <Modal show={isModalOpen} onHide={handleCloseModal} className="left-side-modal" >
                    <Modal.Header>
                        <h5>Select Mobile Number</h5>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            splitValue && splitValue?.map((x, i) => (
                                <>
                                    <p key={i} style={{ cursor: 'pointer' }}>
                                        <RiWhatsappFill className='me-2' size={25} color='green' />
                                        <span onClick={() => handleOpenWhatsApp(splitNumberValue[i])} className='fw-medium'>
                                            {x}
                                        </span>
                                    </p>
                                </>
                            ))
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="secondary" className='btnbluedark' onClick={handleCloseModal}> Close </button>
                    </Modal.Footer>
                </Modal>
            }

            <Modal show={isModalVideoLink} onHide={handleCloseModalVideoLink} className='left-side-modal'>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {
                        Array.isArray(VideoLinkRow) && VideoLinkRow.length > 0 ? (
                            VideoLinkRow.map((x, i) => (
                                <React.Fragment key={i}>
                                    <div className='row'>
                                        <div className='col-12 text-center'>
                                            <img src={x?.PHOTOLINK} alt={`Image ${i}`} style={{ width: '100%', maxWidth: "466px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        {/* Copy Image Link */}
                                        <div className='col-6'>
                                            <button className='btnOutlineBlue w-100' onClick={() => navigator.clipboard.writeText(x?.PHOTOLINK)}>
                                                <FaLink size={17} className='me-2' />
                                                Copy Image Link
                                            </button>
                                        </div>
                                        {/* Download Image */}
                                        <div className='col-6'>
                                            <button
                                                className='btnOutlineBlue w-100'
                                                onClick={async () => {
                                                    try {
                                                        const response = await fetch(x?.PHOTOLINK);
                                                        if (!response.ok) throw new Error('Failed to fetch image');
                                                        const blob = await response.blob();
                                                        const url = window.URL.createObjectURL(blob);

                                                        // Create a temporary <a> element for downloading the image
                                                        const a = document.createElement('a');
                                                        a.style.display = 'none';
                                                        a.href = url;
                                                        a.download = `${x?.PKTID}.jpg`; // Adjust file name and extension as needed
                                                        document.body.appendChild(a);
                                                        a.click();

                                                        // Clean up
                                                        window.URL.revokeObjectURL(url);
                                                        document.body.removeChild(a);
                                                    } catch (error) {
                                                        console.error('Error downloading image:', error);
                                                        alert('Failed to download the image. Please try again.');
                                                    }
                                                }}
                                            >
                                                <IoMdDownload size={17} className='me-2' />
                                                Download Image
                                            </button>
                                        </div>

                                    </div>
                                    <div className='row mt-1'>
                                        {/* Copy Video Link */}
                                        <div className='col-6'>
                                            <button className='btnOutlineBlue w-100' onClick={() => navigator.clipboard.writeText(x?.VIDEOLINK1)}>
                                                <FaLink size={17} className='me-2' />
                                                Copy Video Link
                                            </button>
                                        </div>
                                        {/* Download Video */}
                                        <div className='col-6'>
                                            <button
                                                className='btnOutlineBlue w-100'
                                                onClick={async () => {
                                                    try {
                                                        const response = await fetch(x?.VIDEOLINK1);
                                                        if (!response.ok) throw new Error('Failed to fetch video');
                                                        const blob = await response.blob();
                                                        const url = window.URL.createObjectURL(blob);

                                                        // Create a temporary <a> element for downloading the video
                                                        const a = document.createElement('a');
                                                        a.style.display = 'none';
                                                        a.href = url;
                                                        a.download = `${x?.PKTID}.mp4`; // Adjust file name and extension as needed
                                                        document.body.appendChild(a);
                                                        a.click();

                                                        // Clean up
                                                        window.URL.revokeObjectURL(url);
                                                        document.body.removeChild(a);
                                                    } catch (error) {
                                                        console.error('Error downloading video:', error);
                                                        alert('Failed to download the video. Please try again.');
                                                    }
                                                }}
                                            >
                                                <IoMdDownload size={17} className='me-2' />
                                                Download Video
                                            </button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))
                        ) : (
                            <p>No videos available</p>
                        )
                    }
                </Modal.Body>
                {/* <Modal.Footer>
                    <button variant="secondary" className='btnbluedark' onClick={handleCloseModalVideoLink}> Close </button>
                </Modal.Footer> */}
            </Modal>
        </>
    )

}

export default memo(DataListComp)