import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';
import { AlertPopup, errorPopup } from '../../../Asset/common';

export const TableName = "BRANCHMAST"

export const fetchData = (isMessage) => async (dispatch) => {
  dispatch(startLoader())
  let ObjJsonDet = {
    jsonObj1: [{}],
    SPName: "Api_BranchMastGet"
  };
  let res = await HttpMethod.post(`/SystemConfig/InsUpdDel/DynSpExDisp`, ObjJsonDet)
  if (res?.status === 200) {
    if (!(res.data.DynDisp) && isMessage) {
      errorPopup(res.data)
      dispatch(getData({ DynDisp: [] }));
    }
    else {
      dispatch(getData(await res.data));
    }
  }
  dispatch(stopLoader())
};

const constructDataJson = (data) => {
  let newData = {};
  for (let key in data) {
    newData[key.toLowerCase()] = data[key];
  }

  let dataJson = {};
  dataJson["jsonObj"] = [];
  dataJson["TableName"] = TableName;
  dataJson["jsonObj"].push(newData);
  return dataJson;
};

export const addData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Add', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data);
    dispatch(fetchData())
  };
  dispatch(stopLoader());
};

export const updateData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Update', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data);
    dispatch(fetchData())
  }
  dispatch(stopLoader())
};

export const deleteData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Delete', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data);
    dispatch(fetchData())
  }
  dispatch(stopLoader())
}

export const BranchMastSlice = createSlice({
  name: 'BranchMast',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };      
    }
  },
});
export const { getData } = BranchMastSlice.actions;
export default BranchMastSlice.reducer;