export const objReducer = (state, action) => {
    if (action.type == 'add') {
        let { type, name, value, checked } = action.event.target;
        // if(state[name] == undefined){
        //     return state;
        // }
        if (type == 'checkbox') {
            state[name] = state[name] ?? [];

            if (checked) {
                if (Array.isArray(state[name])) {
                    state[name] = [...state[name], value]
                } else {
                    state[name] = [state[name], value]
                }
            } else {
                state[name] = state[name]?.filter((x) => x != value)
            }
        } else if (type == 'file') {
            state[name] = action.event.target.files[0];
        }
        else {
            state[name] = value;
        }
        state = { ...state }
        return state
    } else if (action.type == 'Reset') {
        state = {}
        return state
    } else if (action.type == 'RemoveKey') {
        const { key } = action.payload;
        if (!state || !state.obj || !state.SearchObj) {
            return state;
        }
        const newState = { ...state };
        delete newState.obj[key];
        delete newState.SearchObj[key];
        return newState;
    } else if (action.type == 'MultiText') {
        let { type, name, value, checked } = action.event.target;


        // state[name] = [...state[name], value]
        // state = { ...state }

        return state
    } else {
        state = { ...action.editObj }
        return state
    }
}