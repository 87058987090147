import { createSlice } from '@reduxjs/toolkit';
import { GetDropDownList, HttpMethod, headerObj, loginUserDetail, setLoginUserDetail } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from './loaderSlice';
import { AlertPopup } from '../../../Asset/common';
import { fetchData as fetchSettingTbl } from '../Masters/SettingSlice';

// document.cookie = "userDetail=Hiii";

export const loginUser = (userData, navigate) => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.login('/Authorization/Account/Login', userData);
  dispatch(stopLoader())
  if (res?.status === 200) {
    // localStorage.setItem('DTradeExpiration', res.data.Expires);
    // localStorage.setItem('DTradeToken', res.data.AccessToken); 

    let loginToken = await res.data.AccessToken;
    headerObj.headers.Authorization = 'Bearer ' + loginToken;

    localStorage.setItem('loginUserDetail', JSON.stringify(res.data))
    localStorage.setItem('isOpen', true)
    setLoginUserDetail();

    // let ObjParam = {
    //   "Master": "UserMenuPermission",
    //   "RoleId": 1,
    //   "UserId": 1,
    // }
    // const resPermition = await GetDropDownList(ObjParam);  
    // let dispatch2= useDispatch()

    // dispatch2(MenuPermition(resPermition));
    dispatch(login(await res.data));
    dispatch(fetchSettingTbl());
    // navigate('/')
  }
};



export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


// export const fetchMenuPermition = () => async (dispatch) => {
//   // dispatch(startLoader()) 
//   let ObjParam = {
//     "Master": "UserMenuPermission",
//     "RoleId": 1,
//     "UserId": 1,
//   }
//   const res = await GetDropDownList(ObjParam);  
//   // if (res?.status === 200) {  
//     dispatch(MenuPermition(res));    
//   // }
//   // dispatch(stopLoader())
// };

export const logoutUser = (logoutUrl) => async (dispatch) => {
  localStorage.removeItem('loginUserDetail');
  localStorage.setItem('isOpen', true);
  // if(!window.location.origin.includes('localhost')){
  //   let redirectUrl = window.location.origin.replace('app.' , '');
  //   window.location.href = redirectUrl;
  // }
  if (logoutUrl) {
    window.location.href = logoutUrl;
  } else {
    dispatch(login(null));
  }
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: localStorage.getItem('loginUserDetail') ? JSON.parse(localStorage.getItem('loginUserDetail')) : null
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload; // Set user data if needed
    },
    MenuPermition: (state, action) => {
      state.MenuList = action.payload; // Set user data if needed      
    },
  },
});

export const { login, MenuPermition } = loginSlice.actions;
export const selectLogin = (state) => state.UserLogin.user

export const changePassword = (passwordData) => async (dispatch) => {
  dispatch(startLoader());
  try {
    const res = await HttpMethod.post('/Authorization/Account/ChangePassword', passwordData);
    if (res && res?.status === 200) {
      // Handle success
      if (AlertPopup && AlertPopup.SuccessPopup) {
        AlertPopup.SuccessPopup('Password changed successfully!');
      }
      dispatch(stopLoader());
    }
  } catch (error) {
    // Handle error
    console.error('Error changing password:', error);
    if (AlertPopup && AlertPopup.ErrorPopup) {
      AlertPopup.ErrorPopup('An error occurred while changing password. Please try again later.');
    }
    dispatch(stopLoader());
  }
};

// export const forgotPassword = (email) => async (dispatch) => {
//   dispatch(startLoader());
//   try {
//     const res = await HttpMethod.post(`/Authorization/Account/ForgotPassword`, { email });
//     if (res && res?.status === 200) {
//       // Handle success
//       if (AlertPopup && AlertPopup.SuccessPopup) {
//         AlertPopup.SuccessPopup('An email with instructions to reset your password has been sent.');
//       }
//     }
//   } catch (error) {
//     // Handle error
//     console.error('Error sending forgot password request:', error);
//     if (AlertPopup && AlertPopup.ErrorPopup) {
//       AlertPopup.ErrorPopup('An error occurred while sending the forgot password request. Please try again later.');
//     }
//   }
//   dispatch(stopLoader());
// };

export default loginSlice.reducer;