import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from './loaderSlice';
import { errorPopup } from '../../../Asset/common';

export const TableName = "BarSetting"

export const fetchData = (isMessage) => async (dispatch) => {
  dispatch(startLoader())
  let ObjJsonDet = {
    jsonObj1: [{
      "COMP_CODE": "", "TYP": ""
    }],
    SPName: "API_BarSettingDisp"
  };
  let res = await HttpMethod.post(`/SystemConfig/InsUpdDel/DynSpExDisp`, ObjJsonDet)
  if (res?.status === 200) {
    if (!(res.data.DynDisp) && isMessage) {
      errorPopup(res.data)
      dispatch(getData({ DynDisp: [] }));
    }
    else {
      dispatch(getData(await res.data));
    }

  }
  dispatch(stopLoader())
};

// export const addData = (data) => async (dispatch) => {
//   const dataJson = constructDataJson(data);
//   const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Add', dataJson);
//   if (res?.status === 200) {
//     AlertPopup.SuccessPopup(res.data)
//     dispatch(fetchData());
//   }
// };


// export const updateData = (data) => async (dispatch) => {
//   const dataJson = constructDataJson(data);
//   const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Update', dataJson);
//   if (res?.status === 200) {
//     AlertPopup.SuccessPopup(res.data)
//     dispatch(fetchData());
//   }
// };

// export const deleteData = (data) => async (dispatch) => {
//   const dataJson = constructDataJson(data);
//   const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Delete', dataJson);
//   if (res?.status === 200) {
//     AlertPopup.SuccessPopup(res.data)
//     dispatch(fetchData());
//   }
// }

export const BarSettingSlice = createSlice({
  name: 'BarSetting',
  initialState: {},
  loading: false,
  reducers: {
    getData: (state, action) => {
      return action.payload;
    }
  },
});
export const { getData } = BarSettingSlice.actions;
export default BarSettingSlice.reducer;