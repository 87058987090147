import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from './loaderSlice';
import { AlertPopup } from '../../../Asset/common';

export const TableName = "ColumnConfig"

export const fetchData = () => async (dispatch) => {
  debugger
  dispatch(startLoader())
  const res = await HttpMethod.get('/SystemConfig/ColumnConfig/GetList');
  if (res?.status === 200) {
    let obj = {
      all: res.data.ColumnConfig,
      visible: res?.data?.ColumnConfig?.filter(x => x.IsHide == false)
    }
    dispatch(getData(obj));
  }
  dispatch(stopLoader())
};

const constructDataJson = (data) => {
  let dataJson = {};
  dataJson["jsonObj"] = [];
  dataJson["TableName"] = TableName;
  if (Array.isArray(data)) {
    for (let x of data) {
      let newData = {};
      for (let key in x) {
        newData[key.toLowerCase()] = x[key];
      }
      dataJson["jsonObj"].push(newData);
    }
  } else {
    let newData = {};
    for (let key in data) {
      newData[key.toLowerCase()] = data[key];
    }
    dataJson["jsonObj"].push(newData);
  }
  return dataJson;
}

export const addData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Add', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
  return res
};


export const updateData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Update', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
  return res
};

export const deleteData = (data) => async (dispatch) => {
  const dataJson = constructDataJson(data);
  const res = await HttpMethod.post('/SystemConfig/InsUpdDel/Delete', dataJson);
  if (res?.status === 200) {
    AlertPopup.SuccessPopup(res.data)
    dispatch(fetchData());
  }
  return res
}


export const ColumnConfigSlice = createSlice({
  name: 'ColumnConfig',
  initialState: {
    all: [],
    visible: []
  },
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };
      // state.initialState=action.payload;
      // state.loading=false;
    }
  },
});
export const { getData } = ColumnConfigSlice.actions;
export default ColumnConfigSlice.reducer;