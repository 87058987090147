import React, { createContext, useState, lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  Switch,
} from "react-router-dom";
//  import routesConfig from "./routesConfig.json";
import Login from '../Pages/Users/Login'
import { useDispatch, useSelector, useSelector as useSelector2 } from "react-redux";
import { selectLogin, loginSlice } from "../Redux/Slices/Utility/LoginSlice";
import StoneSearch from "../Pages/StoneSearch/StoneSearch";
import Register from "../Pages/Users/Register";
import { GetDropDownList } from "../Asset/httpMethod";
import { fetchMenuPermition } from "../Redux/Slices/Utility/MenuPermition";
import { useCookies } from "react-cookie";
import Layout from "../Sidebar/Layout";

//import routesConfig from './tmpConfig.json';
// import DataListComp from '../StoneSearch/DataListComp'
// import Confirm from '../Pages/HeaderPages/Confirm'

// let routesConfig=[]
// const fetchmenuData = async () => {
//   let ObjParam = {
//       "Master": "UserMenuPermission",
//       "RoleId": 1,
//       "UserId": 1,
//   }
//   routesConfig = await GetDropDownList(ObjParam);
//   // setmenulist(GetDropDown);
// };

function RoutePathDynamic() {

  const dispatch = useDispatch();

  let loginUser = useSelector(state => state.UserLogin)
  let routesConfig = useSelector(state => state.MenuPermition)

  // if (login && Object.keys(routesConfig).length === 0) {
  //   dispatch(fetchMenuPermition())  
  // }
  // if (true) {
  return (
    <BrowserRouter>
      <Layout isAuthenticated={!!loginUser.user}>
        <Suspense fallback={<div></div>}>
          <Routes>
            {
              loginUser.user ? (
                <>
                  {
                    routesConfig && routesConfig.length > 0 && routesConfig?.map((route, index) => {
                      const { RoutePath, Element, ElementFolder } = route;

                      if (RoutePath && Element && ElementFolder) {
                        const DynamicComponent = React.lazy(() =>
                          import(`../Pages/${ElementFolder}/${Element}`)
                        );
                        return (
                          <Route
                            key={index}
                            path={RoutePath}
                            element={<DynamicComponent />}
                          />
                        );
                      }
                    })
                  }
                </>
              ) : (
                <>
                  <Route path="/" element={<Navigate to="/login" />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="*" element={<Navigate to='/' />} />
                </>
              )
            }
          </Routes>
        </Suspense>
      </Layout>
    </BrowserRouter>
  );
  // } else {
  //   // Handle the case when routesConfig1 is not an array
  //   return <div>Loading...</div>; // or any other suitable handling
  // }

}
// Helper function to import React components dynamically

export default RoutePathDynamic;
