import React, { useState } from "react";
import "./Register.css"; 
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../../Redux/Slices/Utility/RegisterSlice";
import { useForm } from "react-hook-form";
import { AlertPopup } from "../../Asset/common";

function Register() {
  const navigate = useNavigate();
  const [arr, setarr] = useState(
    JSON.parse(localStorage?.getItem("users")) || []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const isAdmin = true;

  const onSubmit = async (data) => {
    if (
      Object.values(data).every(
        (val) => typeof val === "string" && val.trim() !== ""
      )
    ) {
      if (data.Password !== data.Password) {
        AlertPopup.warningPopup("Password does not match with confirm password !!");
        return;
      }

      try {
        
        // console.log('RegUserdata',data);
        await dispatch(registerUser(data));
        navigate("/login");
      } catch (error) {
        console.error("Error registering user:", error);
        AlertPopup.warningPopup("An error occurred while registering. Please try again later.");
      }
    } else {
      AlertPopup.warningPopup("Please fill in all the required fields !!");
    }
  };

  return (
    <>
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
      <section>
        <div className="Regcontainer" id="container">
          <div className="form-container sign-in-container">
            
          <div className="row mt-2 w-100">
            <form onSubmit={handleSubmit(onSubmit)} className="row">
              <h5 className="mt-3">Register with us</h5>
                <div className="col">
                  <div className="row">
                    <div className="col-sm-6 col-md-6 p-2 Reg_Focus">
                      <input
                        type="Text"
                        placeholder="FirstName"
                        className="form-control "
                        name="FirstName"
                        {...register("FirstName", {
                          required: "This field is required",
                        })}
                      />
                      {errors.FirstName && (
                        <span className="validation">
                          {errors.FirstName.message}
                        </span>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6 p-2 Reg_Focus">
                      <input
                        type="Text"
                        placeholder="LastName"
                        className="form-control "
                        name="LastName"
                        {...register("LastName", {
                          required: "This field is required",
                        })}
                      />
                      {errors.LastName && (
                        <span className="validation">
                          {errors.LastName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 p-2 Reg_Focus">
                      <input
                        type="Text"
                        placeholder="Username"
                        className="form-control "
                        name="Username"
                        {...register("Username", {
                          required: "This field is required",
                        })}
                      />
                      {errors.Username && (
                        <span className="validation">
                          {errors.Username.message}
                        </span>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6 p-2 Reg_Focus">
                      <input
                        type="Text"
                        placeholder="PhoneNumber"
                        className="form-control "
                        name="PhoneNumber"
                        {...register("PhoneNumber", {
                          required: "This field is required",
                        })}
                      />
                      {errors.PhoneNumber && (
                        <span className="validation">
                          {errors.PhoneNumber.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="inputmail">
                    <input
                      type="Text"
                      placeholder="Email"
                      className="form-control Reg_Focus"
                      name="Email"
                      {...register("Email", {
                        required: "This field is required",
                        pattern: {
                          value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.Email && (
                      <span className="validation">{errors.Email.message}</span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-6 p-2 Reg_Focus">
                      <input
                        type="Text"
                        placeholder="Company Name"
                        className="form-control "
                        name="CompanyName"
                        {...register("CompanyName", {
                          required: "This field is required",
                        })}
                      />
                      {errors.CompanyName && (
                        <span className="validation">
                          {errors.CompanyName.message}
                        </span>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-6 p-2 Reg_Focus">
                      <input
                        type="Text"
                        placeholder="Designation"
                        className="form-control "
                        name="Designation"
                        {...register("Designation", {
                          required: "This field is required",
                        })}
                      />
                      {errors.Designation && (
                        <span className="validation">
                          {errors.Designation.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-6 col-md-6 p-2 Reg_Focus">
                    <input
                      type="password"
                      placeholder="Password"
                      className="form-control "
                      name="Password"
                      {...register("Password", {
                        required: "This field is required",
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                          message: "Password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long",
                        },
                      })}
                    />
                    {errors.Password && (
                      <span className="validation">
                        {errors.Password.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-6 col-md-6 p-2 Reg_Focus">
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      className="form-control "
                      name="Password"
                      {...register("Password", {
                        required: "This field is required",
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                          message: "Password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long",
                        },
                      })}
                    />
                    {errors.Password && (
                      <span className="validation">
                        {errors.Password.message}
                      </span>
                    )}
                  </div>
                  </div>
                </div>
              <button className="btn signbtn btn-sm btn-outline-primary ml-md-2 mb-3">
                Sign Up
              </button>
                      
              <div className="foottext">
                <span>
                  Already have an account?
                </span>
                <Link
                  to="/Login"
                  style={{
                    color: "#337357",
                    fontWeight: "700",
                    float: "right",
                    margin: "-5px 5px",
                  }}
                >
                  Login
                </Link>
              </div>
              <div className="col-sm-6 col-md-6 overlay-container">
                <h1>Create, Account!</h1>
                <p>Sign up if you still don't have an account ... </p>
              </div>
            </form>
            
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Register;
