import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Header from '../Header/Header'
// import { ColorRing, RevolvingDot } from 'react-loader-spinner'

export const HOC = (Components, PageName) => {
    const Newcomponent = () => {
        return <>
            {/* <div className='d-flex1'> */}
            {/* <Sidebar />
                <div className='viewContent'> */}
            <Header className='' MainPageName={PageName} />
            <div className='displayContent'>
                <Components className='' />
            </div>
            {/* </div> */}
            {/* </div> */}
        </>
    }
    return Newcomponent
}
