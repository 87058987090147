import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import './Loader.css'
import { useSelector } from 'react-redux';
import { selectLoader } from '../../Redux/Slices/Utility/loaderSlice';

function Loader(props) {
    const Loader = useSelector(selectLoader);
    return (
        <>
            {Loader != 0 &&
                <div className="loading" >
                    {/* <RotatingTriangles
                        visible={true}
                        height="80"
                        width="80"
                        color='var(---FocusColor)'
                        ariaLabel="rotating-triangles-loading"
                        wrapperStyle={{}}
                        wrapperclassName=""
                    /> */}
                    <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="var(---ActiveColor)"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperclassName=""
                    />
                </div>
            }

        </>
    )
}

export default Loader