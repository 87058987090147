import convert from 'color-convert';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { GridActionButton } from '../../Asset/common';

//   const ExcelJS = require('exceljs');
//   const saveAs = require('file-saver'); //


async function generateExcel(columnConfig, data, filename) {
    console.log('CC',columnConfig);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    // Filter columns based on IsUpdate property
    const filteredColumns = columnConfig?.filter(column => column.IsUpdate === false);
    console.log('ff',filteredColumns);

    // Add headers and set column size
 
    let ColumnHeader = []
    // filteredColumns.forEach(column => {
    //     ColumnHeader = [...ColumnHeader,{
    //         header: column.DispName,
    //         key: column.field,
    //         width: column.size / 7
    //     }];
    // });

    const getColorHex = (colorName) => {
        const rgbColor = convert.keyword.rgb(colorName);
        const hexColor = convert.rgb.hex(rgbColor);
        return `${hexColor}`;
    };

    filteredColumns.forEach(column => {
        ColumnHeader.push({
            header: column.DispName,
            key: column.field,
            width: column.size / 7,
            style: {
                bgColor: { argb: getColorHex('green') }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: getColorHex('green') }
            }
        });
    });


    worksheet.columns = ColumnHeader


    // Add data rows
    // data.forEach(row => worksheet.addRow(row));

    data?.forEach((row, rowIndex) => {
        const worksheetRow = worksheet.addRow(row);
        worksheetRow.eachCell({ includeEmpty: true }, cell => {
            const columnIndex = cell._column._number - 1; // Get column index
            const column = filteredColumns[columnIndex];

            // Apply cell background color
            if (column && column.Backcolor && column.Backcolor != '') {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    // fgColor: { argb: "B5C18E" }
                    fgColor: { argb: getColorHex(column.Backcolor) }
                };
            }

            // Apply cell foreground color
            if (column && column.Forecolor && column.Forecolor != '') {
                cell.font = {
                    // color: { argb: "f2632a" }
                    color: { argb: getColorHex(column.Forecolor) }
                };
            }

            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };

            if (column && column.field.toLowerCase() === 'certno' && row.REPORTLINK) {
                cell.value = {
                    text: row.CERTNO,
                    hyperlink: row.REPORTLINK,
                }
                cell.font = {
                    color: { argb: '0000FF' }
                }
            }

            if (column && column.field.toLowerCase() === 'videolink1' && row.VIDEOLINK1) {
                cell.value = {
                    text: row.VIDEOLINK1,
                    hyperlink: row.VIDEOLINK1,
                }
                cell.font = {
                    color: { argb: '0000FF' }
                }
            }

            if (column && column.field.toLowerCase() === 'videolink2' && row.VIDEOLINK2) {
                cell.value = {
                    text: row.VIDEOLINK2,
                    hyperlink: row.VIDEOLINK2,
                }
                cell.font = {
                    color: { argb: '0000FF' }
                }
            }

            if (column && column.field.toLowerCase() === 'photolink' && row.PHOTOLINK) {
                cell.value = {
                    text: row.PHOTOLINK,
                    hyperlink: row.PHOTOLINK,
                }
                cell.font = {
                    color: { argb: '0000FF' }
                }
            }

            if (column && column.field.toLowerCase() === 'reportlink' && row.REPORTLINK) {
                cell.value = {
                    text: row.REPORTLINK,
                    hyperlink: row.REPORTLINK,
                }
                cell.font = {
                    color: { argb: '0000FF' }
                }
            }

        });
    });
    // const lastRow = data.length + 1; // +1 for the header row
    // worksheet.getCell(`B${lastRow + 1}`).value = {
    //     formula: `COUNTA(A2:A${lastRow})`,
    //     result: null, // Optional: set the result to null initially
    // };
    // worksheet.getCell(`O${lastRow + 1}`).value = {
    //     formula: `SUM(O2:O${lastRow})`,
    //     result: null, // Optional: set the result to null initially
    // };
    // worksheet.getCell(`A${lastRow + 1}`).value = 'Count of Non-Empty Names'; // Add a label

    // Save the workbook to a buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Convert buffer to Blob
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Trigger file download
    saveAs(blob, filename);
}

// module.exports = generateExcel;
export default generateExcel;
