import { createSlice } from '@reduxjs/toolkit';
import { HttpMethod, sendError_notify } from '../../../Asset/httpMethod';
import { startLoader, stopLoader } from '../Utility/loaderSlice';
import { AlertPopup } from "../../../Asset/common";

export const ConfirmfetchData = () => async (dispatch) => {
  dispatch(startLoader()) 
  const res = await HttpMethod.get('/Stock/StockSearch/ConfirmList');
  if (res?.status === 200) {
    dispatch(getData(await res.data));
  }
  dispatch(stopLoader())
};

export const ConfirmListaddData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.post('/Stock/StockSearch/AddToConfirm', data);
  if (res?.status === 200) {
    dispatch(ConfirmfetchData())
    AlertPopup.SuccessPopup(res.data);
  };
  dispatch(stopLoader())
}


export const ConfirmListDelData = (data) => async (dispatch) => {
  dispatch(startLoader())
  const res = await HttpMethod.post('/Stock/StockSearch/RemoveConfirm', data);
  if (res?.status === 200) {
    dispatch(ConfirmfetchData())
    AlertPopup.SuccessPopup(res.data);
  }
  dispatch(stopLoader())
};

export const ConfirmSlice = createSlice({
  name: 'ConfirmList',
  initialState: {
    "StockConfirmList": [],
    "StockConfirmList1": []
  },
  loading: false,
  reducers: {
    getData: (state, action) => {
      return { ...action.payload };
    }
  },
});
export const { getData } = ConfirmSlice.actions;
export default ConfirmSlice.reducer;