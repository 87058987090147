import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../Redux/Slices/Utility/LoginSlice";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import loginImage from "../../Asset/Image/Login/Login.png";
import { FaFacebookF, FaGoogle, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { Alert } from "react-bootstrap";
function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Correctly named as navigate for clarity
  const [rememberMe, setRememberMe] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  let UserLogin = useSelector((state) => state.UserLogin);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    
    if (rememberMe) {
      localStorage.setItem("rememberLogin", btoa(JSON.stringify({ userName: data.Username, Password: btoa(data.Password) })));
    } else {
      localStorage.removeItem("rememberLogin");
    }
    await dispatch(loginUser(data, navigate));
  };

  let Setting = useSelector(state => state.Setting.Setting);
  let CompCode = Setting?.find(Setting => Setting.SKEY === 'COMP_CODE')?.SVALUE;
  useEffect(() => {
    // if (UserLogin.user && UserLogin.isAuthenticated === true) { 
    //   dispatch(fetchMenuPermition())
    //   navigate("/");
    // }
    // if (UserLogin.user && UserLogin.isAuthenticated === true) {
    //   dispatch(fetchMenuPermition())
    //   navigate("/");
    // }
    if (UserLogin.user) {
      if (CompCode === "Valam" || CompCode === "SILVER") {
        navigate('/StoneSearch');
      } else {
        navigate('/');
      }
    }
    
  }, [UserLogin]);

  // const handleForgotPassword = async (email) => {
  //   dispatch(forgotPassword(email));
  // };

  useEffect(() => {
    let storedUserdata = localStorage?.getItem("rememberLogin");
    if (storedUserdata) {
      storedUserdata = JSON.parse(atob(storedUserdata))
      setValue("Username", storedUserdata.userName);
      setValue("Password", atob(storedUserdata.Password));
      setRememberMe(true); // Assuming you want to check the checkbox if the username is remembered
    }
  }, [UserLogin]);

  return (
    <>
      <div className="container login_container">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="row align-items-center "
        >
          <div className="col-sm-7 col-md-7 align-items-center mt-5">
            <div className="text-center">
              <img src={loginImage} alt="Login" className="loginimg" />
            </div>
          </div>
          <div className="col-sm-5 col-md-5 align-items-center loghead">
            <h1>Welcome Back</h1>
            <p>Please login to your account</p>
            <div className="mb-3">
              <input
                type="Text"
                placeholder="Username"
                className="form-control"
                name="Username"
                {...register("Username", {
                  required: "This field is required",
                })}
              />
              {errors.Username && (
                <span className="validation">{errors.Username.message}</span>
              )}
            </div>
            <div className="mb-3">
              <input
                type="password"
                placeholder="Password"
                className="form-control"
                name="Password"
                {...register("Password", {
                  required: "This field is required",
                })}
              />
              {errors.Password && (
                <span className="validation">{errors.Password.message}</span>
              )}
            </div>
            <div>
              <span className="chekbox">
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="mx-1"
                ></input>
                <label htmlFor="rememberMe">Remember Me</label>
              </span>
              {/* <span
                className="forget_pass float-end mb-3"
                onClick={handleSubmit(handleForgotPassword)}
              >
                <a href="">Forgot Password</a>
              </span> */}
            </div>

            <button
              className="btn btn-sm btn-outline-primary ml-md-2 w-100 mb-3"
              type="submit"
            >
              Login
            </button>
            <div>
              Don't have an Account ?
              <Link
                to="/Register"
                style={{ color: "#337357", fontWeight: "700" }}
              >
                <span className="float-end" style={{ marginTop: "-35px" }}>
                  SignUp
                </span>
              </Link>
            </div>
            <Alert
              variant="success"
              show={showAlert}
              onClose={() => setShowAlert(false)}
              dismissible
            >
              Password reset email sent!
            </Alert>
            <p className="social-text">Or Sign up with social platforms</p>
            <div className="social-media">
              <a href="#" className="social-icon">
                <FaFacebookF />
              </a>
              <a href="#" className="social-icon">
                <FaTwitter />
              </a>
              <a href="#" className="social-icon">
                <FaGoogle />
              </a>
              <a href="#" className="social-icon">
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default Login;
